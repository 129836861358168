<template>
<v-container>
    <v-layout row wrap>
      <v-flex xs12 >
        <v-card>
            <v-card-title>
                Zoznam povolaní
            </v-card-title>
            <v-card-text v-if="zoznamPovolani">
                <v-layout row wrap v-for="(element, index) in zoznamPovolani" :key="index" :style="getBackgroundColor(index)">
                    <v-flex xs12  @click="prepareKompetentnosti(index)" style="cursor:pointer">
                        <span style="margin-left:10px">{{element.nazov}}</span>
                    </v-flex>
                    <v-flex xs4 v-if="showIndex == index"></v-flex>
                    <v-flex xs12 v-if="showIndex == index">
                        <v-card v-if="zoznamKompetentnosti" outlined style="margin-bottom:10px">
                            <v-card-text>
                            <v-icon class="btn-close" @click="showIndex = -1;zoznamKompetentnosti = null">close</v-icon> 
                            <v-card-text>
                                <v-layout row wrap>
                                    <v-flex xs9><b>Popis</b></v-flex>
                                    <v-flex xs1><v-layout justify-center><b>bc</b></v-layout></v-flex>
                                    <v-flex xs1><v-layout justify-center><b>mgr</b></v-layout></v-flex>
                                    <v-flex xs1><v-layout justify-center><b>spolu</b></v-layout></v-flex>
                                </v-layout>
                                <v-layout row wrap v-for="(element, index2) in zoznamKompetentnosti" :key="index2" :style="getBackgroundColor(index2)">
                                    <v-flex xs8 @click="getPredmety(index2)" style="cursor:pointer">
                                       <span :title="element.povolanie" :style="getCompColor(element.pocet)"> {{index2 + 1}}. {{element.popis}} ({{element.typ}} - {{element.zdroj}})</span>
                                    </v-flex>
                                    <v-flex xs1><v-layout justify-end><v-icon small @click="getPredmety(index2)" style="cursor:pointer">visibility</v-icon></v-layout></v-flex>
                                    <v-flex xs1>
                                         <v-layout justify-center><span :style="getCompColor(element.pocet)">{{element.bc}}</span></v-layout>
                                    </v-flex>
                                    <v-flex xs1>
                                        <v-layout justify-center><span :style="getCompColor(element.pocet)">{{element.mgr}}</span></v-layout>
                                    </v-flex>
                                    <v-flex xs1>
                                        <v-layout justify-center><span :style="getCompColor(element.pocet)">{{element.pocet}}</span></v-layout>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-card v-if="predmetIndex == index2">
                                            <v-card-text>
                                            <v-icon class="btn-close" @click="predmetIndex = -1;zoznamPredmetov = null">close</v-icon> 
                                                <v-layout row wrap>
                                                    <v-flex xs1><v-layout justify-center><b>stupen</b></v-layout></v-flex>
                                                    <v-flex xs1><v-layout justify-center><b>sem.</b></v-layout></v-flex>
                                                    <v-flex xs10><b>názov</b></v-flex>
                                                </v-layout>
                                                <v-layout row wrap v-for="(element3, index3) in zoznamPredmetov" :key="index3" :style="getBackgroundColor(index3)">
                                                    <v-flex xs1><v-layout justify-center>{{element3.stupen}}</v-layout></v-flex>
                                                    <v-flex xs1><v-layout justify-center>{{element3.semester}}</v-layout></v-flex>
                                                    <v-flex xs10 @click="toInfolist(element3.id)" style="cursor:pointer"> {{element3.nazov}}</v-flex>
                                                </v-layout>
                                            </v-card-text>
                                        </v-card>
                                    </v-flex>
                                </v-layout>
                            </v-card-text>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-card-text>            
        </v-card>
      </v-flex>
   </v-layout>
</v-container>
</template>

<script>
import axios from '../../../axios-auth.js'
import {mapGetters} from 'vuex'

  export default {
    data() {
      return {
            zoznamPovolani: null,
            zoznamKompetentnosti: null,
            zoznamPredmetov: null,
            showIndex: -1,
            predmetIndex: -1
        }
    },      
    computed: 
        mapGetters([
                        'isAdmin'
                    ]),
    mounted () {
      this.initData()
    },
    methods: {
        initData() {
            this.getInfo()
        },
        getInfo() {
            const _data = {                
            }
            axios.post('/inf-get-povolania', _data, this.$store.getters.getAxiosConfig )
            .then(res => {
                this.zoznamPovolani = res.data.povolania 
            })
            .catch(error => {
                this.$store.commit('setFeedback',  "User error: " + error)
            })
        },   
        getBackgroundColor(_index) {
            if (_index % 2 == 0) return 'background-color:#FFFFFF'
            return 'background-color:#EEEEEE'
        },
        prepareKompetentnosti(_index) {
            const _data = {     
                povolanie_id: this.zoznamPovolani[_index].id
            }
            axios.post('/inf-get-pov-komp', _data, this.$store.getters.getAxiosConfig )
            .then(res => {
                this.zoznamKompetentnosti = res.data.kompet
                this.showIndex = _index 
            })
            .catch(error => {
                this.$store.commit('setFeedback',  "User error: " + error)
            })
        },
        getCompColor(_poc){
            if (_poc>0) return "color:green"
            return "color:red"
        },
        getPredmety(_index) {
            const _data = {     
                kompetentnost_id: this.zoznamKompetentnosti[_index].id
            }
            axios.post('/inf-get-predmet-komp', _data, this.$store.getters.getAxiosConfig )
            .then(res => {
                this.zoznamPredmetov = res.data.predmety
                this.predmetIndex = _index 
            })
            .catch(error => {
                this.$store.commit('setFeedback',  "User error: " + error)
            })
        },        
        toInfolist(_id) {
            this.$router.push({name:'infolist', params: {id: _id}})   
        }
  }
}
  
</script>

<style>

</style>