<template>
<v-container>
    <v-layout row wrap>
      <v-flex xs12 >
        <v-card v-if="myContent">
            <v-card-title>
                {{myContent.nazov}}
            </v-card-title>
            <v-card-text>                  
                <b>Forma výučby: </b>
                <span v-if="myContent.prednasky > 0">prednáška</span>
                <span v-if="myContent.prednasky > 0 && myContent.cvicenia > 0"> / </span>
                <span v-if="myContent.cvicenia > 0">seminár</span>
                <br>
                <b>Odporúčaný rozsah:</b>
                {{myContent.prednasky}} / {{myContent.cvicenia }} ({{myContent.prednasky*13}} / {{myContent.cvicenia*13}})
                <br>
                <b>Počet kreditov:</b> {{myContent.kredity}}
                <b>Stupeň štúdia:</b> {{myContent.stupen}}
                <b>Ukončenie predmetu:</b> {{myContent.ukoncenie}}
                <v-textarea 
                    label = "Podmienky absolvovania"
                    v-model="podm_abs"
                    filled
                    rows="6"
                    >
                </v-textarea> 
                <v-textarea 
                    label = "Výsledky vzdelávania"
                    v-model="vysl_vzdel"
                    filled
                    rows="10"
                    >
                </v-textarea> 
                <v-textarea 
                    label = "Stručná osnova predmetu"
                    v-model="sylaby"
                    filled
                    rows="10"
                    >
                </v-textarea>
                <v-textarea 
                    label = "Literatúra"
                    v-model="myContent.literatura"
                    filled
                    rows="10"
                    >
                </v-textarea>
            </v-card-text>            
        </v-card>
      </v-flex>
   </v-layout>
</v-container>
</template>

<script>
import axios from '../../../axios-auth.js'
import {mapGetters} from 'vuex'

  export default {
    data() {
      return {
            panels: [],
            myContent: null,
            podm_abs: '',
            vysl_vzdel: '',
            sylaby: '',
            literatura: ''
        }
    },      
    computed: 
        mapGetters([
                        'isAdmin'
                    ]),
    mounted () {
      this.initData()
    },
    methods: {
        initData() {
            this.getInfo(this.$route.params.id)
        },
        getInfo(_id) {
            const data = {
                predmet_id : _id
            }
            axios.post('/inf-get-infolist', data, this.$store.getters.getAxiosConfig )
            .then(res => {
                // eslint-disable-next-line no-console
                // console.log(res.data)
                if (res.data.message=='OK') {
                    this.myContent = res.data 

                    // eslint-disable-next-line no-console
                   //console.log(res.data)
                   this.podm_abs = this.processPodmAbs()
                   this.vysl_vzdel = this.processVysVzdel()
                   this.sylaby = this.processSylaby()
                   this.literatura = this.myContent.literatura

                } else {
                    this.$store.commit('setFeedback',  "User error: " + res.data.message)    
                }
            })
            .catch(error => {
                this.$store.commit('setFeedback',  "User error: " + error)
            })
        },   
        processPodmAbs() {
            var _prednasky = 0
            var _cvicenie = 0
            var _doma = 0
            for(let i=0; i<this.myContent.sylaby.length; i++) {
                _prednasky += this.myContent.sylaby[i].hodin_prednasky
                _cvicenie += this.myContent.sylaby[i].hodin_cvicenia
                _doma += this.myContent.sylaby[i].hodin_doma
            }
            var _data = 'Celková záťaž študenta: '+(_prednasky + _cvicenie + _doma)+' hodín.\nZ toho: '
            if (_prednasky > 0) _data += 'prednášky - ' + _prednasky + ' hodín, '
            if (_cvicenie > 0) _data += 'semináre - ' + _cvicenie + ' hodín, '
            if (_doma > 0) _data += 'domáca príprava - ' + _doma + ' hodín. \n'
            _data += "Podmienky absolvovania: " + this.myContent.podm_abs
            return _data
        },
        processVysVzdel() {
            const _zrucnosti = []
            const _znalosti = []
            for(let i=0; i<this.myContent.komp.length; i++) {
                if (this.myContent.komp[i].typ == 'zručnosti') _zrucnosti.push(this.myContent.komp[i].popis)
                if (this.myContent.komp[i].typ == 'znalosti') _znalosti.push(this.myContent.komp[i].popis)
                if (this.myContent.komp[i].typ == '') _zrucnosti.push(this.myContent.komp[i].popis)
            }
            var _data = ''
            if (_znalosti.length > 0) {
                _data = 'Znalosti: \n'
                for(let i=0; i<_znalosti.length; i++)  {
                    _data += ' - ' + _znalosti[i]  + '\n'              
                }
            }
            if (_zrucnosti.length > 0) {
                _data += '\nZručnosti: \n'
                for(let i=0; i<_zrucnosti.length; i++)  {
                    _data += ' - ' + _zrucnosti[i] + '\n'               
                }
            }
            _data += '\nVýsledky vzdelávania: \n'            
            _data += ' - ' + this.hardReplace(this.myContent.vysl_vzdel,'\n','\n - ')
            if (this.myContent.vysl_vzdel.substring(this.myContent.vysl_vzdel.length-1) == '\n')
                _data = _data.substring(0,_data.length-4)            
            return _data
        },
        hardReplace(_text,_old,_new) {
            var _start = 0
            while (_text.indexOf(_old, _start)> -1) {
              _start = _text.indexOf(_old, _start)  
              _text = _text.substring(0, _start) + _new + _text.substring(_start + _old.length) 
              _start += 1
            }
            return _text
        },
        processSylaby() {
            var _data = ''
            for(let i=0; i<this.myContent.sylaby.length; i++) {
                _data += (i+1) +  '. '+ this.myContent.sylaby[i].tema + '\n'
                while (_data.substring(_data.length-2,_data.length) == '\n\n') _data = _data.substring(0,_data.length-1)
                if (this.myContent.sylaby[i].popis.length > 0 || this.myContent.sylaby[i].hodin_doma > 0) {
                    _data += '  - domáca príprava: '
                    if (this.myContent.sylaby[i].popis.length > 0) _data += this.myContent.sylaby[i].popis
                    if (this.myContent.sylaby[i].hodin_doma > 0) {
                        if (this.myContent.sylaby[i].popis.length > 0) {
                           _data += ' (' + this.myContent.sylaby[i].hodin_doma + ' hod.)'
                        } else {
                           _data += this.myContent.sylaby[i].hodin_doma + ' hod.'
                        }
                    }
                    _data += '\n'
                }
            }
            return _data
        }

        
  }
}
  
</script>

<style>

</style>