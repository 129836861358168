<template>
    <v-row style="margin:2px">
        <v-col cols=12 v-if="isAdmin">
            <v-card color ="red">
                Admin nemá výkazy
            </v-card>
        </v-col>
        <v-col cols=12 v-if="!isAdmin">
          <v-card>
            <v-card-title>
                <v-row style="margin:2px">
                    <v-col cols=1></v-col>    
                    <v-col cols=1>
                        {{month}} / {{year}}
                    </v-col>
                    <v-col cols=2>
                        {{meno}} {{priezvisko}}
                    </v-col>
                    <v-col cols=2>
                        stav výkazu: 
                        <span :style="getBgColor(stav_vykazu)">{{stav_vykazu}}</span>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text v-if="myComments">
                <v-row style="margin:2px">
                    <v-col cols=1></v-col>
                    <v-col cols=10>
                        <strong>Pripomienky administrátora: </strong>
                        <span style="color:red">{{myComments}}</span>
                    </v-col>
                </v-row>
            </v-card-text>
          </v-card>


        <v-card v-if="year>0" outlined raised color = "lightgray" style="margin: 10px">
            <v-card-title>
                <v-row>
                    <v-col cols=1></v-col>
                    <v-col cols=8>
                        <v-card>
                            <v-card-title>
                                Zoznam činností
                            </v-card-title>
                            <v-card-text>
                                <v-row v-for="(project, index) in projects" :key="index">
                                    <v-col cols=12 v-for="(aktivita, index2) in project.aktivity" :key="index2" style="margin:0px">
                                        <v-row v-if="getAktivitaPocetHodin(index,index2) > 0 && project.id != 1">        
                                            <v-col style="margin:0px" cols=1>  </v-col>
                                            <v-col style="margin:0px" cols=2>        
                                                {{project.nazov}}
                                            </v-col>
                                            <v-col style="margin:0px" cols=2>        
                                                {{aktivita.nazov}}
                                            </v-col>
                                            <v-col style="margin:0px" cols=3>        
                                                {{getAktivitaPocetHodin(index,index2)}}
                                            </v-col>                                            
                                        </v-row>                                    
                                    </v-col>
                                </v-row>
                                <div v-if="myPoznamky">
                                    <p></p>
                                    <strong>Pozn.:</strong> {{myPoznamky}}
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols=1 v-if="stav_vykazu == 'uzavretý'"></v-col>
                    <v-col cols=1 v-if="stav_vykazu == 'uzavretý'">
                            <v-col cols=12>
                                <v-btn color="secondary" style="width:100%; margin-top: 10px" @click="otvorVykaz()">Otvor výkaz </v-btn>    
                            </v-col>
                    </v-col>

                    <v-col cols=2 v-if="stav_vykazu == '' || stav_vykazu == 'otvorený'">
                        <v-row style="margin:2px">
                            <v-col cols=12>
                                <v-btn v-if="popis_aktivity != 'nevyplnené...' && popis_vystupu != 'nevyplnený...' && !editovat_popis" color="secondary" style="width:100%; margin-top: 10px" @click="uzatvorVykaz()">Uzatvor výkaz </v-btn>    
                            </v-col>
                            <!-- <v-col cols=12>
                                <v-btn color="secondary" style="width:100%; margin-top: 10px" @click="vymazObsah()">Vymaž obsah výkazu</v-btn>    
                            </v-col> -->

                            <v-col cols=12>
                                <v-btn small color="primary" style="width:100%" @click="showObed = true">Nastav obedy... </v-btn>    
                            </v-col>
                            <v-col cols=12 v-if="!showObed">
                                <v-btn small color="primary"  style="width:100%" @click="showRozvrh = true">Nastav rozvrh... </v-btn>
                            </v-col>
                            <v-col cols=12 v-if="false"> <!--!showGenerator -->
                                <v-btn small color="primary"  style="width:100%" @click="showGenerator = true">Generator :)</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
               
                    <v-col cols=10 v-if="showObed"></v-col>
                    <v-col cols=2  v-if="showObed">
                        <v-card>
                            <v-card-title>
                                Zadajte začiatok obeda
                            </v-card-title>
                            <v-card-text>
                                <v-time-picker v-model="obed" format="24hr" style="margin:10px"></v-time-picker>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn @click ="nastavObedy()" style="margin:10px">OK</v-btn>                                    
                                <v-btn @click ="showObed = false" style="margin:10px">Zrušiť</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                    <v-col cols=10 v-if="showRozvrh"></v-col>
                    <v-col cols=2 v-if="showRozvrh">
                        <v-card>
                            <v-card-title>
                                <v-row style="margin:2px">
                                    <v-col cols=12>
                                        Vyberte rozvrh
                                    </v-col>
                                    <v-col cols=12 v-if="feedback">
                                        <v-alert type="error">
                                            {{feedback}}
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <v-select
                                    :items="rozvrhyList"
                                    v-model="selectedRozvrh"
                                    label="Rozvrh:"
                                ></v-select>

                                <v-text-field label="Aplikovať od:" type ="number" max="31" min="1" step="1" v-model="rozvrh_od_dna" style="margin-right:10px"></v-text-field>
                                <v-text-field label="Aplikovať do:" type ="number" max="31" min="1" step="1" v-model="rozvrh_do_dna" style="margin-right:10px"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn @click ="nastavRozvrh()" style="margin:10px">OK</v-btn>                                    
                                <v-btn @click ="showRozvrh = false" style="margin:10px">Zrušiť</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col> 

                    <v-col cols=10 v-if="showGenerator"></v-col>
                    <v-col cols=2 v-if="showGenerator">
                        <v-card>
                            <v-card-title>
                                <v-row style="margin:2px">
                                    <v-col cols=12>
                                        Vyberte projekt a aktivitu
                                    </v-col>
                                    <v-col cols=12 v-if="feedback">
                                        <v-alert type="error">
                                            {{feedback}}
                                        </v-alert>
                                    </v-col>
                                    <v-col cols=12>
                                        <v-select style="margin:10px"
                                            :items="projectsList"
                                            v-model="selectedProject"
                                            label="Projekt:"
                                            @change = "refreshActivities()"
                                        ></v-select>
                                        <v-select style="margin:10px"
                                            :items="activitiesList"
                                            v-model="selectedActivity"
                                            label="Aktivita:"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <v-text-field label="Aplikovať od:" type ="number" max="31" min="1" step="1" v-model="rozvrh_od_dna" style="margin-right:10px"></v-text-field>
                                <v-text-field label="Aplikovať do:" type ="number" max="31" min="1" step="1" v-model="rozvrh_do_dna" style="margin-right:10px"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn @click ="generuj()" style="margin:10px">OK</v-btn>                                    
                                <v-btn @click ="showGenerator = false" style="margin:10px">Zrušiť</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col> 

                     <v-col cols=1></v-col>
                     <v-col cols=8>
                        <v-card v-if="true">
                            <v-card-title>
                                Popis činnosti
                            </v-card-title>                            
                            <v-card-text>  
                                <p>Oprávnená činnosť v súlade s opisom projektu, napr.: Účasť na: seminároch, školeniach, konferenciách, workshopoch, odborných stretnutiach, pracovných skupinách a iných + názov spracovávaného výstupu/časti výstupu/míľnika, názov vytváraného administratívneho/odborného dokumentu/časti dokumentu, názvy analyzovaných vstupných materiálov a iné.)</p>
                                <p v-if="!editovat_popis">
                                    <v-icon v-if="(stav_vykazu == '' || stav_vykazu == 'otvorený') && !editovat_popis" style="margin-top:10px" @click="editovat_popis = true">edit</v-icon>
                                </p>
                                <p v-if="!editovat_popis">
                                    {{popis_aktivity}}
                                </p>
                                <v-textarea v-if="editovat_popis"
                                    label = "Popis aktivít počas mesiaca: "
                                    v-model="popis_aktivity"
                                    name = "pa"
                                    filled
                                    >
                                    </v-textarea>
                                <v-btn v-if="editovat_popis" small @click="ulozCinnost()">OK</v-btn>
                            </v-card-text>
                        </v-card>
                     </v-col>                
                     <v-col cols=1></v-col>
                    <v-col cols=8>
                        <v-card v-if="true">
                            <v-card-title>
                                Výstup/Výsledok pracovnej činnosti:
                            </v-card-title>                            
                            <v-card-text>  
                                        <p>Výstup činnosti</p>
                                        <p v-if="!editovat_vystup">
                                            <v-icon v-if="(stav_vykazu == '' || stav_vykazu == 'otvorený') && !editovat_vystup" style="margin-top:10px" @click="editovat_vystup = true">edit</v-icon>
                                        </p>
                                        <p v-if="!editovat_vystup">
                                            {{popis_vystupu}}
                                        </p>
                                <v-textarea v-if="editovat_vystup"
                                    label = "Popis výstupov počas mesiaca: "
                                    v-model="popis_vystupu"
                                    name = "pa"
                                    filled
                                    >
                                    </v-textarea>
                                <v-btn v-if="editovat_vystup" small @click="ulozVystup()">OK</v-btn>
                            </v-card-text>
                        </v-card>
                     </v-col>                

                </v-row>
            </v-card-title>
        </v-card>
        <v-card v-if="mycontent">
            <v-card-text>
                <v-row v-for="(day,index) in mycontent.days" :key="index" style="margin:2px">
                <v-row :style="getbgColor(day.day_of_week)">
                    <v-col cols=1 style="align-items: center; justify-content: center"  align="center">
                        <h2>{{day.day}}</h2>
                        <p>{{dni[day.day_of_week]}}</p>
                    </v-col>
                    <v-col cols=2>
                        <v-row style="margin:2px">
                            <v-col cols=8 :style="getColorTime(day.end_hours + day.end_minutes)">        
                                    {{dm2(day.start_hours)}}:{{dm2(day.start_minutes)}} - {{dm2(day.end_hours)}}:{{dm2(day.end_minutes)}} 
                            </v-col>        
                            <v-col cols=4>
                                <v-icon v-if="(stav_vykazu == '' || stav_vykazu == 'otvorený') && editovanie_dochadzky" style="margin-top:5px" @click="editDochadzka(day.id, day)">edit</v-icon>
                            </v-col>   
                            <v-col cols =12>                                
                                <v-select
                                    :items="dayTypes"
                                    v-model="day.day_type"
                                    label=""
                                    @change="saveDochadzka(index, 2)"
                                    disabled
                                ></v-select>                                   
                            </v-col>                   
                        </v-row>
                    </v-col>
                    <v-col cols=5 v-if="unp_dochadzka == day.day"> 
                        <v-card>
                            <v-card-text>
                                <v-row style="margin:2px">
                                    <v-col cols=12 v-if="feedback">
                                        <v-alert type="error">
                                            {{feedback}}
                                        </v-alert>
                                    </v-col>
                                    <v-col cols=10>
                                        <v-time-picker v-model="dochadzka_od" format="24hr" style="margin:10px"></v-time-picker>
                                        <v-time-picker v-model="dochadzka_do" format="24hr" style="margin:10px"></v-time-picker>
                                    </v-col>
                                    <v-col cols=2>
                                        <v-btn @click ="saveDochadzka(index, 1)" style="margin:10px">OK</v-btn>                                    
                                        <v-btn @click ="unp_dochadzka = -1" style="margin:10px">Zrušiť</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                           
                    <v-col cols=9>
                        <v-card v-if="day.activities.length>0" flat :style = "getbgColor(day.day_of_week)">
                            <v-card-text>
                                <v-row v-for="(actv,index2) in day.activities" :key="index2" style="margin:2px">
                                    <v-col cols=2>                                                   
                                        {{dm2(actv.start_hours)}}:{{dm2(actv.start_minutes)}} - {{dm2(actv.end_hours)}}:{{dm2(actv.end_minutes)}}
                                    </v-col>
                                    <v-col cols=2>
                                        {{actv.project_name}}
                                    </v-col>
                                    <v-col cols=2>
                                        {{actv.activity_name}}
                                    </v-col>
                                    <v-col cols=2>
                                        {{actv.wlength}}
                                    </v-col>
                                    <v-col cols=2>
                                        <v-icon v-if="(stav_vykazu == '' || stav_vykazu == 'otvorený') && actv.project_id !=7 && actv.project_id !=3" color="primary" @click="editActivity(index, index2)">edit</v-icon>
                                        <v-icon v-if="(stav_vykazu == '' || stav_vykazu == 'otvorený') && actv.project_id !=7 && actv.project_id !=3" color="primary" @click="deleteActivity(index, index2)">delete</v-icon>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols=6></v-col>
                                    <v-col cols=2 offset-6>
                                        <span style = "getColorLength(sucetLength(index))"><strong> {{sucetLength(index)}} </strong></span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card flat :style = "getbgColor(day.day_of_week)">
                            <v-btn 
                                v-if="stav_vykazu == '' || stav_vykazu == 'otvorený' && (day.day_type=='práca' || day.day_type=='služobná cesta')" 
                                small @click="addAktivity(day.id, day)">
                                <v-icon>mdi-plus-circle-outline</v-icon>Aktivity
                            </v-btn>
                        </v-card>
                         <br>
                    </v-col>
                    <v-col cols = 9 v-if="unpack == day.day">
                        <v-card>
                            <v-card-title>
                                <v-row style="margin:2px">
                                    <v-col cols=12 v-if="feedback">
                                        <v-alert type="error">
                                            {{feedback}}
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <v-row style="margin:2px">
                                    <v-col cols=8>
                                        <v-time-picker v-model="cas_od" format="24hr"></v-time-picker>
                                        <v-time-picker v-model="cas_do" format="24hr"></v-time-picker>
                                    </v-col>
                                    <v-col cols=4>
                                            <v-select
                                                :items="projectsList"
                                                v-model="selectedProject"
                                                label="Projekt:"
                                                @change = "refreshActivities()"
                                            ></v-select>
                                            <v-select
                                                :items="activitiesList"
                                                v-model="selectedActivity"
                                                label="Aktivita:"
                                            ></v-select>
                                            <v-textarea
                                            label = "Popis:"
                                            v-model="popis"
                                            name = "ta"
                                            filled
                                            >

                                            </v-textarea>
                                        <v-checkbox
                                        label = "mimo pracovnej doby"
                                        v-model="chb_mimoPracovnejDoby"
                                    ></v-checkbox>
                                        <v-btn @click ="saveActivity(index)" style="margin:10px">OK</v-btn>                                    
                                        <v-btn @click ="unpack = -1" >Zrušiť</v-btn>
                                    </v-col>                                    
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- <v-card :color = "getColor(day.day_of_week)">
                        <v-card-text> 
                            <v-layout row wrap>
                                <v-col cols=2>
                                    <v-layout row wrap>
                                    <v-col cols=5>
                                        <v-layout row wrap justify-center>
                                            <v-col cols=12>
                                                <v-layout row wrap justify-center>
                                                    <h2>{{day.day}}</h2> 
                                                </v-layout>
                                            </v-col>
                                            <v-col cols=12>
                                                <v-layout row wrap justify-center>
                                                {{dni[day.day_of_week]}}
                                                </v-layout>
                                            </v-col>
                                        </v-layout>
                                    </v-col>
                                    <v-col cols=7>  
                                        <v-layout row wrap>
                                            <v-col cols=11>  
                                                <v-card flat :color="getColorTime(day.end_hours + day.end_minutes)" style="margin:20px">
                                                        <v-layout justify-center>
                                                                {{dm2(day.start_hours)}}:{{dm2(day.start_minutes)}} - {{dm2(day.end_hours)}}:{{dm2(day.end_minutes)}} 
                                                        </v-layout>
                                                </v-card>
                                            </v-col>
                                            <v-col cols=1>  
                                                <v-layout justify-center>
                                                    
                                                 <v-icon v-if="(stav_vykazu == '' || stav_vykazu == 'otvorený') && editovanie_dochadzky" style="margin-top:20px" @click="editDochadzka(day.id, day)">edit</v-icon>
                                                </v-layout>
                                            </v-col>
                                           
                                            <v-col cols=12 >  
                                                <v-card flat :color="getColor(day.day_of_week)" style="margin:10px">
                                                <v-layout justify-center>
                                                            <v-select
                                                                :items="dayTypes"
                                                                v-model="day.day_type"
                                                                label=""
                                                                @change="saveDochadzka(index, 2)"
                                                                disabled
                                                            ></v-select>                                   
                                                    </v-layout> 
                                                </v-card>   
                                            </v-col>
                                        </v-layout>                                     
                                    </v-col>
                                    </v-layout>
                                </v-col>
                                <v-col cols=5 v-if="unp_dochadzka == day.day">
                                    <v-card>
                                        <v-card-text>
                                            <v-layout row wrap>
                                                <v-col cols=12 v-if="feedback">
                                                    <v-alert type="error">
                                                        {{feedback}}
                                                    </v-alert>
                                                </v-col>
                                                <v-col cols=10>
                                                    <v-time-picker v-model="dochadzka_od" format="24hr" style="margin:10px"></v-time-picker>
                                                    <v-time-picker v-model="dochadzka_do" format="24hr" style="margin:10px"></v-time-picker>
                                                </v-col>
                                                <v-col cols=2>
                                                    <v-btn @click ="saveDochadzka(index, 1)" style="margin:10px">OK</v-btn>                                    
                                                    <v-btn @click ="unp_dochadzka = -1" style="margin:10px">Zrušiť</v-btn>
                                                </v-col>
                                            </v-layout>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                           
                                <v-col cols=9 offset-1>
                                    <v-card v-if="day.activities.length>0" :color = "getColor(day.day_of_week)">
                                        <v-card-text>
                                            <v-layout row wrap v-for="(actv,index2) in day.activities" :key="index2">
                                                <v-col cols=2>                                                   
                                                   {{dm2(actv.start_hours)}}:{{dm2(actv.start_minutes)}} - {{dm2(actv.end_hours)}}:{{dm2(actv.end_minutes)}}
                                                </v-col>
                                                <v-col cols=2>
                                                    {{actv.project_name}}
                                                </v-col>
                                                <v-col cols=2>
                                                    {{actv.activity_name}}
                                                </v-col>
                                                <v-col cols=2>
                                                    {{actv.wlength}}
                                                </v-col>
                                                <v-col cols=2>
                                                    <v-icon v-if="(stav_vykazu == '' || stav_vykazu == 'otvorený') && actv.project_id !=7 && actv.project_id !=3" color="primary" @click="editActivity(index, index2)">edit</v-icon>
                                                    <v-icon v-if="(stav_vykazu == '' || stav_vykazu == 'otvorený') && actv.project_id !=7 && actv.project_id !=3" color="primary" @click="deleteActivity(index, index2)">delete</v-icon>
                                                </v-col>
                                            </v-layout>
                                            <v-layout row wrap>
                                                <v-col cols=2 offset-6>
                                                   <span style = "getColorLength(sucetLength(index))"><strong> {{sucetLength(index)}} </strong></span>
                                                </v-col>
                                            </v-layout>
                                        </v-card-text>
                                    </v-card>

                                    <v-btn v-if="stav_vykazu == '' || stav_vykazu == 'otvorený' && (day.day_type=='práca' || day.day_type=='služobná cesta')" small @click="addAktivity(day.id, day)" style="margin: 5px"><v-icon>mdi-plus-circle-outline</v-icon>Aktivity</v-btn>
                                </v-col>
                                <v-col cols=9 offset-3 v-if="unpack == day.day">
                                    <v-card>
                                        <v-card-title>
                                            <v-col cols=12 v-if="feedback">
                                                    <v-alert type="error">
                                                        {{feedback}}
                                                    </v-alert>
                                                </v-col>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-layout row wrap>
                                                <v-col cols=8>
                                                    <v-time-picker v-model="cas_od" format="24hr"></v-time-picker>
                                                    <v-time-picker v-model="cas_do" format="24hr"></v-time-picker>
                                                </v-col>
                                                <v-col cols=4>
                                                        <v-select
                                                            :items="projectsList"
                                                            v-model="selectedProject"
                                                            label="Projekt:"
                                                            @change = "refreshActivities()"
                                                        ></v-select>
                                                        <v-select
                                                            :items="activitiesList"
                                                            v-model="selectedActivity"
                                                            label="Aktivita:"
                                                        ></v-select>
                                                        <v-textarea
                                                        label = "Popis:"
                                                        v-model="popis"
                                                        name = "ta"
                                                        filled
                                                        >

                                                        </v-textarea>
                                                    <v-checkbox
                                                    label = "mimo pracovnej doby"
                                                    v-model="chb_mimoPracovnejDoby"
                                                ></v-checkbox>
                                                    <v-btn @click ="saveActivity(index)" style="margin:10px">OK</v-btn>                                    
                                                    <v-btn @click ="unpack = -1" >Zrušiť</v-btn>
                                                </v-col>
                                                
                                            </v-layout>
                                        </v-card-text>
                                    </v-card>

                                </v-col>
                            </v-layout>                            
                        </v-card-text>
                    </v-card> -->
                </v-row>
                </v-row>  
            </v-card-text>
        </v-card>
      </v-col> 
    </v-row>
</template>

<script>
import axios from '../../axios-auth.js'
import * as time_override from './time_override.js'
import {mapGetters} from 'vuex'

  export default {
    computed: 
        mapGetters([
                        'isAdmin'
                    ])
    ,
    data() {
      return {
        stav_vykazu: '',
        meno: '',
        priezvisko: '',
        myComments: null,
        myPoznamky: null,
        editovanie_dochadzky: false,
        popis_aktivity: 'nevyplnené...',
        popis_vystupu: 'nevyplnený...',
        editovat_popis: false,
        editovat_vystup: false,
        showObed: false,
        showGenerator: false,
        obed: "12:30",
        showRozvrh: false,
        rozvrhyList: null,
        selectedRozvrh: '',
        rozvrh_od_dna: 1,
        rozvrh_do_dna: 28,
        feedback : null,
        year: 0,
        month: 0,
        days : [],
        dni : ['nedeľa', 'pondelok', 'utorok','streda','štvrtok', 'piatok', 'sobota'],
        dayTypes: ['práca', 'sviatok', 'dovolenka', 'lekár', 'PN', 'voľno', 'služobná cesta'],
        id: null,
        activity_id: -1,
        activity_index: -1,
        // for selects
        projectsList: null,
        activitiesList: null,
        selectedProject: null,
        selectedActivity: null,
        // text in form
        dochadzka_od: null,
        dochadzka_do: null,
        cas_od: null,
        cas_do: null,
        popis: '',
        chb_mimoPracovnejDoby : false,
        // zobrazenie formulara na pridavanie
        unpack: -1,
        unp_dochadzka: -1,
        // ciselniky
        projects: [{
                    id: 1,
                    nazov: "ita",
                    aktivity:[ {
                                id: 1,
                                nazov: 'aktivita 1.1'
                            },{
                                id: 2,
                                nazov: 'aktivita 2.1'
                            }                    
                    ]
                   },
                   {
                    id: 2,
                    nazov: "fake news",
                    aktivity:[ {
                                id: 3,
                                nazov: 'aktivita 2016-18'
                            },{
                                id: 4,
                                nazov: 'aktivita 2018-19'
                            }                    
                    ]
                   },
                   {
                    id: 3,
                    nazov: "eko",
                    aktivity:[ {
                                id: 5,
                                nazov: 'aktivita 2016-18'
                            },{
                                id: 6,
                                nazov: 'aktivita 2018-19'
                            }                    
                    ]
                   }],
            // my content
            mycontent: null,
            /*{
                user_id : 1,
                month: 10,
                year: 2018,
                odpracovane_hodiny
                sviatok
                dovolenka
                lekar
                PN
                nahradne_volno
                plateny_nadcas
                dalsie
                days : [
                    id: 0,
                    day: 1,
                    start_work:
                    end_work:
                    activities: [
                        {
                            id
                            start 
                            end
                            length
                            project
                            activity
                            description
                        }
                    ]
                ]


            }*/
        }
    },      
    mounted () {
      this.initData()
   },
   methods: {
        stringToDate(_date,_format,_delimiter) {
                    var formatLowerCase=_format.toLowerCase();
                    var formatItems=formatLowerCase.split(_delimiter);
                    var dateItems=_date.split(_delimiter);
                    var monthIndex=formatItems.indexOf("mm");
                    var dayIndex=formatItems.indexOf("dd");
                    var yearIndex=formatItems.indexOf("yyyy");
                    var month=parseInt(dateItems[monthIndex]);
                    month-=1;
                    var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
                    return formatedDate;
        },
        getDaysInMonth (month,year) {
            // Here January is 1 based
            //Day 0 is the last day in the previous month
            return new Date(year, month, 0).getDate();
            // Here January is 0 based
            // return new Date(year, month+1, 0).getDate();
        },
        initData() {
            this.$store.commit('setFeedback', null)
            // prepare empty mycontent
            this.id = this.$route.params.id
            // empty data for user
            this.prepareTemplate(this.id)
            // get projects for user
            // this.getUserProjects()
            // read data from db
            
            this.readDataFromDB()
            // report of month 
        },
        prepareTemplate(myDate) {
            this.year = Math.floor(myDate / 100)
            this.month = myDate % 100
            this.mycontent = {
                id: 0,
                month: this.month,
                year: this.year,
                odpracovane_hodiny: 0,
                sviatok: 0,
                dovolenka: 0,
                lekar: 0,
                PN: 0,
                nahradne_volno: 0,
                plateny_nadcas: 0,
                dalsie: 0,
                days: []
            }

            let n = this.getDaysInMonth(this.month, this.year)
            this.rozvrh_do_dna = n
            for(let i = 1; i <= n; i++) {
                let dow = this.stringToDate("" + i + "/" + this.month + "/" + this.year,"dd/MM/yyyy","/").getDay()
                this.mycontent.days.push( {
                    id: 0,
                    day: i,
                    day_type: this.getDayType(dow),
                    day_of_week: dow,
                    start_hours :0,
                    start_minutes :0,
                    end_hours :0,
                    end_minutes :0,
                    activities: []
                })
            }

        },
        getDayType(dow) {
            if (dow == 0 || dow == 6) return "voľno"
            return "práca"
        },
        getProjects() {
            this.projectsList = []
            for(let i = 0; i < this.projects.length; i++) {
                this.projectsList.push(this.projects[i].nazov)
            }
        },
        refreshActivities() {
            this.activitiesList = []
            for(let i = 0; i < this.projects.length; i++) {
                if (this.projects[i].nazov == this.selectedProject) {
                    for(let j = 0; j < this.projects[i].aktivity.length; j++) {
                        this.activitiesList.push(this.projects[i].aktivity[j].nazov)
                    }
                }
            }
        },
        addAktivity(day_id, day) {
            this.feedback = ''
            this.activity_id = 0 
            this.unp_dochadzka = - 1
            this.unpack = day.day
            this.cas_od = '00:00'
            this.cas_do = '00:00'
        }, 
        editActivity(index, index2) {
           // eslint-disable-next-line no-console
           // console.log(this.mycontent.days[index].activities[index2]);
           this.activity_id = this.mycontent.days[index].activities[index2].id
           this.activity_index = index2
           this.cas_od = this.mycontent.days[index].activities[index2].start_hours+':'+this.mycontent.days[index].activities[index2].start_minutes
           this.cas_do = this.mycontent.days[index].activities[index2].end_hours+':'+this.mycontent.days[index].activities[index2].end_minutes
           this.selectedProject = this.mycontent.days[index].activities[index2].project_name
           // nacitat aktivity pre projekt
           this.refreshActivities()
           this.selectedActivity = this.mycontent.days[index].activities[index2].activity_name
           this.popis = this.mycontent.days[index].activities[index2].description
           this.chb_mimoPracovnejDoby = this.mycontent.days[index].activities[index2].out_of_work

            // zapis do db

            
           this.unpack = index + 1 // = day
        },
        deleteActivity(index,index2) {
            // zapis do databazy - mazanie podla id
            const data = {
                id : this.mycontent.days[index].activities[index2].id
            }

            axios.post('/delete-aktivita', data, this.$store.getters.getAxiosConfig )
            .then(res => {
            // set data to var
            // eslint-disable-next-line no-console
             // console.log(res.data)
            if (res.data.message == 'OK')
              this.mycontent.days[index].activities.splice(index2, 1)    
            else
              this.$store.commit('setFeedback', res.data.status)
            
            })
            .catch(error => {
                this.$store.commit('setFeedback', error)
            })
            

        },
        getColor(day) {
           if (day==6) return 'blue-grey lighten-5'
           if (day==0) return 'blue-grey lighten-5'
           if (day == 2 || day == 4) return 'yellow lighten-4'
           return 'white'
        },
        getbgColor(day) {
           if (day==6) return 'background-color:silver'
           if (day==0) return 'background-color:silver'
           if (day == 2 || day == 4) return 'background-color:yellow'
           return 'background-color:white'
        },
        getColorTime(minutes) {
            if (minutes == 0) return "color:red"
            return ""
        },
        editDochadzka(day_id, day) {
            this.feedback = ''
            this.unpack = -1
            this.unp_dochadzka = day.day
            this.dochadzka_od = '' + day.start_hours+':'+day.start_minutes
            this.dochadzka_do = '' + day.end_hours+':'+day.end_minutes
        },
        dm2(number) {
            if (number<10) return '0'+number
            else return number
        },
        saveDochadzka(index, type) {
            this.$store.commit('setFeedback', null)
            this.feedback = ''
            if (type == 1) {

                var ind = this.cas_od.indexOf(':')
                let sh = parseInt(this.cas_od.substring(0,ind))
                let sm = parseInt(this.cas_od.substring(ind+1))
                ind = this.cas_do.indexOf(':')
                let eh = parseInt(this.cas_do.substring(0,ind))
                let em = parseInt(this.cas_do.substring(ind+1))
                if (sh*60+sm >= eh*60+em) {
                        this.feedback = 'Koniec pracovnej musí byť neskôr ako začiatok.'
                        return 
                }

                this.mycontent.days[index].start_hours = sh
                this.mycontent.days[index].start_minutes = sm
                this.mycontent.days[index].end_hours = eh
                this.mycontent.days[index].end_minutes = em
                this.unp_dochadzka = - 1
            }
            
            // eslint-disable-next-line no-console
            // console.log(this.mycontent.days[index].day_type)

            // zapis do databazy
            const data = {
                day: index + 1,
                month: this.month,
                year: this.year,
                day_type : this.mycontent.days[index].day_type,
                start_hour: this.mycontent.days[index].start_hours,
                start_minute: this.mycontent.days[index].start_minutes,
                end_hour: this.mycontent.days[index].end_hours,
                end_minute: this.mycontent.days[index].end_minutes
            }

            axios.post('/zapis-dochadzka', data, this.$store.getters.getAxiosConfig )
            .then(res => {
            // set data to var
            // eslint-disable-next-line no-console
             // console.log(res.data)
             if (res.data.message !='OK')
                this.$store.commit('setFeedback', res.data.status)
            })
            .catch(error => {
                this.$store.commit('setFeedback', error)
            })
        },
        readDataFromDB() {
            axios.get('/get-my-projekty-aktivity', this.$store.getters.getAxiosConfig )
            .then(res => {
            // set data to var
                //console.log(res.data)
                this.projects = res.data.list;
                // eslint-disable-next-line no-console
                //console.log(this.projects)

                this.getProjects()
                this.refreshActivities()

                const data = {
                    month: this.month,
                    year: this.year
                }

                axios.post('/get-my-dochadzka', data, this.$store.getters.getAxiosConfig )
                .then(res => {
                // set data to var
                // eslint-disable-next-line no-console
                // console.log(res.data);
                
                this.stav_vykazu = res.data.stav
                this.popis_aktivity = res.data.popis_aktivity
                this.popis_vystupu = res.data.popis_vystupy

                this.meno = res.data.meno
                this.priezvisko = res.data.priezvisko
                this.myComments = res.data.komentar
                this.myPoznamky = res.data.poznamky

                for(let i = 0; i < res.data.list.length; i++) {
                    let myday = res.data.list[i].day - 1 // index of day
                    this.mycontent.days[myday].id = res.data.list[i].id
                    this.mycontent.days[myday].start_hours = res.data.list[i].start_hour
                    this.mycontent.days[myday].start_minutes = res.data.list[i].start_minutes
                    this.mycontent.days[myday].end_hours = res.data.list[i].end_hour
                    this.mycontent.days[myday].end_minutes = res.data.list[i].end_minutes
                    this.mycontent.days[myday].day_type = res.data.list[i].day_type
                    
                    for(let j = 0; j < res.data.list[i].activities.length; j++) {
                        const act = {
                            id : res.data.list[i].activities[j].id,
                            start_hours : res.data.list[i].activities[j].start_hours,
                            start_minutes : res.data.list[i].activities[j].start_minutes,
                            end_hours : res.data.list[i].activities[j].end_hours,
                            end_minutes : res.data.list[i].activities[j].end_minutes,
                            wlength : res.data.list[i].activities[j].wlength,
                            project_id : res.data.list[i].activities[j].project_id,
                            project_name: this.getProjectName(res.data.list[i].activities[j].project_id),
                            proj_activity_id : res.data.list[i].activities[j].proj_activity_id,
                            activity_name : this.getActivityName(res.data.list[i].activities[j].proj_activity_id),
                            description : res.data.list[i].activities[j].description,
                            detail : res.data.list[i].activities[j].detail,
                            out_of_work : res.data.list[i].activities[j].out_of_work
                        }
                        this.mycontent.days[myday].activities.push(act)
                        // eslint-disable-next-line no-console
                    // console.log(act);
                    }
                }
                    
                })
                .catch(error => {
                    this.$store.commit('setFeedback',  "User report error " + this.month + "/" + this.year + ": " + error)
                })
            })
            .catch(error => {
               
                this.$store.commit('setFeedback', "User projects error: " + error)
            })

            this.rozvrhy = []
            const data = {
                year: this.year
            }

            axios.post('/get-zoznam-rozvrhov', data, this.$store.getters.getAxiosConfig )
            .then(res => {
            // set data to var
                this.rozvrhy = res.data.list;
                // eslint-disable-next-line no-console
                // console.log(this.rozvrhy);
                this.rozvrhyList = []
                for(let i = 0; i < this.rozvrhy.length; i++)
                    this.rozvrhyList.push(this.rozvrhy[i].nazov + ' (' +this.rozvrhy[i].id + ')')
            })
            .catch(error => {
                this.$store.commit('setFeedback', "User list of timetable error: " + error)
            })                    

        },
        getProjectName(id) {
            // eslint-disable-next-line no-console
            // console.log("savujem activity")
            for(let i = 0; i < this.projects.length; i++){
                if (this.projects[i].id == id) return this.projects[i].nazov
            }
            return "---"
        },
        getActivityName(id) {
            for(let i = 0; i < this.projects.length; i++){
                for(let j = 0; j < this.projects[i].aktivity.length; j++){
                    if (this.projects[i].aktivity[j].id == id) return this.projects[i].aktivity[j].nazov
                }
            }
            return "---"
        },
        getActivityIndex(index, myTime) {
             for(let i = this.mycontent.days[index].activities.length-1; i >= 0; i-- ) {
                if (myTime > this.mycontent.days[index].activities[i].start_hours*60 + this.mycontent.days[index].activities[i].start_minutes) {
                    return i+1
                }
            }
            return 0
        },
        saveActivity(day_index){
            this.feedback = ''
            // eslint-disable-next-line no-console
            // console.log("savujem activity")

            // new activity
            var index = -1
            var project_id = -1
            var proj_activity_id = -1
            var ind = this.cas_od.indexOf(':')
            let sh = parseInt(this.cas_od.substring(0,ind))
            let sm = parseInt(this.cas_od.substring(ind+1))
            ind = this.cas_do.indexOf(':')
            let eh = parseInt(this.cas_do.substring(0,ind))
            let em = parseInt(this.cas_do.substring(ind+1))
            let mimo = this.chb_mimoPracovnejDoby

            // koniec pred startom
            if (sh*60+sm >= eh*60+em) {
                    this.feedback = 'Koniec aktivity musí byť neskôr ako začiatok.'
                    return 
            }

            // mimo dochadzky
            if (!mimo) {
                
                // eslint-disable-next-line no-console
                // console.log(this.cas_od+"   "+sh + ":" + sm)
                if ((this.mycontent.days[day_index].start_hours != 0) && (this.mycontent.days[day_index].start_minutes != 0) &&
                    (this.mycontent.days[day_index].end_hours != 0) && (this.mycontent.days[day_index].end_minutes != 0)) {

                    if (sh*60+sm < this.mycontent.days[day_index].start_hours*60 + this.mycontent.days[day_index].start_minutes ||
                        eh*60+em > this.mycontent.days[day_index].end_hours*60 + this.mycontent.days[day_index].end_minutes) {
                            this.feedback = 'Aktivita zasahuje mimo pracovnej doby.'
                            return 
                    }
                }
            } else {
                if ((sh*60+sm < this.mycontent.days[day_index].start_hours*60 + this.mycontent.days[day_index].start_minutes &&
                     eh*60+em > this.mycontent.days[day_index].start_hours*60 + this.mycontent.days[day_index].start_minutes) ||
                    (sh*60+sm > this.mycontent.days[day_index].end_hours*60 + this.mycontent.days[day_index].end_hours &&
                     eh*60+em < this.mycontent.days[day_index].end_hours*60 + this.mycontent.days[day_index].end_minutes)) {
                        this.feedback = 'Aktivita zasahuje do pracovnej doby pričom je definovaná mimo nej.'
                        return 
                }               
            }

            // eslint-disable-next-line no-console
            // console.log(this.mycontent.days[day_index].activities)
            // eslint-disable-next-line no-console
            // console.log("KONTROLA> " + sh + ":" + sm + "-" + eh + ":" + em)

            // prekryv s ostatnymi aktivitami
            for(let i = 0; i < this.mycontent.days[day_index].activities.length; i++) {
               
                if (this.activity_id != this.mycontent.days[day_index].activities[i].id) {                   
                    // zaciatok nie je v aktivite
                    let sha = this.mycontent.days[day_index].activities[i].start_hours
                    let sma = this.mycontent.days[day_index].activities[i].start_minutes
                    let eha = this.mycontent.days[day_index].activities[i].end_hours
                    let ema = this.mycontent.days[day_index].activities[i].end_minutes
                    if (time_override.time_override(sh,sm,eh,em,sha,sma,eha,ema))
                     {
                            // eslint-disable-next-line no-console
                            // console.log('TO: ' + sha + ":" + sma + "-" + eha + ":" + ema)
                            this.feedback = 'Aktivita sa prekrýva s už definovanými aktivitami.'
                            // eslint-disable-next-line no-console
                            // console.log(this.mycontent.days[day_index].activities)

                            return 
                    }
                }
            }

            // eslint-disable-next-line no-console
            // console.log("projektami " + this.selectedProject)

            // identifikácia projektu
            for(let i = 0; i < this.projects.length; i++) {
                if (this.projects[i].nazov == this.selectedProject) {
                   index = i
                   project_id = this.projects[i].id
                   break;
                }
            }

            // eslint-disable-next-line no-console
            // console.log("PO projektami ")

            if (project_id == -1) {
                this.feedback = 'Nie je vybraný projekt.'
                return 
            }

            // identifikácia aktivity
            for(let i = 0; i < this.projects[index].aktivity.length; i++) {
                if (this.projects[index].aktivity[i].nazov == this.selectedActivity) {
                   proj_activity_id = this.projects[index].aktivity[i].id
                   break;
                }
            }
             
            if (proj_activity_id == -1) {
                this.feedback = 'Nie je vybraná aktivita projektu.'
                return 
            }


            this.zapisAktivity(day_index,this.activity_id,sh,sm,eh,em,this.selectedProject, project_id, this.selectedActivity, proj_activity_id, this.popis, mimo)
            this.unpack = -1
        },
        // zapisuje aktivitu do FE aj BE - pre usera aj automat
        zapisAktivity(_day_index,_activity_id,sh,sm,eh,em, _selectedProject, _project_id, _selectedActivity, _proj_activity_id, _popis, _mimo) {        
            const myActivity = { // for frontend
                        id : _activity_id,
                        start_hours: sh,
                        start_minutes: sm,
                        end_hours: eh,
                        end_minutes: em,
                        wlength: (eh*60+em-sh*60-sm) / 60,
                        project_name: _selectedProject,
                        project_id: _project_id,
                        activity_name: _selectedActivity,
                        proj_activity_id: _proj_activity_id,
                        description: _popis,
                        detail: '',
                        out_of_work: _mimo                   
            }           

            const data = {  // for backend
                        id : _activity_id,
                        day: _day_index + 1,
                        month: this.mycontent.month,
                        year: this.mycontent.year,
                        start_hours: sh,
                        start_minutes: sm,
                        end_hours: eh,
                        end_minutes: em,
                        wlength: (eh*60+em-sh*60-sm) / 60,
                        project_id: _project_id,
                        proj_activity_id: _proj_activity_id,
                        description: _popis,
                        detail: '',
                        out_of_work: _mimo                   
            }

            if (_activity_id != 0) { // ak uz existuje vymazem ju a nanovo pridam
                // najdem si ju v danom dni podla id, resp. ju mam v activity_index
                let myIndexID = this.getIndexAktivitybyID(_day_index, _activity_id)
                this.mycontent.days[_day_index].activities.splice(myIndexID, 1)                
            }    
                
            // najdem poziciu
            let myIndex = this.getActivityIndex(_day_index, sh*60 + sm)
            // a vlozim ju tam
            this.mycontent.days[_day_index].activities.splice(myIndex, 0, myActivity)

            // eslint-disable-next-line no-console
            // console.log(data)


            // zapis do databazy - vratenie id 
            axios.post('/zapis-aktivita', data, this.$store.getters.getAxiosConfig )
            .then(res => {
               // nastavim v pripade novej id
                if (_activity_id == 0)
                    this.mycontent.days[_day_index].activities[myIndex].id = res.data.day_activity_id

                // eslint-disable-next-line no-console
                // console.log(data)
            })
            .catch(error => {
                this.$store.commit('setFeedback', error)
            })               
            
        },
        getIndexAktivitybyID(day_index, id) {
             for(let i = 0; i < this.mycontent.days[day_index].activities.length; i++ ) {
                if (id == this.mycontent.days[day_index].activities[i].id) {
                    return i
                }
            }
            return -1    
        },
        nastavRozvrh() {
            this.feedback = ''

            if (this.selectedRozvrh == '') {
                this.feedback = 'Nie je vybraný rozvrh.'
                return
            }

            if (this.rozvrh_od_dna > this.rozvrh_do_dna) {
                this.feedback = 'Koncový dátum je menší ako začiatočný.'
                return
            }

            // ziskaj id rozvrhu
            let zac = this.selectedRozvrh.lastIndexOf('(') + 1
            let kon = this.selectedRozvrh.lastIndexOf(')')
            const data = {
                id: parseInt(this.selectedRozvrh.substring(zac,kon))
            }
            // give me rozvrh   
            axios.post('/get-rozvrh-by-id', data, this.$store.getters.getAxiosConfig )
            .then(res => {
            // set data to var
                let rozvrh = res.data.list[0]

                for(let i = this.rozvrh_od_dna; i<= this.rozvrh_do_dna; i++) {
                    if (this.mycontent.days[i-1].day_type == 'práca' && this.mycontent.days[i-1].id != 0 && this.mycontent.days[i-1].day_of_week != 6 && this.mycontent.days[i-1].day_of_week != 0) {

                        let _day = this.mycontent.days[i-1].day_of_week

                        // eslint-disable-next-line no-console
                        // console.log(this.mycontent.days[i-1].day +": " + _day)


                        // prejdem rozvrh pre dany den
                        for(let j = 0; j < rozvrh.dni[_day-1].length; j++) {
                            let sh = rozvrh.dni[_day-1][j].start_hours
                            let sm = rozvrh.dni[_day-1][j].start_minutes
                            let eh = rozvrh.dni[_day-1][j].end_hours
                            let em = rozvrh.dni[_day-1][j].end_minutes
                            // idem zaradit rozvrh
                            var mozno_zapisat = true
                            // prekryv s ostatnymi aktivitami
                            for(let ii = 0; ii < this.mycontent.days[i-1].activities.length; ii++) {
                                    // zaciatok nie je v aktivite
                                    let sha = this.mycontent.days[i-1].activities[ii].start_hours
                                    let sma = this.mycontent.days[i-1].activities[ii].start_minutes
                                    let eha = this.mycontent.days[i-1].activities[ii].end_hours
                                    let ema = this.mycontent.days[i-1].activities[ii].end_minutes
                                    if (time_override.time_override(sh,sm,eh,em,sha,sma,eha,ema))
                                    { 
                                        mozno_zapisat = false
                                        break
                                    }
                            }
                            if (mozno_zapisat) this.zapisAktivity(i-1, 0, sh, sm, eh, em, 'výučba', 2, 'výučba', 2, rozvrh.dni[_day-1][j].nazov, false)
                        }                      
                    }
                }
                this.showRozvrh = false
 
            })
            .catch(error => {
                this.$store.commit('setFeedback', "Timetable application error: " + error)
            })     
        },
        nastavObedy() {
            // ak sa neprekryva vloz obed
            // zapisAktivity(_day_index,_activity_id,sh,sm,eh,em, _selectedProject, _project_id, _selectedActivity, _proj_activity_id, _popis, _mimo)
            
            var ind = this.obed.indexOf(':')
            let sh = parseInt(this.obed.substring(0,ind))
            let sm = parseInt(this.obed.substring(ind+1))
            var eh = sh
            var em = sm + 30
            if (sm >= 30) {
                eh = sh + 1
                em = (sm + 30 ) % 60
            } 
            for(var day_index = 0; day_index < this.mycontent.days.length; day_index++) {
                if (this.mycontent.days[day_index].id > 0) {// ak existuje zapis v db
                    
                 // eslint-disable-next-line no-console
                 // console.log(this.mycontent.days[day_index].day_type)

                    this.mycontent.days[day_index].day_type

                    if (this.mycontent.days[day_index].day_type != "práca") continue
                    var mozno_zapisat = true
                    // prekryv s ostatnymi aktivitami
                    for(let i = 0; i < this.mycontent.days[day_index].activities.length; i++) {
                            // zaciatok nie je v aktivite
                            let sha = this.mycontent.days[day_index].activities[i].start_hours
                            let sma = this.mycontent.days[day_index].activities[i].start_minutes
                            let eha = this.mycontent.days[day_index].activities[i].end_hours
                            let ema = this.mycontent.days[day_index].activities[i].end_minutes
                            if (time_override.time_override(sh,sm,eh,em,sha,sma,eha,ema))
                            { 
                                mozno_zapisat = false
                                break
                            }
                    }
                    if (mozno_zapisat) this.zapisAktivity(day_index, 0, sh, sm, eh, em, 'obed', 1, 'obed', 1, '', false)
                }
            }
            this.showObed = false

        }, 
        vymazObsah() {
            // zapis do databazy - mazanie podla id
            const data = {
                month : this.month,
                year: this.year
            }

            axios.post('/delete-obsah-vykazu', data, this.$store.getters.getAxiosConfig )
            .then(res => {
            // set data to var
            // eslint-disable-next-line no-console
             // console.log(res.data)
            if (res.data.message == 'OK') {
                for(let i = 0; i < this.mycontent.days.length; i++) {
                    while (this.mycontent.days[i].activities.length > 0) {
                        this.mycontent.days[i].activities.splice(0, 1)    
                    }
                }
            }
            else
              this.$store.commit('setFeedback', res.data.status)
            
            })
            .catch(error => {
                this.$store.commit('setFeedback', error)
            })              
        },
        sucetLength(index) {
            var sum = 0
            for (let i = 0; i < this.mycontent.days[index].activities.length; i++) {
                    // eslint-disable-next-line no-console
                    // console.log(this.mycontent.days[index].activities[i].wlength)
                sum += this.mycontent.days[index].activities[i].wlength
            }
            return sum
        },
        getAktivitaPocetHodin(index,index2) {
            var pocet = 0
            for(let i = 0; i < this.mycontent.days.length; i++) {
                for (let j = 0; j < this.mycontent.days[i].activities.length; j++) {
                    if (this.mycontent.days[i].activities[j].proj_activity_id == this.projects[index].aktivity[index2].id)
                        pocet += this.mycontent.days[i].activities[j].wlength
                }
            }
            return pocet
        },
        ulozCinnost() {
            const data = {
                popis : this.popis_aktivity,
                month : this.month,
                year: this.year
            }

            axios.post('/zapis-cinnosti', data, this.$store.getters.getAxiosConfig )
            .then(res => {
            // set data to var
            // eslint-disable-next-line no-console
             // console.log(res.data)
            if (res.data.message != 'OK') 
              this.$store.commit('setFeedback', res.data.status)
            
            })
            .catch(error => {
                this.$store.commit('setFeedback', error)
            })  


            this.editovat_popis = false
        },
        ulozVystup() {
            const data = {
                popis : this.popis_vystupu,
                month : this.month,
                year: this.year
            }

            axios.post('/zapis-vystupu', data, this.$store.getters.getAxiosConfig )
            .then(res => {
            // set data to var
            // eslint-disable-next-line no-console
             // console.log(res.data)
            if (res.data.message != 'OK') 
              this.$store.commit('setFeedback', res.data.status)
            
            })
            .catch(error => {
                this.$store.commit('setFeedback', error)
            })  


            this.editovat_vystup = false
        },

        getBgColor(stav) {
            if (stav == 'otvorený') return "background-color:#FF8A80;"
            if (stav == 'uzavretý') return "background-color:yellow;"
            if (stav == 'schválený') return "background-color:#76FF03;"
            return "background-color:#40C4FF;"
        },
        uzatvorVykaz() {
            if (this.popis_aktivity == "nevyplnené...") {
                this.$store.commit('setFeedback', "Počkajte s uzavretím do načítania výkazu.")    
                return
            }
            if (this.popis_vystupu == "nevyplnený...") {
                this.$store.commit('setFeedback', "Počkajte s uzavretím do načítania výkazu.")    
                return
            }
            // ak je všetko ok
            // max 7.5 denne
            this.$store.commit('setFeedback', null)
        /*    for (let i = 0 ; i < this.mycontent.days.length; i++ )  {
                var s = 0
                for(let j = 0; j < this.mycontent.days[i].activities.length; j++) {
                    if (this.mycontent.days[i].activities[j].project_id != 1)
                        s = s + this.mycontent.days[i].activities[j].wlength
                }
                if ( s > 7.5) {
                    this.$store.commit('setFeedback', "Dňa " + (i+1) + " sumár aktivít prekračuje 7,5 hodiny. Máte vyplnené aktivity až na: " + s +" hodín.")
                    return
                }
            }
*/


            // vygeneruj dovolenky a sviatky a sluz cesty
            var _dovolenka = 'dovolenka: '
            var _sviatok = 'sviatky: '
            var _lekar = 'lekár: '
            var _PN = 'PN: '
            var _SC = 'služ. cesty: '
            var odpracovane = 0
            var sviatok = 0
            var dovolenka = 0
            var lekar = 0
            var PN = 0
            var nahradne_volno = 0
            var plateny_nadcas = 0

            for (let i = 0 ; i < this.mycontent.days.length; i++ )  {
           /*     if (this.mycontent.days[i].end_hours *60 + this.mycontent.days[i].end_minutes - 
                    this.mycontent.days[i].start_hours *60 - this.mycontent.days[i].start_minutes < 480) {
                        this.$store.commit('setFeedback', "Krátky pobyt na pracovisku v deň: " + (i+1) + "." + this.month)
                        return
                    }
*/
                if (this.mycontent.days[i].day_type == "dovolenka") {
                    if (_dovolenka.length > 11) _dovolenka += ', '
                    _dovolenka +='' + this.mycontent.days[i].day + '.'+ this.month + '.'
                    dovolenka +=  7.5
                }
                if (this.mycontent.days[i].day_type == "sviatok") {                    
                    if (this.mycontent.days[i].day_of_week != 0 && this.mycontent.days[i].day_of_week != 6) {
                        if (_sviatok.length > 9) _sviatok += ', '
                        _sviatok +='' + this.mycontent.days[i].day + '.'+ this.month + '.'
                        sviatok +=  7.5
//                        odpracovane +=  7.5
                    }
                }
                if (this.mycontent.days[i].day_type == "lekár") {
                    if (_lekar.length > 7) _lekar += ', '
                    _lekar +='' + this.mycontent.days[i].day + '.'+ this.month + '.'
                    lekar +=  7.5
                }
                if (this.mycontent.days[i].day_type == "PN") {
                    if (_PN.length > 4) _PN += ', '
                    _PN +='' + this.mycontent.days[i].day + '.'+ this.month + '.'
                    PN +=  7.5
                }
                if (this.mycontent.days[i].day_type == "služobná cesta") {
                    // eslint-disable-next-line no-console
                    //console.log('sc')
                    if (_SC.length > 13) _SC += ', '
                    _SC +='' + this.mycontent.days[i].day + '.'+ this.month + '.'
                    odpracovane +=  7.5
                }

                if ((this.mycontent.days[i].day_type == "práca")) {
                    odpracovane +=  7.5
                }

            }
            var _poznamky = "";
            if (_sviatok.length > 9) _poznamky += _sviatok + ';';
            if (_dovolenka.length > 11) _poznamky += _dovolenka + ';';
            if (_lekar.length > 7) _poznamky += _lekar + ';';
            if (_PN.length > 4) _poznamky += _PN + ';';
            
            //uzavri vykaz
            const data = {
                month : this.month,
                year: this.year,
                // vygenerovany text
                poznamky: _poznamky,
            //    popis: this.popis_aktivity,
                odpracovane_hodiny: odpracovane,
                sviatok: sviatok,
                dovolenka: dovolenka,
                lekar: lekar,
                PN: PN,
                nahradne_volno: nahradne_volno,
                plateny_nadcas: plateny_nadcas
            }

            axios.post('/uzavri-my-vykaz', data, this.$store.getters.getAxiosConfig )
            .then(res => {
            // set data to var
            // eslint-disable-next-line no-console
             // console.log(res.data)
            if (res.data.message == 'OK') {
                this.stav_vykazu = 'uzavretý'
            }
            else
              this.$store.commit('setFeedback', res.data.status)
            
            })
            .catch(error => {
                this.$store.commit('setFeedback', error)
            })  
        },
        otvorVykaz() {
            const data = {
                user_id: this.$store.getters.getUserID,
                rok: this.year,
                mesiac: this.month,
                stav: "otvorený"
            }

            // eslint-disable-next-line no-console
            // console.log(data)

            axios.post('/zmena-stavu-vykazu', data, this.$store.getters.getAxiosConfig )
            .then((res) => {
            // set data to var
                if (res.data.message == 'OK')
                    this.stav_vykazu = "otvorený"
                else {
                    this.$store.commit('setFeedback', res.data.status)
                    this.feedback = res.data.status
                }
            })
        },
        getColorLength(ln) {
            if (ln > 8) return "color: red"
            else return "color:primary"
        },
        generuj() {
            this.feedback = null
            var proj_activity_id = -1
            var project_id = -1
            var index = -1

            // identifikácia projektu
            for(let i = 0; i < this.projects.length; i++) {
                if (this.projects[i].nazov == this.selectedProject) {
                   index = i
                   project_id = this.projects[i].id
                   break;
                }
            }

            if (project_id == -1) {
                this.feedback = 'Nie je vybraný projekt.'
                return 
            }

            // identifikácia aktivity
            for(let i = 0; i < this.projects[index].aktivity.length; i++) {
                if (this.projects[index].aktivity[i].nazov == this.selectedActivity) {
                   proj_activity_id = this.projects[index].aktivity[i].id
                   break;
                }
            }

            if (proj_activity_id == -1) {
                this.feedback = 'Nie je vybraná aktivita.'
                return 
            }
            
            var start_hod = 0
            var start_min = 0
            var kon_hod = 0
            var kon_min = 0
            var sh = 0
            var sm = 0 
            var cas = 0
            var polhodin = 0
            var sucet = 0
            let _od = Math.max(0,this.rozvrh_od_dna-1)
            let _do = Math.min(this.rozvrh_do_dna, this.mycontent.days.length)

            for (let i = _od ; i < _do; i++ )  {

                if (this.mycontent.days[i].day_type != "práca") continue
                if (this.mycontent.days[i].activities.length == 0) continue

                // prva aktivita
                start_hod = this.mycontent.days[i].start_hours
                start_min = this.mycontent.days[i].start_minutes
                sucet = this.sucetLength(i) * 60 // aktualna dlzka minut
                cas = this.mycontent.days[i].activities[0].start_hours * 60 + this.mycontent.days[i].activities[0].start_minutes - start_hod*60 - start_min
                if (sucet + cas > 480 ) cas = 480 - sucet
                
                if (cas >= 30) {                    
                    polhodin = Math.floor(cas / 30)
                    cas = polhodin * 30
                    cas = start_hod *60 + start_min + cas
                    kon_hod = Math.floor(cas /60) 
                    kon_min = cas - kon_hod * 60

                    sucet = sucet + kon_hod * 60 - start_hod * 60 + kon_min - start_min

                    // eslint-disable-next-line no-console
                    // console.log(i + "" + cas + " " + start_hod + ":" + start_min + ">> " + kon_hod + ":" + kon_min);
                    
                    this.zapisAktivity(i,0,start_hod,start_min,kon_hod,kon_min,this.selectedProject, project_id, this.selectedActivity, proj_activity_id, "generated", 0)
                }
                kon_hod = this.mycontent.days[i].end_hours
                kon_min = this.mycontent.days[i].end_minutes
                sh = this.mycontent.days[i].activities[this.mycontent.days[i].activities.length-1].end_hours
                sm = this.mycontent.days[i].activities[this.mycontent.days[i].activities.length-1].end_minutes
                cas = kon_hod*60 + kon_min - sh *60 - sm
                if (sucet + cas > 480 ) cas = 480 - sucet

                if (cas >= 30) {
                    polhodin = Math.floor(cas / 30)
                    cas = polhodin * 30
                    cas = sh *60 + sm + cas
                    kon_hod = Math.floor(cas /60) 
                    kon_min = cas - kon_hod * 60

                    this.zapisAktivity(i,0,sh,sm,kon_hod,kon_min,this.selectedProject, project_id, this.selectedActivity, proj_activity_id, "generated", 0)
                }
                

            }
    
            this.showGenerator = false
        }
    }
  }
  
</script>

<style>

</style>