<template>
  <div>
    <p></p>
    <v-layout row wrap class="change-password">
     
      <v-flex xs10 md7 xl6>
        <v-card class="app-page-card shadow-none" v-if="step == 1">
          <v-card-title>
            {{getTitle()}}
          </v-card-title>
          <v-card-text>
              <v-text-field
                label = "Type your email"
                v-model="email"
                >
              </v-text-field>
            <p align="center" v-if="feedback" style="color: red">{{feedback}}</p>
            <v-btn class="btn-style btn-primary" @click="sendResetMail()">Send email</v-btn>
          </v-card-text>
        </v-card>
        <v-card class="app-page-card shadow-none" v-if="step == 2">
        <v-card-title>
          Password reset
        </v-card-title>
        <v-card-text>
          <p align="center" v-if="feedback" style="color: blue">{{feedback}}</p>
          <p></p>
          <v-layout row wrap justify-center>
          <v-flex xs1><v-text-field v-model = part1 solo></v-text-field></v-flex>
          <v-flex xs1>
            <p></p>
            <v-layout justify-center>
              <h2>-</h2>
            </v-layout>
          </v-flex>
          <v-flex xs1><v-text-field v-model = part2 solo></v-text-field></v-flex>
          <v-flex xs1>
            <p></p>
            <v-layout justify-center>
              <h2>-</h2>
            </v-layout>
          </v-flex>
          <v-flex xs1><v-text-field v-model = part3 solo></v-text-field></v-flex>
          <v-flex xs1></v-flex>
          <v-flex xs1>
            <v-btn class="btn-style btn-primary" @click="confirmCode()">Confirm</v-btn>            
          </v-flex>          
          </v-layout>
        </v-card-text>
      </v-card>
      <v-card class="app-page-card shadow-none" v-if="step == 3">
        <v-card-title>
          New pasword
        </v-card-title>
        <v-card-text>
            <v-layout row wrap>
              <v-flex xs6>
                <v-text-field label="Password:" v-model="passw"
                  style="margin:10px"
                  :append-icon="show1 ? 'visibility' : 'visibility_off'"
                  :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field label="Confirm password:" v-model="confpassw"
                  style="margin:10px"
                  :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'"
                  ></v-text-field>
              </v-flex>
            </v-layout>
        </v-card-text>
        <v-card-text>
          <p align="center" v-if="feedback" style="color: red">{{feedback}}</p>
          <p></p>
          <v-layout row wrap justify-center>
              <v-flex xs1>
                <v-btn class="btn-style btn-primary" @click="changePassword()">Change password</v-btn>            
              </v-flex> 
            </v-layout>
        </v-card-text>
      </v-card>
      <v-card class="app-page-card shadow-none" v-if="step == 4">
        <v-card-title>
          Process finished
        </v-card-title>
        <v-card-text>
          <p align="center" style="color: blue">Process finished.</p>
          <v-layout row wrap justify-center>
          <v-flex xs1>
            <v-btn class="btn-style btn-primary" @click="goToDashboard()">{{getNextText()}}</v-btn>            
          </v-flex>          
          </v-layout>
        </v-card-text>
      </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from '../../axios-auth.js'
import configData from '../../config.js'


  export default {
    data: function () {
      return {
        email: '',
        step : 1,
        feedback : null,
        command : null,
        part1: '',
        part2: '',
        part3: '',
        token: '',
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 5 || 'Min 5 characters'
        },
        show1: false,
        passw: '',
        confpassw: ''

      }
    },
    mounted() {
      this.$store.commit('setFeedback', null)
      this.email = this.eml
    },
    props:['source','eml'],
    methods: {
      sendResetMail() {
        this.feedback = ''
        if (!this.validEmail(this.email)) {
            this.feedback = "Invalid email address."
            return
        }
        if (this.source == 2) {
          if (this.email != this.eml) {
            this.feedback = "This is not email of logged user."
            return
          }
        }

        axios.post('/pwd-change-mail', {mail: this.email, client_secret: configData.client_secret}, this.$store.getters.getAxiosConfig)
          .then((res) => {
            if (res.data.message == 'OK') {
              this.step = 2
              this.feedback = 'Please check your mailbox and confirm password change (check the spam folder too).'
            } else {
              this.feedback = res.data.description
            }
          })
          .catch(error => {
            this.feedback = error.message
          })

      },
      validEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(email);
        },
      confirmCode() {
        this.feedback = ''
        const data = {email: this.email, client_secret: configData.client_secret, code: this.part1 +'-'+ this.part2 + '-' +this.part3}
        axios.post('/pwd-confirm-code', data, this.$store.getters.getAxiosConfig)
          .then((res) => {
            if (res.data.message == 'OK') {
              this.token = res.data.token
              this.step = 3
            } else {
              this.feedback = res.data.description
            }
          })
          .catch(error => {
            this.feedback = error.message
          })
      },
      changePassword() {
        this.feedback = ''
        if (this.passw != this.confpassw) {
          this.feedback = "Password and confirmation must be match."
          return
        }  
        axios.post('/pwd-change-pwd', {email: this.email, 
                                client_secret: configData.client_secret, 
                                code: this.token,
                                passwd: this.passw}, 
                                this.$store.getters.getAxiosConfig)
          .then((res) => {
            if (res.data.message == 'OK') {
              this.token = res.data.token
              this.step = 4
            } else {
              this.feedback = res.data.description
            }
          })
          .catch(error => {
            this.feedback = error.message
          })


      },
      goToDashboard() {
        if (this.source == 1) {
          const data = {
            email: this.email,
            password: this.passw,
            grant_type: "password",
            client_id: 2,
            client_secret: configData.client_secret,
            username: this.email,
          }
          // eslint-disable-next-line no-console           
          // console.log(data)
          this.$store.dispatch('login', data)
          this.$store.dispatch('tryAutologin')
          //this.$router.push({name:'dashboard'})   
        }
        if (this.source == 2) {
          this.$router.push({name:'dashboard'})     
        }
        
      },
      getNextText() {
        if (this.source == 1) {
          return "Go to system!"
        }
        if (this.source == 2) {
          return "Go to dashboard!"
        }

      },
      getTitle() {
        if (this.source == 1) return "Reset password"
        if (this.source == 2) return "Change password"
      }
    }
  }

</script>

<style scoped>
.change-password .flex {
  margin: 0 auto;
}
</style>