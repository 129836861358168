import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from '../components/welcome/welcome.vue'
import Dashboard from '../components/dashboard/dashboard.vue'
import Register from '../components/auth/register.vue'
import Login from '../components/auth/login.vue'
import ReportUser from '../components/elements/ReportUser.vue'
import RozvrhHodin from '../components/elements/RozvrhHodin.vue'
import ProjectUsers from '../components/elements/ProjectUsers.vue'
import Report from '../components/elements/Report.vue'
import Tabulka from '../components/elements/Tabulka.vue'
import Infolisty from '../components/elements/infolisty/infolisty.vue'
import Infolist from '../components/elements/infolisty/infolist.vue'
import AisExport from '../components/elements/infolisty/ais_export.vue'
import Kompetentnosti from '../components/elements/infolisty/kompetentnosti.vue'
import MyEpc from '../components/elements/infolisty/myEpc.vue'
import TeacherInfo from '../components/elements/infolisty/teacher_info.vue'
import ZoznamProjektov from '../components/elements/ZoznamProjektov.vue'

Vue.use(VueRouter)

const routes = [
    { path: '/404', 
      component: Login 
    },  
    { path: '/', 
      name: 'welcome',
      component: Welcome,
      meta: {
        requiresAuth : false,
        forUser : false,
        forAdmin: false
      }
    }, { 
      path: '/register', 
      name: 'register',
      component: Register,
      meta: {
        requiresAuth : false,
        forUser : false,
        forAdmin: false
      },
    }, { 
      path: '/login', 
      name: 'login',
      component: Login,
      meta: {
        requiresAuth : false,
        forUser : false,
        forAdmin: false
      }
    }, { 
      path: '/dashboard', 
      name: 'dashboard',
      component: Dashboard,
      meta: {
        requiresAuth : true,
        forUser : true,
        forAdmin: true,
      }
    }, { 
      path: '/report_user/:id', 
      name: 'report_user',
      component: ReportUser,
      meta: {
        requiresAuth : false,
        forUser : true,
        forAdmin: false
      }
    } ,  { 
      path: '/rozvrh_hodin', 
      name: 'rozvrh_hodin',
      component: RozvrhHodin,
      meta: {
        requiresAuth : false,
        forUser : true,
        forAdmin: false
      }
    } , { 
      path: '/project_users/:id', 
      name: 'project_users',
      component: ProjectUsers,
      meta: {
        requiresAuth : false,
        forUser : false,
        forAdmin: true
      }
    }, { 
      path: '/infolisty', 
      name: 'infolisty',
      component: Infolisty,
      meta: {
        requiresAuth : true,
        forUser : true,
        forAdmin: true
      }
    }, { 
      path: '/kompetentnosti', 
      name: 'kompetentnosti',
      component: Kompetentnosti,
      meta: {
        requiresAuth : true,
        forUser : true,
        forAdmin: true
      }
    }, { 
      path: '/infolist/:id', 
      name: 'infolist',
      component: Infolist,
      meta: {
        requiresAuth : true,
        forUser : true,
        forAdmin: true
      }
    }, { 
      path: '/ais_export/:id', 
      name: 'ais_export',
      component: AisExport,
      meta: {
        requiresAuth : true,
        forUser : true,
        forAdmin: true
      }
    }, { 
      path: '/my_epc', 
      name: 'my_epc',
      component: MyEpc,
      meta: {
        requiresAuth : true,
        forUser : true,
        forAdmin: true
      }
    }, { 
      path: '/teacher_info/:id', 
      name: 'teacher_info',
      component: TeacherInfo,
      meta: {
        requiresAuth : true,
        forUser : true,
        forAdmin: true
      }
    }, { 
      path: '/report/:id/:user_id', 
      name: 'report',
      component: Report,
      meta: {
        requiresAuth : true,
        forUser : false,
        forAdmin: true
      }
    }, { 
      path: '/show_table/:id/:user_id/:project_id', 
      name: 'show_table',
      component: Tabulka,
      meta: {
        requiresAuth : true,
        forUser : false,
        forAdmin: true
      }
    }, { 
      path: '/project_list/:id', 
      name: 'project_list',
      component: ZoznamProjektov,
      meta: {
        requiresAuth : true,
        forUser : true,
        forAdmin: false
      }
    }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})



export default router
