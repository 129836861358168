<template>
    <v-row>
        <v-col cols=12>
            <v-card>
                <v-card-title>
                    <p v-if="test<0"> {{test}}</p> 
                    <v-row>
                        <v-col cols=4></v-col>
                        <v-col cols=2>
                            <v-btn @click="changeView(1)">Po zamestnancoch</v-btn>
                        </v-col>
                        <v-col cols=1>
                            <v-btn @click="changeView(2)">Po mesiacoch</v-btn>
                        </v-col>
                    </v-row>                    
                </v-card-title>
            </v-card>
            <div v-if="myContent && show_type == 1">
                <v-card v-for="(user,index) in myContent.list" :key="index" >
                    <v-card-title style="cursor: pointer" @click="unpacking[index]++; test++;zapisUnpack(index);">
                        <v-row>
                            <v-col cols=1></v-col>
                            <v-col cols=3>
                                {{user.priezvisko}} {{user.meno}}   
                            </v-col>
                            <v-col cols=1>
                                <v-layout row justify-center>
                                {{user.hodiny}}
                                </v-layout>
                            </v-col>
                            <v-col cols=4>
                                <v-btn @click="generujVykazy(user.user_id,user.priezvisko,user.vykazy)">Generuj všetky vykazy zamestnanca</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text v-if="unpacking[index] % 2 != 0">         
                        <v-row v-for="(vykaz, index2) in user.vykazy" :key="index2">
                            <v-col cols=2></v-col>
                            <v-col cols=2>
                                {{vykaz.rok}}-{{dm2(vykaz.mesiac)}}
                                --- <strong>({{vykaz.hodiny}}) </strong>
                            </v-col>
                            <v-col cols=2>
                               <span :style="getBgColor(vykaz.stav)"> {{vykaz.stav}} </span>
<!--                                 <v-btn x-small @click="nastavitStav(index, index2, user.user_id, vykaz.mesiac, vykaz.rok,'otvorený')" style="margin-left:15px;margin-bottom:5px;">Na otvorený</v-btn>
                                <v-btn x-small @click="nastavitStav(index, index2, user.user_id, vykaz.mesiac, vykaz.rok,'schválený')" style="margin-left:5px;margin-bottom:5px;">Na schválený</v-btn>
                                <v-btn x-small @click="nastavitStav(index, index2, user.user_id, vykaz.mesiac, vykaz.rok,'uzavretý')" style="margin-left:5px;margin-bottom:5px;">Zrušiť schv.</v-btn>
 -->                        </v-col>

                            <v-col cols=4>
                                <v-btn @click ="ukazReport(user.user_id, vykaz.mesiac, vykaz.rok)" small  style="margin-left:10px;margin-bottom:5px;">Zobraziť výkaz</v-btn>
                                <v-btn @click ="ukazTabulku(user.user_id, vykaz.mesiac, vykaz.rok)" small style="margin-left:10px;margin-bottom:5px;">Zobraziť tabuľku</v-btn>
                            </v-col>

                            <v-col cols=3 v-if="vykaz.komentar.length>0">
                                Komentár: {{vykaz.komentar}}
                            </v-col>                        
                        </v-row>                                        
                    </v-card-text>
                </v-card>
            </div>
             <div v-if="myContent && show_type == 2">
                <v-card v-for="(month,index) in myContent.list" :key="index" >
                    <v-card-title style="cursor: pointer" @click="unpacking[index]++; test++">
                        <v-row>
                            <v-col cols=1></v-col>
                            <v-col cols=3>
                                {{month.rok}}-{{dm2(month.mesiac)}}   
                            </v-col>
                            <v-col cols=1>
                                <v-layout justify-end>
                                {{month.hodiny}}
                                </v-layout>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text v-if="unpacking[index] % 2 != 0">         
                        <v-row v-for="(vykaz, index2) in month.vykazy" :key="index2">
                            <v-col cols=2></v-col>
                            <v-col cols=2>
                                {{vykaz.priezvisko}} {{dm2(vykaz.meno)}}  
                                --- <strong>({{vykaz.hodiny}}) </strong> 
                            </v-col>
                            <v-col cols=2>
                               <span :style="getBgColor(vykaz.stav)"> {{vykaz.stav}} </span>
                               <!--  <v-btn x-small @click="nastavitStav(index, index2, user.user_id, vykaz.mesiac, vykaz.rok,'otvorený')" style="margin-left:15px;margin-bottom:5px;">Na otvorený</v-btn>
                                <v-btn x-small @click="nastavitStav(index, index2, user.user_id, vykaz.mesiac, vykaz.rok,'schválený')" style="margin-left:5px;margin-bottom:5px;">Na schválený</v-btn>
                                <v-btn x-small @click="nastavitStav(index, index2, user.user_id, vykaz.mesiac, vykaz.rok,'uzavretý')" style="margin-left:5px;margin-bottom:5px;">Zrušiť schv.</v-btn>
                       -->      </v-col>
                            <v-col cols=4>
                                <v-btn @click ="ukazReport(vykaz.user_id, month.mesiac, month.rok)" small  style="margin-left:10px;margin-bottom:5px;">Zobraziť výkaz</v-btn>
                                <v-btn @click ="ukazTabulku(vykaz.user_id, month.mesiac, month.rok)" small style="margin-left:10px;margin-bottom:5px;">Zobraziť tabuľku</v-btn>
                            </v-col>
<!--                                 <v-btn small color="primary" style="margin-left:10px;margin-bottom:5px;">Zobraziť</v-btn>
                                <v-btn small style="margin-left:10px;margin-bottom:5px;">Zobr. v tab.</v-btn>
                                <v-btn small color="primary" style="margin-left:10px;margin-bottom:5px;">Download</v-btn>
                            </v-col>
 -->                            <v-col cols=3 offset-1 v-if="vykaz.komentar.length>0">
                                Komentár: {{vykaz.komentar}}
                            </v-col>
                        </v-row>                                        
                    </v-card-text>

                </v-card>
            </div>
        </v-col>   
    </v-row>
</template>

<script>
import axios from '../../axios-auth.js'

export default {
    mounted () {
        this.initData()
    },
  data() {
      return {
          inicialised: false,
          show_type: 0, 
          myContent: null,
          project_id: 0,
          test: 0,
          unpacking: [],
          feedback: null
      }
  },
  methods: {
    initData() {        
        this.project_id = this.$route.params.id
        let ll = localStorage.getItem('view')
        if (ll) this.changeView(ll) 
        else this.changeView(1)
    },
    changeView(typ) {
      this.$store.commit('setFeedback',null)  
      this.feedback = null
      localStorage.setItem('view',typ)
      if (typ == this.show_type) return

      this.show_type = typ
      // citanie z db
      const data = {
          project_id : this.project_id,
          type: typ
      }

      axios.post('/get-projectusers-list', data, this.$store.getters.getAxiosConfig )
      .then(res => {
      // set data to var
         this.myContent = res.data
         this.unpacking = []
         for(let i = 0; i<this.myContent.list.length; i++) {
             this.unpacking.push(0)
         }

         let g = localStorage.getItem('unpack')
         if (g) this.unpacking[g]++

         
      })
      .catch(error => {
          this.$store.commit('setFeedback', error)
          this.feedback = error
      })

    },    
    dm2(number) {
        if (number<10) return '0'+number
        else return number
    },
    nastavitStav(index, index2, user_id, mesiac, rok, novy_stav) {
        this.$store.commit('setFeedback',null)
        this.feedback = null

        let povodny_stav = this.myContent.list[index].vykazy[index2].stav
        if (novy_stav == 'schválený' && povodny_stav !='uzavretý') {
            this.$store.commit('setFeedback', 'Na zmenu stavu na "schválený" musí byť výkaz v stave "uzavretý".')
            this.feedback = 'Na zmenu stavu na "schválený" musí byť výkaz v stave "uzavretý".'
            return
        }
        if (novy_stav == 'uzavretý' && povodny_stav !='schválený') {
            this.$store.commit('setFeedback', 'Na zmenu stavu na "uzavretý" musí byť výkaz v stave "schválený".')
            this.feedback = 'Na zmenu stavu na "uzavretý" musí byť výkaz v stave "schválený".'
            return
        }
        if (novy_stav == 'otvorený' && povodny_stav !='uzavretý') {
            this.$store.commit('setFeedback', 'Na zmenu stavu na "otvorený" musí byť výkaz v stave "uzavretý".')
            this.feedback = 'Na zmenu stavu na "otvorený" musí byť výkaz v stave "uzavretý".'
            return
        }
        
        const data = {
            user_id: user_id,
            rok: rok,
            mesiac: mesiac,
            stav: novy_stav
        }

         // eslint-disable-next-line no-console
         // console.log(data)              


        axios.post('/zmena-stavu-vykazu', data, this.$store.getters.getAxiosConfig )
        .then((res) => {
        // set data to var
            if (res.data.message == 'OK')
              this.myContent.list[index].vykazy[index2].stav = novy_stav
            else {
                this.$store.commit('setFeedback', res.data.status)
                this.feedback = res.data.status
            }
        })
        .catch(error => {
            this.$store.commit('setFeedback', error)
            this.feedback = error
        })
      
    },
    getBgColor(stav) {
        if (stav == 'otvorený') return "background-color:#FF8A80;"
        if (stav == 'uzavretý') return "background-color:yellow;"
        if (stav == 'schválený') return "background-color:#76FF03;"
        return "background-color:#40C4FF;"
    },
    ukazReport(user_id, mesiac, rok) {
        this.$router.push({name:'report', params: {id: rok*100+mesiac, user_id: user_id }})     
    },
    ukazTabulku(user_id, mesiac, rok) {
        this.$router.push({name:'show_table', params: {id: rok*100+mesiac, user_id: user_id, project_id: this.project_id }})     
    },
    zapisUnpack(index) {
        localStorage.setItem('unpack', index)   
    },
    generujVykazy(user_id, priezvisko, zoznam) {
        for(let i = 0; i<zoznam.length; i++) {
            this.download(user_id, priezvisko, zoznam[i].rok, zoznam[i].mesiac)
        }
    },
    download(_user_id, _priezvisko, _year, _month) {
            const data = {
                user_id: _user_id,
                rok: _year,
                project_id: this.$route.params.id,
                mesiac: _month // this.month
            }
            var ac = this.$store.getters.getAxiosConfig 
            ac.responseType = 'arraybuffer'

            axios.post('/prepare-download-2021', data, ac)
                    .then((res) => {
                    // set data to var

                        const url = URL.createObjectURL(new Blob([res.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        }))
                        const link = document.createElement('a')
                        link.href = url
                        var mm = _month
                        if (_month < 10) mm = '0' + _month   //this.month                          
                        link.setAttribute('download', '' + _year + '-' + mm + '-' + _priezvisko + '.xlsx')
                        document.body.appendChild(link)
                        link.click()              
                    })
                    .catch(error => {
                        this.$store.commit('setFeedback', error)
                    }) 
        },

  }
}
</script>

<style>

</style>