<template>
<v-container>
    <v-layout row wrap>
      <v-flex xs12 >
        <v-card>
            <v-card-title>
                {{myContent.teacher}}
            </v-card-title>
            <v-card-text>
                <h3 style="color:blue">Predmety</h3>
            </v-card-text>
            <v-card-text>
                <v-card style="height:30px" v-for="(element, index) in myContent.predmety" :key="index" :color="element.semester % 2 == 1 ? '#EEEEEE' : '#CCCCCC'">
                    <v-layout row wrap >
                        <v-flex xs2 >
                            <v-layout row wrap>
                                <v-flex xs4>
                                    <v-layout justify-center>{{element.stupen}}</v-layout>
                                </v-flex>
                                <v-flex xs3>
                                    <v-btn dense x-small color="primary" style="margin-right:10px" @click="toAis(element.id)">do AIS</v-btn>
                                </v-flex>
                                <v-flex xs2>
                                    <v-layout justify-center>{{element.rocnik}}</v-layout>
                                </v-flex>
                                <v-flex xs3>{{element.semester}}</v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs4>
                            <b v-if="element.typ_predmetu == 'PP'" @click="showInfolist(element.id)" style="cursor:pointer">{{element.nazov}}</b>    
                            <span v-if="element.typ_predmetu != 'PP'" @click="showInfolist(element.id)" style="cursor:pointer">{{element.nazov}}</span>    
                        </v-flex>
                        <v-flex xs1> {{element.prednasky}} / {{element.cvicenia}} - {{element.kredity}} ({{element.ukoncenie}})</v-flex>    
                        <v-flex xs1>
                            <v-icon small v-if="element.sylaby>12" style="color:green" :title="'sylaby ('+ element.sylaby+')'">check</v-icon>
                            <v-icon small v-if="element.sylaby>7 && element.sylaby<13" style="color:orange" :title="'sylaby ('+ element.sylaby+')'">check</v-icon>
                            <v-icon small v-if="element.sylaby<8" style="color:red" :title="'sylaby ('+ element.sylaby+')'">adjust</v-icon>
                            
                            <v-icon small v-if="element.vysl_vzdel>0" style="color:green" title="výsledky vzdelávania">check</v-icon>
                            <v-icon small v-else style="color:red" title="výsledky vzdelávania">adjust</v-icon>
                            <v-icon small v-if="element.podm_abs>0" style="color:green" title="Podmienky absolvovania">check</v-icon>
                            <v-icon small v-else style="color:red" title="Podmienky absolvovania">adjust</v-icon>
                            <v-icon small v-if="element.literatura>0" style="color:green" title="literatúra">check</v-icon>
                            <v-icon small v-else style="color:red" title="literatúra">adjust</v-icon>
                            <span v-if="element.kompet > 10" style="color:green;margin-left:5px" :title="'kompetentnosti ('+ element.kompet+')'">{{element.kompet}}</span>
                            <span v-if="element.kompet > 4 && element.kompet <11 " style="color:orange;margin-left:5px" :title="'kompetentnosti ('+ element.kompet+')'">{{element.kompet}}</span>
                            <span v-if="element.kompet < 5" style="color:red;margin-left:5px" :title="'kompetentnosti ('+ element.kompet+')'">{{element.kompet}}</span>
                        </v-flex>
                        <v-flex xs4><span v-for="(ucitel,index2) in element.ucitelia" :key="index2">
                            <b v-if="index2 == 0" @click="showTeacherInfo(ucitel.id)" style="cursor:pointer">{{ucitel.priezvisko}}</b>
                            <span v-if="index2 != 0" >, </span>
                            <span v-if="index2 != 0" @click="showTeacherInfo(ucitel.id)" style="cursor:pointer">{{ucitel.priezvisko}}</span>
                            </span></v-flex>
                    </v-layout> 
                </v-card>
            </v-card-text>
            <v-card-text>
                <h3 style="color:blue">Publikácie</h3>
            </v-card-text>
            <v-card-text>
                <h4>Posledných 5 rokov:</h4>                
                <v-layout row wrap v-for="(pub, index) in myContent.epc.five_years" :key="index">
                    <v-flex xs1></v-flex>
                    <v-flex xs10>
                        <p> {{index+1}}. {{pub}} </p>
                    </v-flex>
                    <v-flex xs1></v-flex>
                </v-layout> 
            </v-card-text>
            <v-card-text>
                <h4>Celkom:</h4>                
                <v-layout row wrap v-for="(pub, index) in myContent.epc.all_life" :key="index">
                    <v-flex xs1></v-flex>
                    <v-flex xs10>
                        <p> {{index+1}}. {{pub}} </p>
                    </v-flex>
                    <v-flex xs1></v-flex>
                </v-layout> 
            </v-card-text>
        </v-card>
      </v-flex>
   </v-layout>
   </v-container>
</template>

<script>
import axios from '../../../axios-auth.js'
import {mapGetters} from 'vuex'

  export default {
    data() {
      return {
            // my content
            myContent: {
                epc: { five_years: ["","","","",""], all_life: ["","","","",""]}, 
                teacher: '',
                predmety: []
            },
        }
    },      
    computed: 
        mapGetters([
                        'isAdmin'
                    ]),
    mounted () {
      this.initData()
    },
    methods: {
        initData() {
            this.getInfo(this.$route.params.id)
        },
        getInfo(_id) {
            const data = {
                teacher_id : _id
            }
            axios.post('/inf-get-teacher-info', data, this.$store.getters.getAxiosConfig )
            .then(res => {
                // eslint-disable-next-line no-console
                // console.log(res.data)
                if (res.data.epc.length>1) {
                    this.myContent.epc = JSON.parse(res.data.epc) 
                } else {
                    this.myContent.epc = {five_years: ["","","","",""], all_life: ["","","","",""]}
                }

                this.myContent.teacher = res.data.teacher 
                this.myContent.predmety = res.data.predmety                                                 
            })
            .catch(error => {
                this.$store.commit('setFeedback',  "User error: " + error)
            })
        },   
        showTeacherInfo(_id) {
            this.getInfo(_id)    
        }, 
        showInfolist(_id) {
            this.$router.push({name:'infolist', params: {id: _id}})   
        },
        toAis(_id) {
            this.$router.push({name:'ais_export', params: {id: _id}})   
        },
  }
}
  
</script>

<style>

</style>