<template>
  <v-layout row wrap>
    <v-flex xs6 offset-3>
      <v-card outlined flat>
        <v-card-title>
          Registrácia
        </v-card-title>
        <v-card-text>
          <v-flex xs10 offset-1>
            <p v-if="feedback" style="color: red">{{feedback}}</p>
           <v-text-field label="Tituly pred:" v-model="ttl_pred"></v-text-field>
           <v-text-field label="Meno:" v-model="meno"></v-text-field>
           <v-text-field label="Priezvisko:" v-model="priezvisko"></v-text-field>
           <v-text-field label="Tituly za:" v-model="ttl_za"></v-text-field>
           <v-text-field label="Osobné číslo:" v-model="osobne_cislo"></v-text-field>
           <v-text-field
            label="E-mail:"
            v-model="email"
            suffix="@ukf.sk"
            ></v-text-field>

           <v-text-field label="Heslo:" v-model="heslo"
            :append-icon="show1 ? 'visibility' : 'visibility_off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            ></v-text-field>
          </v-flex>
          <v-flex xs2 offset-10>
            <v-btn @click="odosliData()">Registruj</v-btn>
          </v-flex>
        </v-card-text>


      </v-card>
    </v-flex>

  </v-layout>
</template>

<script>
  export default {
    data () {
      return {
        feedback: '', 
        ttl_pred : '',
        meno: '',
        priezvisko : '',
        ttl_za: '',
        osobne_cislo: '',
        email: '',
        heslo: '',
        show1 : false,
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 5 || 'Min 5 characters',
          emailMatch: () => ('The email and password you entered don\'t match'),
        },
      }
    },
    methods: {
      odosliData () {
        this.feedback = null
        if (this.meno.length < 2) { 
          this.feedback = "Meno je príliš krátke." 
          return 
        }
        if (this.priezvisko.length < 2) {
          this.feedback = "Priezvisko je príliš krátke."
          return
        }

        try {
          let os = Number(this.osobne_cislo)
          
          if (os > 0) {
            this.osobne_cislo = os
          } else {
            this.feedback = 'Osobné číslo nemá korektnú hodnotu. (' + os +')'
            return
          }
        } catch(err) {
            this.feedback = 'Osobné číslo nemá číselnú hodnotu. (' + this.osobne_cislo +')'
            return
        }
        for(let i = 0; i<this.email.length; i++) {
          let ch = this.email.substring(i,i+1)
          if ((ch >= '0' && ch <= '9') || (ch >= 'a' && ch <= 'z') || (ch >= 'A' && ch <= 'Z')) continue
          this.feedback = "Nesprávny formát a-mailovej adresy (zadajte len názov schránky bez domény)."
          return
        }

        if (this.email.length < 2) {
          this.feedback = "E-mail je príliš krátky."
          return
        }          

        const formData = {
          ttl_pred : this.ttl_pred,
          meno: this.meno,
          priezvisko : this.priezvisko,
          ttl_za: this.ttl_za,
          osobne_cislo: this.osobne_cislo,
          email: this.email + '@ukf.sk',
          heslo: this.heslo
        }
        this.$store.dispatch('register', formData)
      }
    }
  }
</script>

<style >

</style>
