<template>
    <v-layout row wrap>
        <v-flex xs10 offset-1>
            <v-card v-if="projects">
                <v-card-title>
                    Zoznam projektov:
                </v-card-title>
                <v-card-text>
                    <v-card v-for="(projekt, index) in projects" :key="index">
                        <v-card-title v-if="projekt.id>4">
                            <v-layout row wrap>
                                <v-flex xs2 offset-1>
                                    {{projekt.ITMS}}
                                </v-flex>
                                <v-flex xs1>
                                    {{projekt.nazov}}
                                </v-flex>
                                <v-flex xs6>
                                    {{projekt.cely_nazov}}
                                </v-flex>
                            </v-layout>
                        </v-card-title>
                        <v-card-text v-if="projekt.id>4">
                            <v-layout row wrap v-for="(aktivita, index2) in projekt.aktivity" :key="index2">
                                <v-flex xs6 offset-1>
                                    {{aktivita.nazov}}
                                </v-flex>
                                <v-flex xs2>
                                    {{aktivita.pocet_hodin}}
                                </v-flex>
                                <v-flex xs5>
                                    
                                </v-flex>

                            </v-layout>
                        </v-card-text> 
                    </v-card>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from '../../axios-auth.js'

export default {
    mounted () {
        this.initData()
    },
    data() {
        return {
            inicialised: false,
            month: 0,
            year: 0, 
            myContent: null,
            projects : null,
            editSum: true
        }
    },
    methods: {
        initData() {
            this.readDatafromDB()
        },
        readDatafromDB() {
            axios.get('/get-projekty-aktivity/' + this.$route.params.id, this.$store.getters.getAxiosConfig )
            .then(res => {
            // set data to var
                this.projects = res.data.list;

                // eslint-disable-next-line no-console
                // console.log(this.projects)   
            })
            .catch(error => {
                this.$store.commit('setFeedback', error)
            })            
        }

    }

}
</script>