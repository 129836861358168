<template>
  <v-app>
    <app-header />
    <v-main>
    <v-alert v-if="getFeedback" color="red">{{getFeedback}}</v-alert>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  import Header from './components/header/header.vue'
  import {mapGetters} from 'vuex'

  export default {
    name: 'App',
    computed: 
        mapGetters([
                    'getFeedback',
                    'isLogged'
                    ])
    ,  
    components: {
      'app-header': Header
    },
    created() {
      this.$store.dispatch('tryAutologin')
    }
  }
</script>

<style>
 a{
  text-decoration: none;
}
</style>