<template>
    <v-row>
      <v-col cols=12 v-if="mycontent">
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols=2>
                        <v-btn color="primary" style="margin-left: 10px" @click="navratNaProjekt()">Návrat na projekt...</v-btn>    
                    </v-col>
                    <v-col cols=1></v-col>
                    <v-col cols=1>
                        {{month}} / {{year}}
                    </v-col>
                    <v-col cols=2>
                        {{meno}} {{priezvisko}}
                    </v-col>
                    <v-col cols=2>
                        stav výkazu: 
                        <span :style="getBgColor(stav_vykazu)">{{stav_vykazu}}</span>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-col cols=1></v-col>
                <v-col cols=11>
                    <v-btn v-if="stav_vykazu =='uzavretý'" color="primary" style="margin-left: 10px" @click="zmenaStavu('otvorený')">Otvoriť výkaz </v-btn>    
<!--                     <v-btn v-if="stav_vykazu =='uzavretý'" color="primary" style="margin-left: 10px" @click="zmenaStavu('schválený')">Schváliť výkaz </v-btn>    
                    <v-btn v-if="stav_vykazu =='schválený'" color="primary" style="margin-left: 10px" @click="zmenaStavu('uzavretý')">Zrušiť schválenie</v-btn>    
                   <v-btn v-if="stav_vykazu =='otvorený'" color="primary" style="margin-left: 10px" @click="zmenaStavu('uzavretý')">Uzavrieť výkaz</v-btn>    
 -->                 </v-col>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols=1></v-col>
                    <v-col cols=11>
                        Komentár:
                    </v-col>
                    <v-col cols=1></v-col>
                    <v-col cols=1>
                        <v-icon @click="showComment = true">edit</v-icon>
                    </v-col>
                    <v-col cols=9 v-if="!showComment">
                        {{myComment}}
                    </v-col>
                    <v-col cols=9 v-if="showComment">
                        <v-textarea 
                            label = "Komentár pre používateľa:"
                            v-model="myComment"
                            name = "pa"
                            filled
                            >
                            </v-textarea>
                        <v-btn small @click="ulozKomentar()">Uložiť</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <v-card v-if="year>0" outlined raised color = "lightgray" style="margin: 10px">
            <v-card-title>
                <v-row>
                    <v-col cols=1></v-col>
                    <v-col cols=8>
                        <v-card>
                            <v-card-title>
                                Zoznam činností:
                            </v-card-title>
                            <v-card-text>
                                <div v-for="(project, index) in projects" :key="index">
                                    <div v-for="(aktivita, index2) in project.aktivity" :key="index2">
                                        <v-layout row wrap v-if="getAktivitaPocetHodin(index,index2) > 0 && project.id != 1">        
                                            <v-col cols=2>        
                                                {{project.nazov}}
                                            </v-col>
                                            <v-col cols=4>        
                                                {{aktivita.nazov}}
                                            </v-col>
                                            <v-col cols=2>        
                                                {{getAktivitaPocetHodin(index,index2)}}
                                            </v-col>
                                        </v-layout>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols=1></v-col>
                    <v-col cols=1>
                        <v-row>
                            <v-col cols=12>                                
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols=1></v-col>
                    <v-col cols=8>
                        <v-card>
                            <v-card-title>
                                Popis mesačnej činnosti:
                            </v-card-title>
                            <v-card-text>  
                                    {{popis_aktivity}}
                            </v-card-text>
                            <v-card-actions>
                                Poznámky:
                                {{myPoznamky}}
                            </v-card-actions>
                        </v-card>
                     </v-col> 
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-row>
                <v-col cols=12 v-for="(day,index) in mycontent.days" :key="index">
                    <v-card :color = "getColor(day.day_of_week)">
                        <v-card-text> 
                            <v-row>
                                <v-col cols=2>
                                    <v-row>
                                    <v-col cols=4 style="align-items: center; justify-content: center"  align="center">
                                        <h2>{{day.day}}</h2>
                                        <p>{{dni[day.day_of_week]}}</p>                    
                                    </v-col>
                                    <v-col cols=8>  
                                        <v-row>
                                            <v-col cols=12>  
                                                {{dm2(day.start_hours)}}:{{dm2(day.start_minutes)}} - {{dm2(day.end_hours)}}:{{dm2(day.end_minutes)}} 
                                            </v-col>
                                            <v-col cols=12 >  
                                                    {{day.day_type}}
                                            </v-col>
                                        </v-row> 
                                    </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols=1></v-col>
                                <v-col cols=9>
                                    <v-card v-if="day.activities.length>0" :color = "getColor(day.day_of_week)">
                                        <v-card-text>
                                            <v-row v-for="(actv,index2) in day.activities" :key="index2">
                                                <v-col cols=2>                                                   
                                                   {{dm2(actv.start_hours)}}:{{dm2(actv.start_minutes)}} - {{dm2(actv.end_hours)}}:{{dm2(actv.end_minutes)}}
                                                </v-col>
                                                <v-col cols=2>
                                                    {{actv.project_name}}
                                                </v-col>
                                                <v-col cols=2>
                                                    {{actv.activity_name}}
                                                </v-col>
                                                <v-col cols=2>
                                                    {{actv.wlength}}
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols=6></v-col>
                                                <v-col cols=2>
                                                   <strong> {{sucetLength(index)}} </strong>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>                            
                        </v-card-text>
                    </v-card>
                </v-col>
                </v-row>  
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
</template>

<script>
import axios from '../../axios-auth.js'
import {mapGetters} from 'vuex'

  export default {
    computed: 
        mapGetters([
                        'isAdmin'
                    ])
    ,
    data() {
      return {
        stav_vykazu: '',
        showComment: false,
        myComment: null,
        myPoznamky: null,
        popis_aktivity: 'nevyplnené...',
        editovat_popis: false,
        year: 0,
        month: 0,
        meno: null,
        priezvisko: null,
        days : [],
        dni : ['nedeľa', 'pondelok', 'utorok','streda','štvrtok', 'piatok', 'sobota'],
        dayTypes: ['práca', 'sviatok', 'dovolenka', 'lekár', 'PN', 'voľno'],
        id: null,
        user_id: null,
        activity_id: -1,
        activity_index: -1,
        // ciselniky
        projects: [{
                    id: 1,
                    nazov: "ita",
                    aktivity:[ {
                                id: 1,
                                nazov: 'aktivita 1.1'
                            },{
                                id: 2,
                                nazov: 'aktivita 2.1'
                            }                    
                    ]
                   },
                   {
                    id: 2,
                    nazov: "fake news",
                    aktivity:[ {
                                id: 3,
                                nazov: 'aktivita 2016-18'
                            },{
                                id: 4,
                                nazov: 'aktivita 2018-19'
                            }                    
                    ]
                   },
                   {
                    id: 3,
                    nazov: "eko",
                    aktivity:[ {
                                id: 5,
                                nazov: 'aktivita 2016-18'
                            },{
                                id: 6,
                                nazov: 'aktivita 2018-19'
                            }                    
                    ]
                   }],
            // my content
            mycontent: null,
            /*{
                user_id : 1,
                month: 10,
                year: 2018,
                odpracovane_hodiny
                sviatok
                dovolenka
                lekar
                PN
                nahradne_volno
                plateny_nadcas
                dalsie
                days : [
                    id: 0,
                    day: 1,
                    start_work:
                    end_work:
                    activities: [
                        {
                            id
                            start 
                            end
                            length
                            project
                            activity
                            description
                        }
                    ]
                ]


            }*/
        }
    },      
    mounted () {
      this.initData()
   },
   methods: {
        stringToDate(_date,_format,_delimiter) {
                    var formatLowerCase=_format.toLowerCase();
                    var formatItems=formatLowerCase.split(_delimiter);
                    var dateItems=_date.split(_delimiter);
                    var monthIndex=formatItems.indexOf("mm");
                    var dayIndex=formatItems.indexOf("dd");
                    var yearIndex=formatItems.indexOf("yyyy");
                    var month=parseInt(dateItems[monthIndex]);
                    month-=1;
                    var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
                    return formatedDate;
        },
        getDaysInMonth (month,year) {
            // Here January is 1 based
            //Day 0 is the last day in the previous month
            return new Date(year, month, 0).getDate();
            // Here January is 0 based
            // return new Date(year, month+1, 0).getDate();
        },
        initData() {
            this.$store.commit('setFeedback', null)
            // prepare empty mycontent
            this.id = this.$route.params.id
            this.user_id = this.$route.params.user_id
            // empty data for user
            this.prepareTemplate(this.id)
            // get projects for user
            // this.getUserProjects()
            // read data from db
            this.readDataFromDB()
            // report of month 
        },
        prepareTemplate(myDate) {
            this.year = Math.floor(myDate / 100)
            this.month = myDate % 100
            this.mycontent = {
                id: 0,
                month: this.month,
                year: this.year,
                odpracovane_hodiny: 0,
                sviatok: 0,
                dovolenka: 0,
                lekar: 0,
                PN: 0,
                nahradne_volno: 0,
                plateny_nadcas: 0,
                dalsie: 0,
                days: []
            }

            let n = this.getDaysInMonth(this.month, this.year)
            this.rozvrh_do_dna = n
            for(let i = 1; i <= n; i++) {
                let dow = this.stringToDate("" + i + "/" + this.month + "/" + this.year,"dd/MM/yyyy","/").getDay()
                this.mycontent.days.push( {
                    id: 0,
                    day: i,
                    day_type: this.getDayType(dow),
                    day_of_week: dow,
                    start_hours :0,
                    start_minutes :0,
                    end_hours :0,
                    end_minutes :0,
                    activities: []
                })
            }

        },
        getDayType(dow) {
            if (dow == 0 || dow == 6) return "voľno"
            return "práca"
        },
        getColor(day) {
           if (day==6) return 'blue-grey lighten-5'
           if (day==0) return 'blue-grey lighten-5'
           if (day == 2 || day == 4) return 'yellow lighten-4'
           return 'white'
        },
        getColorTime(minutes) {
            if (minutes == 0) return "red"
            return "white"
        },
        dm2(number) {
            if (number<10) return '0'+number
            else return number
        },
        readDataFromDB() {
            axios.get('/get-projekty-aktivity/'+ this.user_id, this.$store.getters.getAxiosConfig )
            .then(res => {
            // set data to var
                this.projects = res.data.list
                // eslint-disable-next-line no-console
                // console.log(this.projects)

                const data = {
                    month: this.month,
                    year: this.year,
                }

                axios.post('/get-dochadzka/'+ this.user_id, data, this.$store.getters.getAxiosConfig )
                .then(res => {
                // set data to var
                // eslint-disable-next-line no-console
                // console.log(res.data);
                this.stav_vykazu = res.data.stav
                this.popis_aktivity = res.data.popis_aktivity
                this.meno = res.data.meno
                this.priezvisko = res.data.priezvisko
                this.myComment = res.data.komentar
                this.myPoznamky = res.data.poznamky

                for(let i = 0; i < res.data.list.length; i++) {
                    let myday = res.data.list[i].day - 1 // index of day
                    this.mycontent.days[myday].id = res.data.list[i].id
                    this.mycontent.days[myday].start_hours = res.data.list[i].start_hour
                    this.mycontent.days[myday].start_minutes = res.data.list[i].start_minutes
                    this.mycontent.days[myday].end_hours = res.data.list[i].end_hour
                    this.mycontent.days[myday].end_minutes = res.data.list[i].end_minutes
                    this.mycontent.days[myday].day_type = res.data.list[i].day_type
                    
                    for(let j = 0; j < res.data.list[i].activities.length; j++) {
                        const act = {
                            id : res.data.list[i].activities[j].id,
                            start_hours : res.data.list[i].activities[j].start_hours,
                            start_minutes : res.data.list[i].activities[j].start_minutes,
                            end_hours : res.data.list[i].activities[j].end_hours,
                            end_minutes : res.data.list[i].activities[j].end_minutes,
                            wlength : res.data.list[i].activities[j].wlength,
                            project_id : res.data.list[i].activities[j].project_id,
                            project_name: this.getProjectName(res.data.list[i].activities[j].project_id),
                            proj_activity_id : res.data.list[i].activities[j].proj_activity_id,
                            activity_name : this.getActivityName(res.data.list[i].activities[j].proj_activity_id),
                            description : res.data.list[i].activities[j].description,
                            detail : res.data.list[i].activities[j].detail,
                            out_of_work : res.data.list[i].activities[j].out_of_work
                        }
                        this.mycontent.days[myday].activities.push(act)
                        // eslint-disable-next-line no-console
                    // console.log(act);
                    }
                }
                    
                })
                .catch(error => {
                    this.$store.commit('setFeedback',  "User report error " + this.month + "/" + this.year + ": " + error)
                })
            })
            .catch(error => {
               
                this.$store.commit('setFeedback', "User projects error: " + error)
            })
        },
        getProjectName(id) {
            for(let i = 0; i < this.projects.length; i++){
                if (this.projects[i].id == id) return this.projects[i].nazov
            }
            return "---"
        },
        getActivityName(id) {
            for(let i = 0; i < this.projects.length; i++){
                for(let j = 0; j < this.projects[i].aktivity.length; j++){
                    if (this.projects[i].aktivity[j].id == id) return this.projects[i].aktivity[j].nazov
                }
            }
            return "---"
        },
        getActivityIndex(index, myTime) {
             for(let i = this.mycontent.days[index].activities.length-1; i >= 0; i-- ) {
                if (myTime > this.mycontent.days[index].activities[i].start_hours*60 + this.mycontent.days[index].activities[i].start_minutes) {
                    return i+1
                }
            }
            return 0
        },
        getIndexAktivitybyID(day_index, id) {
             for(let i = 0; i < this.mycontent.days[day_index].activities.length; i++ ) {
                if (id == this.mycontent.days[day_index].activities[i].id) {
                    return i
                }
            }
            return -1    
        },
        sucetLength(index) {
            var sum = 0
            for (let i = 0; i < this.mycontent.days[index].activities.length; i++) {
                    // eslint-disable-next-line no-console
                    // console.log(this.mycontent.days[index].activities[i].wlength)
                sum += this.mycontent.days[index].activities[i].wlength
            }
            return sum
        },
        getAktivitaPocetHodin(index,index2) {
            var pocet = 0
            for(let i = 0; i < this.mycontent.days.length; i++) {
                for (let j = 0; j < this.mycontent.days[i].activities.length; j++) {
                    if (this.mycontent.days[i].activities[j].proj_activity_id == this.projects[index].aktivity[index2].id)
                        pocet += this.mycontent.days[i].activities[j].wlength
                }
            }
            return pocet
        },
        getBgColor(stav) {
            if (stav == 'otvorený') return "background-color:#FF8A80;"
            if (stav == 'uzavretý') return "background-color:yellow;"
            if (stav == 'schválený') return "background-color:#76FF03;"
            return "background-color:#40C4FF;"
        },
    zmenaStavu(novy_stav) {
        this.$store.commit('setFeedback',null)

        let povodny_stav = this.stav_vykazu
        if (novy_stav == 'schválený' && povodny_stav !='uzavretý') {
            this.$store.commit('setFeedback', 'Na zmenu stavu na "schválený" musí byť výkaz v stave "uzavretý".')
            return
        }
        if (novy_stav == 'uzavretý' && povodny_stav !='schválený' && povodny_stav !='otvorený') {
            this.$store.commit('setFeedback', 'Na zmenu stavu na "uzavretý" musí byť výkaz v stave "schválený" alebo "otvorený".')
            return
        }
        if (novy_stav == 'otvorený' && povodny_stav !='uzavretý') {
            this.$store.commit('setFeedback', 'Na zmenu stavu na "otvorený" musí byť výkaz v stave "uzavretý".')
            return
        }
        
        const data = {
            user_id: this.user_id,
            rok: this.year,
            mesiac: this.month,
            stav: novy_stav
        }

         // eslint-disable-next-line no-console
         // console.log(data)              


        axios.post('/zmena-stavu-vykazu', data, this.$store.getters.getAxiosConfig )
        .then((res) => {
        // set data to var
            if (res.data.message == 'OK')
              this.stav_vykazu = novy_stav
            else {
                this.$store.commit('setFeedback', res.data.status)
            }
        })
        .catch(error => {
            this.$store.commit('setFeedback', error)
        })
      
    },
    ulozKomentar() {
        const data = {
            komentar : this.myComment,
            user_id: this.user_id,
            mesiac : this.month,
            rok: this.year
        }

        axios.post('/zapis-komentar', data, this.$store.getters.getAxiosConfig )
        .then(res => {
        // set data to var
        // eslint-disable-next-line no-console
            // console.log(res.data)
        if (res.data.message != 'OK') 
            this.$store.commit('setFeedback', res.data.status)
        
        })
        .catch(error => {
            this.$store.commit('setFeedback', error)
        })  

        this.showComment = false
    },
    navratNaProjekt() {
        let id = localStorage.getItem('project_id')   
        if (id == null) return
        this.$router.push({name:'project_users', params: {id: id }})   
    }
    
    }
  }
  
</script>

<style>

</style>