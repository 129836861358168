<template>
    <v-row>
        <v-col cols=12>
            <v-card v-if="myContent">
                <v-card-text>
                    <v-row>
                        <v-col cols=2>
                            <v-btn color="primary" style="margin-left: 10px" @click="navratNaProjekt()">Návrat na projekt...</v-btn>    
                        </v-col>
                        <v-col cols=1>
                            {{month}} / {{year}}
                        </v-col>
                        <v-col cols=3 >
                            <h3>{{myContent.meno}} {{myContent.priezvisko}}</h3>
                        </v-col>
                        <v-col cols=4>
                            <v-row>
                            <v-col cols=2 >
                                <p align="center" style="background-color:pink">sviatok</p>
                            </v-col>
                            <v-col cols=2 >
                                <p align="center" style="background-color:orange">služ. cesta</p>
                            </v-col>
                            <v-col cols=2 >
                                <p align="center" style="background-color:lightgray">voľno</p>
                            </v-col>
                            <v-col cols=2 >
                                <p align="center" style="background-color:lightgreen">dovolenka</p>
                            </v-col>
                            <v-col cols=2 >
                                <p align="center" style="background-color:lightblue">lekár</p>
                            </v-col>
                            <v-col cols=2 >
                                <p align="center" style="background-color:red">PN</p>
                            </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text>
                    <table width = "80%" border=1 cellpadding=0 cellspacing=0>
                        <tr>
                            <td width = "10%">
                                Popis
                            </td>
                            <td>
                                Údaj
                            </td>
                            <td width="3%" v-for="(el, index) in myContent.dochadzka" :key="index" align="center" :bgcolor="getBgColor(index)">
                                {{index+1}}
                            </td>
                            <td align="center">
                                <b>Suma odpracovaných hodín</b>
                            </td>
                        </tr>
                        <tr v-for="(aktivita,index) in myContent.aktivity" :key="index" >
                            <td v-if="aktivita.mimo == 0">
                                    TPP
                            </td>
                            <td v-if="aktivita.mimo == 1">
                                mimo TPP
                            </td>                  
                            <td>
                                {{getProjekt(aktivita.projekt_id)}}/{{getAktivita(aktivita.aktivita_id)}}
                            </td>
                            <td v-for="(hodina, index2) in aktivita.hodiny" :key="index2" align="center" :bgcolor="getBgColor(index2)">
                              <span v-if="hodina>0">   {{hodina}} </span>
                            </td>
                            <td align="center">
                                <strong>{{getSumaRiadok(aktivita.hodiny)}}</strong>
                            </td>
                        </tr>    
                        <tr>
                            <td>
                                pracovné pomery mimo EŠIF (TPP):
                            </td>
                            <td></td>
                            <td v-for="(el, index33) in myContent.tpp" :key="index33" align="center" :bgcolor="getBgColor(index33)">
                                 <span v-if="el>0">   {{el}} </span>
                            </td>
                            <td align="center"><b>{{getSumTPP()}}</b></td>
                        </tr>   

                        <tr>
                            <td>
                                pracovné pomery mimo EŠIF (DoVP/DoPČ):
                            </td>
                            <td></td>
                            <td v-for="(el, index4) in myContent.dochadzka" :key="index4" align="center" :bgcolor="getBgColor(index4)">
                                
                            </td>   
                            <td></td>
                        </tr>   

                        <tr>
                            <td></td>
                            <td width="5%">
                                <v-icon v-if="!editSum && myContent.stav_vykazu !='schválený'" color="primary" @click="editSum = true">edit</v-icon>
                                <v-icon v-if="editSum" color="primary" @click="saveSumHodiny()">save</v-icon>
                                <v-icon v-if="editSum" color="primary" @click="autoSumHodiny()">trending_down</v-icon>
                                Suma
                            </td>
                            <td v-for="(el, index) in myContent.dochadzka" :key="index" align="center"  :bgcolor="getBgColor(index)">
                                <v-text-field type="" @input="SumaRiadokDochadzka()" v-if="editSum" v-model=myContent.dochadzka[index].suma style="background-color:yellow"></v-text-field>
                                <span v-if="!editSum">{{myContent.dochadzka[index].suma}}</span>                
                            </td>
                            <td align="center">
                                <strong>{{sumaDochadzka}}</strong> 
                                
                            </td>
                        </tr>
                        <tr>
                        <td>Úprava doch.</td>
                            <td></td>
                            <td v-for="(el, index) in myContent.dochadzka" :key="index" align="center" :bgcolor="getBgColor(index)">
                                <v-icon small @click="zmenaZaciatku(index)" style="cursor:pointer">sync_problem</v-icon>
                            </td>
                            <td></td>
                        </tr>                        <tr>
                            <td>Začiatok činnosti</td>
                            <td></td>
                            <td v-for="(el, index) in myContent.dochadzka" :key="index" align="center"  :bgcolor="getBgColor(index)">
                                {{el.zac_hod}}:{{dm2(el.zac_min)}}
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Koniec činnosti</td>
                            <td></td>
                            <td v-for="(el, index) in myContent.dochadzka" :key="index" align="center"  :bgcolor="getBgColor(index)">
                                {{el.kon_hod}}:{{dm2(el.kon_min)}}
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Max. počet hodín</td>
                            <td></td>
                            <td v-for="(el, index) in myContent.dochadzka" :key="index" align="center"  :bgcolor="getBgColor(index)">
                                {{el.max_poc_hod}}
                            </td>
                            <td>

                            </td>

                        </tr>
                        <tr>
                            <td>Úprava doch.</td>
                            <td></td>
                            <td v-for="(el, index) in myContent.dochadzka" :key="index" align="center">
                                <v-icon small @click="zmenaKonca(index)" style="cursor:pointer">sync_problem</v-icon>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Nast. voľna</td>
                            <td></td>
                            <td v-for="(el, index) in myContent.dochadzka" :key="index" align="center">
                                <v-icon v-if="giveMeDay(index) == 0 || giveMeDay(index) == 6 " small @click="nastavVolno(index)" style="cursor:pointer">emoji_people</v-icon>
                            </td>
                            <td></td>
                        </tr>

                    </table>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols=1></v-col>
        <v-col cols=3 v-if="myContent" style="margin-top:10px">
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols=1>
                            <v-icon color="primary" v-if="!editZaver && myContent.stav_vykazu !='schválený'" @click="editZaver = true" style="margin-left:10px">edit</v-icon>
                        </v-col>
                        <v-col cols=10> 
                            <v-text-field dense :disabled="!editZaver" v-model="myContent.odpracovane" :label="'odpracované hodiny ('+myContent.k_odpracovane+')'"></v-text-field>
                        </v-col><v-col cols=10 offset-1> 
                            <v-text-field dense :disabled="!editZaver" v-model="myContent.sviatky" :label="'sviatok (' + myContent.k_sviatky+ ')'"></v-text-field>
                        </v-col> <v-col cols=10 offset-1>  
                            <v-text-field dense :disabled="!editZaver" v-model="myContent.dovolenka" :label="'dovolenka (' + myContent.k_dovolenka+')'"></v-text-field>
                        </v-col> <v-col cols=10 offset-1> 
                            <v-text-field dense :disabled="!editZaver" v-model="myContent.lekar" :label="'lekár (vrátane OČR) ('+myContent.k_lekar+')'"></v-text-field>
                        </v-col><v-col cols=10 offset-1> 
                            <v-text-field dense :disabled="!editZaver" v-model="myContent.PN" :label="'PN ('+myContent.k_PN+')'"></v-text-field>
                        </v-col> <v-col cols=10 offset-1> 
                            <v-text-field dense :disabled="!editZaver" v-model="myContent.nahradne_volno" :label="'náhradné voľno ('+ myContent.k_nahradne_volno+')'"></v-text-field>
                        </v-col><v-col cols=10 offset-1> 
                            <v-text-field dense :disabled="!editZaver" v-model="myContent.plateny_nadcas" :label="'platený nadčas ('+ myContent.k_plateny_nadcas + ')'"></v-text-field>
                        </v-col> <v-col cols=10 offset-1>  
                            <v-text-field dense :disabled="!editZaver" v-model="myContent.dalsie" :label="'ďalšie'"></v-text-field>
                        </v-col>
                        <v-col cols=2 offset-9>
                            <v-btn v-if="editZaver" @click="saveZaver()">Uložiť</v-btn>
                        </v-col>
                        <v-col cols=10 offset-2>
                            <v-btn v-if="myContent.stav_vykazu =='uzavretý' && !editZaver" style="margin-left: 10px" @click="znovuUzavrietVykaz()">Znovu uzavrieť výkaz </v-btn>    
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols=7 v-if="myContent" style="margin-top:10px">
            <v-card v-if="zmenit_koniec_PD || zmenit_zaciatok_PD" color="red">
                <v-card-title>
                    <span v-if="zmenit_zaciatok_PD">Chystáte sa zmeniť príchod do práce: {{den_pre_zmenu}}.{{month}}.{{year}}</span>
                    <span v-if="zmenit_koniec_PD">Chystáte sa zmeniť odchod z práce: {{den_pre_zmenu}}.{{month}}.{{year}}</span>
                </v-card-title>
                <v-card-text>                    
                    <v-text-field label="Hodiny:" type ="number" max="23" min="0" step="1" v-model="hodiny_na_zmenu" style="margin-right:10px"></v-text-field>
                    <v-text-field label="Minúty:" type ="number" max="59" min="0" step="1" v-model="minuty_na_zmenu" style="margin-right:10px"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="zmenit_koniec_PD = false; zmenit_zaciatok_PD = false"> Zrušiť</v-btn>
                    <v-btn @click="zapisZmenuDochadzky()"> Naozaj to chcem!</v-btn>
                </v-card-actions>
            </v-card>

            <v-card v-if="urobit_volny_den" color="red">
                <v-card-title>
                    Chystáte sa urobiť voľný deň: {{den_pre_zmenu}}.{{month}}.{{year}}
                </v-card-title>
                <v-card-actions>
                    <v-btn @click="urobit_volny_den = false"> Zrušiť</v-btn>
                    <v-btn @click="zapisVolnyDen()"> Naozaj to chcem!</v-btn>
                </v-card-actions>
            </v-card>

            <v-card>
                <v-card-text>
                    <p v-if="prepocetTyzdnov"> {{prepocitajTyzdne()}}</p>
                    <v-row>
                        <v-col cols=1 v-for="(tyzden,index) in tyzdne" :key="index"> 
                            <v-layout row wrap justify-center>
                                <b><span :style="getColorWeek(tyzden.odpracovane)"> {{tyzden.odpracovane}} </span></b>
                            </v-layout>
                            <v-layout row wrap justify-center>
                                <span> {{tyzden.max}} </span>
                            </v-layout>
                        </v-col>                        
                    </v-row>
                </v-card-text>                
            </v-card>
            <v-card>
                <v-card-title>
                    <v-row>                        
                        <v-btn v-if="myContent.stav_vykazu =='uzavretý'" color="primary" style="margin-left: 10px" @click="schvalitVykaz()">Schváliť výkaz </v-btn>    
                        <v-btn v-if="myContent.stav_vykazu =='schválený'" color="primary" style="margin-left: 10px" @click="zmenaStavu('uzavretý')">Zrušiť schválenie</v-btn>    
                        <v-btn v-if="myContent.stav_vykazu !='schválený'" color="primary" style="margin-left: 10px" @click="download()">Stiahnuť (neodporúča sa) </v-btn>    
                        <v-btn v-if="myContent.stav_vykazu =='schválený'" color="primary" style="margin-left: 10px" @click="download()">Stiahnuť</v-btn>    
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols=12 v-if="feedback">
                            <p style="color:red"> {{feedback}} </p>
                        </v-col>
                        <v-col cols=10 offset-1> 
                          <strong>Poznámky: </strong>  
                        </v-col>
                        <v-col cols=10 offset-1> 
                          {{myContent.poznamky}}
                          <hr>
                        </v-col>
                        <v-col cols=10 offset-1> 
                          <strong>Popis činnosti: </strong> 
                        </v-col>
                        <v-col cols=10 offset-1> 
                          {{myContent.popis_aktivity}}
                        </v-col>
                        <v-col cols=10 offset-1> 
                          <strong>Výstupy: </strong> 
                        </v-col>
                        <v-col cols=10 offset-1> 
                          {{myContent.popis_vystupy}}
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <div id="ss" style="height:10px ; width :100%; "></div>
    </v-row>
</template>

<script>
import axios from '../../axios-auth.js'

export default {
    mounted () {
        this.initData()
    },
    data() {
        return {
            inicialised: false,
            month: 0,
            year: 0, 
            myContent: null,
            mycontent: null,
            projects : null,
            editSum: false,
            editZaver: false,
            typy_dni:[],
            stav_vykazu: '',
            feedback: null,
            excelSource: null,
            tyzdne: [],
            prepocetTyzdnov: false,
            sumaDochadzka: 0,
            zmenit_koniec_PD : false,
            zmenit_zaciatok_PD : false,
            den_pre_zmenu : 0,
            hodiny_na_zmenu: 0,
            minuty_na_zmenu: 0,
            urobit_volny_den: false,
            project_id : 0
        }
    },
    methods: {
        initData() {
            this.year = Math.floor(this.$route.params.id / 100)
            this.month =  this.$route.params.id % 100
            this.project_id = this.$route.params.project_id
            this.nastavTypyDni()
            this.readDatafromDB()
        },

        readDatafromDB() {
            axios.get('/get-projekty-aktivity/' + this.$route.params.user_id, this.$store.getters.getAxiosConfig )
            .then(res => {
            // set data to var
                this.projects = res.data.list;                

                const data = {
                    user_id : this.$route.params.user_id,
                    project_id: this.$route.params.project_id,
                    rok: this.year,
                    mesiac: this.month
                }

                // eslint-disable-next-line no-console
                //    console.log(data)

                axios.post('/get-tabulka', data, this.$store.getters.getAxiosConfig )
                .then(res => {
                    this.myContent = res.data 
                    // eslint-disable-next-line no-console
                    // console.log(res.data)
                    this.myContent.tpp = []
                    for(let i = 0; i< this.typy_dni.length; i++) {
                        this.typy_dni[i].typ_dna = this.myContent.dochadzka[i].typ_dna
                        if ((this.myContent.dochadzka[i].typ_dna == "práca" )  || (this.myContent.dochadzka[i].typ_dna == "služobná cesta"))                   
                                this.myContent.tpp.push( 
                                                        parseFloat(this.myContent.dochadzka[i].suma)
                                                        )
                        else this.myContent.tpp.push(0)

                    }
                    this.SumaRiadokDochadzka()
                })
                .catch(error => {
                    this.$store.commit('setFeedback', error)
                })            
            })
            .catch(error => {
                this.$store.commit('setFeedback', error)
            })            
        },
        dm2(number) {
            if (number<10) return '0'+number
            else return number
        },
        getProjekt(id) {
            for(let i = 0; i< this.projects.length; i++ ) {
                if (this.projects[i].id == id)
                    return this.projects[i].ITMS
            }
            return '-----'
        },
        getAktivita(id) {
            for(let i = 0; i< this.projects.length; i++ ) {
                for(let j = 0; j< this.projects[i].aktivity.length; j++ ) {
                if (this.projects[i].aktivity[j].id == id)
                    return this.projects[i].aktivity[j].nazov.substring(0,this.projects[i].aktivity[j].nazov.indexOf(';'))
                }
            }
            return '-----'
        },
        getSumaRiadok(pole){
            var sucet = 0
            for(let i=0; i<pole.length; i++) {
                sucet +=pole[i]
            }
            return sucet
        },
        SumaRiadokDochadzka(){
            var sucet = 0
            for(let i = 0; i < this.myContent.dochadzka.length; i++) {
                if ((this.myContent.dochadzka[i].typ_dna == 'práca') || (this.myContent.dochadzka[i].typ_dna == 'služobná cesta')) {
                    sucet += parseFloat(this.myContent.dochadzka[i].suma)
                 }
            }            
            this.sumaDochadzka = Math.round(sucet*100)/100
            this.prepocitajTyzdne()
            this.PrepocitajTpp()
        },
        prepocitajTyzdne() {
            this.tyzdne = []
            var s1 = 0
            var s2 = 0
            for(let i = 0; i < this.myContent.dochadzka.length; i++) {
                if ((this.myContent.dochadzka[i].typ_dna == 'práca') || (this.myContent.dochadzka[i].typ_dna == 'služobná cesta')){
                   s1 += parseFloat(this.myContent.dochadzka[i].suma)
                   s2 += parseFloat(this.myContent.dochadzka[i].max_poc_hod-0.5)
                }
                if (this.myContent.dochadzka[i].typ_dna == 'voľno') {
                    if (s1>0) {
                        this.tyzdne.push( {
                            odpracovane: Math.round(s1*100)/100,
                            max: Math.round(s2*100)/100
                        })    
                        s1 = 0
                        s2 = 0
                    }
                }
            }
            if (s1>0) {
                this.tyzdne.push( {
                    odpracovane: Math.round(s1*100)/100,
                    max: Math.round(s2*100)/100
                })    
            }
        },
        stringToDate(_date,_format,_delimiter) {
            var formatLowerCase=_format.toLowerCase();
            var formatItems=formatLowerCase.split(_delimiter);
            var dateItems=_date.split(_delimiter);
            var monthIndex=formatItems.indexOf("mm");
            var dayIndex=formatItems.indexOf("dd");
            var yearIndex=formatItems.indexOf("yyyy");
            var month=parseInt(dateItems[monthIndex]);
            month-=1;
            var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
            return formatedDate;
        },
        getDaysInMonth (month,year) {
            // Here January is 1 based
            //Day 0 is the last day in the previous month
            return new Date(year, month, 0).getDate();
            // Here January is 0 based
            // return new Date(year, month+1, 0).getDate();
        },
        nastavTypyDni() {
            let n = this.getDaysInMonth(this.month, this.year)
            this.typy_dni = []
            for(let i = 0; i< n; i++) {
                let dow = this.stringToDate("" + (i+1) + "/" + this.month + "/" + this.year,"dd/MM/yyyy","/").getDay()
                this.typy_dni.push( {
                        den: dow,
                        typ_dna: this.getDayType(dow)
                    }
                )
            }
        },
        getDayType(dow) {
            if (dow == 0 || dow == 6) return "voľno"
            return "práca"
        },
        getBgColor(index) {
            if (this.typy_dni[index].den == 0 || this.typy_dni[index].den == 6) return "lightgray"
            if (this.typy_dni[index].typ_dna == "PN") return "red"
            if (this.typy_dni[index].typ_dna == "voľno") return "yellow"
            if (this.typy_dni[index].typ_dna == "lekár") return "lightblue"
            if (this.typy_dni[index].typ_dna == "dovolenka") return "lightgreen"
            if (this.typy_dni[index].typ_dna == "služobná cesta") return "orange"
            if (this.typy_dni[index].typ_dna == "sviatok") return "pink"

            return "white"
        },
        saveSumHodiny() {
            this.$store.commit('setFeedback', null)
            /// zapis do dennej dochadzky

            // ciselna kontrola
            var my_dni = []
            for(let i = 0; i < this.myContent.dochadzka.length; i++) {
                try {
                    if (isNaN(this.myContent.dochadzka[i].suma)) {
                        this.$store.commit('setFeedback', "Nekorektná hodnota: " + (i+1) + "." + this.month + "." + this.year)
                        return
                    }
                    my_dni.push(this.myContent.dochadzka[i].suma)
                    
                } catch (error) {
                    this.$store.commit('setFeedback', "Nekorektná hodnota: " + (i+1) + "." + this.month + "." + this.year)
                    return
                }
            }

            var d = ''
            for(let i = 0; i < my_dni.length; i++) {
                if (i < my_dni.length - 1) {
                    d = d + my_dni[i] + ','
                } else {
                    d += my_dni[i]
                }
            }

            // generuj data pre axios   
            const data = {
                user_id: this.$route.params.user_id,
                mesiac : this.month,
                rok: this.year,
                dni: d
            }

            axios.post('/zapis-suma-za-dni', data, this.$store.getters.getAxiosConfig )
                .then(res => {
                    if (res.data.message == '!OK')
                        this.$store.commit('setFeedback', res.data.status)

                    this.PrepocitajTpp()
                })
                .catch(error => {
                    this.$store.commit('setFeedback', error)
                }) 
            
            this.editSum = false
        },
        PrepocitajTpp() {
            // eslint-disable-next-line no-console
            // console.log(this.myContent)   

            for(let i = 0; i < this.myContent.tpp.length; i++) {
                if ((this.myContent.dochadzka[i].typ_dna == "práca") || (this.myContent.dochadzka[i].typ_dna == "služobná cesta"))
                    this.myContent.tpp[i] = this.myContent.dochadzka[i].suma
                else 
                    this.myContent.tpp[i] = 0
                for(let j = 0; j < this.myContent.aktivity.length; j++) {
                    if ((this.myContent.dochadzka[i].typ_dna == "práca") || (this.myContent.dochadzka[i].typ_dna == "služobná cesta"))
                        this.myContent.tpp[i] = this.myContent.tpp[i] - this.myContent.aktivity[j].hodiny[i]
                }

                // eslint-disable-next-line no-console
               // console.log(this.myContent.tpp[i] +" - " + Math.round(this.myContent.tpp[i]*100)/100)   
                this.myContent.tpp[i] = Math.round(this.myContent.tpp[i]*100)/100
            }
        },
        getSumTPP() {
            var sum = 0
            for(let i = 0; i < this.myContent.tpp.length; i++) {
                sum +=this.myContent.tpp[i]
            }
            return Math.round(sum*100)/100
        },
        saveZaver() {
            this.$store.commit('setFeedback', null)
            this.feedback = null

            if (isNaN(this.myContent.odpracovane)) {
                        this.$store.commit('setFeedback', "Nečíselná hodnota v odpracované")
                        this.feedback = "Nečíselná hodnota v odpracované"
                        return
                    }

            if (isNaN(this.myContent.sviatky)) {
                        this.$store.commit('setFeedback', "Nečíselná hodnota v sviatky")
                        this.feedback = "Nečíselná hodnota v sviatky"
                        return
                    }
            if (isNaN(this.myContent.dovolenka)) {
                        this.$store.commit('setFeedback', "Nečíselná hodnota v dovolenka")
                        this.feedback = "Nečíselná hodnota v dovolenka"
                        return
                    }
            if (isNaN(this.myContent.lekar)) {
                        this.$store.commit('setFeedback', "Nečíselná hodnota v lekar")
                        this.feedback = "Nečíselná hodnota v lekar"
                        return
                    }
            if (isNaN(this.myContent.PN)) {
                        this.$store.commit('setFeedback', "Nečíselná hodnota v PN")
                        this.feedback = "Nečíselná hodnota v PN"
                        return
                    }
            if (isNaN(this.myContent.nahradne_volno)) {
                        this.$store.commit('setFeedback', "Nečíselná hodnota v nahradne_volno")
                        this.feedback = "Nečíselná hodnota v nahradne_volno"
                        return
                    }
            if (isNaN(this.myContent.plateny_nadcas)) {
                        this.$store.commit('setFeedback', "Nečíselná hodnota v plateny_nadcas")
                        this.feedback = "Nečíselná hodnota v plateny_nadcas"
                        return
                    }
            if (isNaN(this.myContent.dalsie)) {
                        this.$store.commit('setFeedback', "Nečíselná hodnota v dalsie")
                        this.feedback = "Nečíselná hodnota v dalsie"
                        return
                    }


            const data = {
                user_id: this.$route.params.user_id,
                rok: this.year,
                mesiac: this.month,
                odpracovane: this.myContent.odpracovane,
                sviatky: this.myContent.sviatky,
                dovolenka: this.myContent.dovolenka,
                lekar: this.myContent.lekar,
                PN: this.myContent.PN,
                nahradne_volno: this.myContent.nahradne_volno,
                plateny_nadcas: this.myContent.plateny_nadcas,
                dalsie: this.myContent.dalsie
            }


            axios.post('/zapis-sumar', data, this.$store.getters.getAxiosConfig )
                .then(res => {
                    if (res.data.message == '!OK')
                        this.$store.commit('setFeedback', res.data.status)

                    this.editZaver = false

                })
                .catch(error => {
                    this.$store.commit('setFeedback', error)
                }) 

        },
        schvalitVykaz() {
            this.SumaRiadokDochadzka()
            this.$store.commit('setFeedback', null)
            this.feedback = null
            // kontrola den hodiny
            for(let i = 0; i < this.myContent.dochadzka.length; i++) {
                if (this.myContent.dochadzka[i].max_poc_hod < this.myContent.dochadzka[i].suma + 0.5) {
                    this.$store.commit('setFeedback', "Max. počet hodín je oproti sume odpracovaných hodín malý dňa " + (i+1) + "." + this.month + "." + this.year)
                    this.feedback = "Max. počet hodín je oproti sume odpracovaných hodín malý dňa " + (i+1) + "." + this.month + "." + this.year
                    return
                }
            }
            
            // kontrola suctov
            var suma = 0
            suma = suma + this.getSumTPP()
            for(let i = 0; i<this.myContent.aktivity.length; i++) {
                for(let j = 0; j < this.myContent.aktivity[i].hodiny.length; j++ ) {
                    suma += this.myContent.aktivity[i].hodiny[j]
                }
            }

            if (suma != this.sumaDochadzka) {
                    this.$store.commit('setFeedback', "Súčet hodín v aktivitách a dennej dochádzky nie je totožný.")
                    this.feedback = "Súčet hodín v aktivitách a dennej dochádzky nie je totožný."
                    return
            }

            // kontrola sumarizacii - sviatky, dovolenky, PN
            if (parseFloat(this.sumaDochadzka) != this.myContent.odpracovane) {
                    this.$store.commit('setFeedback', "Súčet hodín v dennej dochádzke a sumáre nie je totožný.")
                    this.feedback = "Súčet hodín v dennej dochádzke a sumáre nie je totožný."
                    return
            }

            if (this.getSumTypDna('sviatok') != parseFloat(this.myContent.sviatky)) {
                    this.$store.commit('setFeedback', "Súčet sviatkov v dennej dochádzke a sumáre nie je totožný.")
                    this.feedback = "Súčet sviatkov v dennej dochádzke a sumáre nie je totožný."
                    return
            }

            if (this.getSumTypDna('dovolenka') != parseFloat(this.myContent.dovolenka)) {
                    this.$store.commit('setFeedback', "Súčet dovoleniek v dennej dochádzke a sumáre nie je totožný.")
                    this.feedback = "Súčet dovoleniek v dennej dochádzke a sumáre nie je totožný."
                    return
            }

            if (this.getSumTypDna('PN') != parseFloat(this.myContent.PN)) {
                    this.$store.commit('setFeedback', "Súčet PN v dennej dochádzke a sumáre nie je totožný.")
                    this.feedback = "Súčet PN v dennej dochádzke a sumáre nie je totožný."
                    return
            }

            if (this.getSumTypDna('lekár') != parseFloat(this.myContent.lekar)) {
                    this.$store.commit('setFeedback', "Súčet lekára v dennej dochádzke a sumáre nie je totožný.")
                    this.feedback = "Súčet lekára v dennej dochádzke a sumáre nie je totožný."
                    return
            }

            // zmena stavu
            this.zmenaStavu('schválený')

        },
        getSumTypDna(typ) {
            var suma = 0
            for(let i = 0 ; i<this.myContent.dochadzka.length; i++) {
                if (this.myContent.dochadzka[i].typ_dna == typ)
                    suma += parseFloat(this.myContent.dochadzka[i].suma)
            }

            // eslint-disable-next-line no-console
            // console.log(typ + ": " + suma)   
            return suma
        },
        zmenaStavu(novy_stav) {
            const data = {
                user_id: this.$route.params.user_id,
                rok: this.year,
                mesiac: this.month,
                stav: novy_stav
            }

            axios.post('/zmena-stavu-vykazu', data, this.$store.getters.getAxiosConfig )
            .then((res) => {
            // set data to var
                if (res.data.message == 'OK')
                this.myContent.stav_vykazu = novy_stav
                else {
                    this.$store.commit('setFeedback', res.data.status)
                    this.feedback = res.data.status
                }
            })
            .catch(error => {
                this.$store.commit('setFeedback', error)
                this.feedback = error
            })
        },
        autoSumHodiny() {
            for (let i = 0; i<this.myContent.dochadzka.length; i++ ) {
                if (this.myContent.dochadzka[i].typ_dna != "voľno")
                    this.myContent.dochadzka[i].suma =  this.myContent.dochadzka[i].max_poc_hod - 0.5 
                else 
                    this.myContent.dochadzka[i].suma =  0
            }
        },
        navratNaProjekt() {
            let id = localStorage.getItem('project_id')   
            if (id == null) return
            this.$router.push({name:'project_users', params: {id: id }})   
        },
        getColorWeek(cas) {
            if (cas == 37.5) return "color:lightgreen"
            if (cas % 7.5 != 0 && cas % 5.25 != 0) return "color:red"
            return "color:blue"
        },
        giveMeDay(index) {
            return this.stringToDate("" + (index+1) + "/" + this.month + "/" + this.year,"dd/MM/yyyy","/").getDay()
        },

//************************** UZATVARANIE VYKAZU **************************************************************** */
        prepareTemplate() {
            this.mycontent = {
                id: 0,
                month: this.month,
                year: this.year,
                odpracovane_hodiny: 0,
                sviatok: 0,
                dovolenka: 0,
                lekar: 0,
                PN: 0,
                nahradne_volno: 0,
                plateny_nadcas: 0,
                dalsie: 0,
                days: []
            }

            let n = this.getDaysInMonth(this.month, this.year)
            this.rozvrh_do_dna = n
            for(let i = 1; i <= n; i++) {
                let dow = this.stringToDate("" + i + "/" + this.month + "/" + this.year,"dd/MM/yyyy","/").getDay()
                this.mycontent.days.push( {
                    id: 0,
                    day: i,
                    day_type: this.getDayType(dow),
                    day_of_week: dow,
                    start_hours :0,
                    start_minutes :0,
                    end_hours :0,
                    end_minutes :0,
                    activities: []
                })
            }
        },


        znovuUzavrietVykaz() {
            const data = {
                year: this.year,
                month: this.month,
            }

            // nacitat mycontent    
                axios.post('/get-dochadzka/'+ this.$route.params.user_id, data, this.$store.getters.getAxiosConfig )
                .then(res => {
                // set data to var
                    this.prepareTemplate()
                    // eslint-disable-next-line no-console
                    //console.log(res.data);

                    for(let i = 0; i < res.data.list.length; i++) {
                        let myday = res.data.list[i].day - 1 // index of day
                        this.mycontent.days[myday].id = res.data.list[i].id
                        this.mycontent.days[myday].start_hours = res.data.list[i].start_hour
                        this.mycontent.days[myday].start_minutes = res.data.list[i].start_minutes
                        this.mycontent.days[myday].end_hours = res.data.list[i].end_hour
                        this.mycontent.days[myday].end_minutes = res.data.list[i].end_minutes
                        this.mycontent.days[myday].day_type = res.data.list[i].day_type
                        
                        for(let j = 0; j < res.data.list[i].activities.length; j++) {
                            const act = {
                                id : res.data.list[i].activities[j].id,
                                start_hours : res.data.list[i].activities[j].start_hours,
                                start_minutes : res.data.list[i].activities[j].start_minutes,
                                end_hours : res.data.list[i].activities[j].end_hours,
                                end_minutes : res.data.list[i].activities[j].end_minutes,
                                wlength : res.data.list[i].activities[j].wlength,
                                project_id : res.data.list[i].activities[j].project_id,
                                project_name: '',
                                proj_activity_id : res.data.list[i].activities[j].proj_activity_id,
                                activity_name : '',
                                description : res.data.list[i].activities[j].description,
                                detail : res.data.list[i].activities[j].detail,
                                out_of_work : res.data.list[i].activities[j].out_of_work
                            }
                            this.mycontent.days[myday].activities.push(act)

                        }
                    }

                    // uzavretie
                    var _dovolenka = 'dovolenka: '
                    var _sviatok = 'sviatky: '
                    var _lekar = 'lekár: '
                    var _PN = 'PN: '
                    var _SC = 'služ. cesty: '
                    var odpracovane = 0
                    var sviatok = 0
                    var dovolenka = 0
                    var lekar = 0
                    var PN = 0
                    var nahradne_volno = 0
                    var plateny_nadcas = 0
                    
                    for (let i = 0 ; i < this.mycontent.days.length; i++ )  {
                        if (this.mycontent.days[i].day_type == "dovolenka") {
                            if (_dovolenka.length > 11) _dovolenka += ', '
                            _dovolenka +='' + this.mycontent.days[i].day + '.'+ this.month + '.'
                            dovolenka +=  7.5
                        }
                        if (this.mycontent.days[i].day_type == "sviatok") {                    
                            if (this.mycontent.days[i].day_of_week != 0 && this.mycontent.days[i].day_of_week != 6) {
                                if (_sviatok.length > 9) _sviatok += ', '
                                _sviatok +='' + this.mycontent.days[i].day + '.'+ this.month + '.'
                                sviatok +=  7.5
                            }
                        }
                        if (this.mycontent.days[i].day_type == "lekár") {
                            if (_lekar.length > 7) _lekar += ', '
                            _lekar +='' + this.mycontent.days[i].day + '.'+ this.month + '.'
                            lekar +=  7.5
                        }
                        if (this.mycontent.days[i].day_type == "PN") {
                            if (_PN.length > 4) _PN += ', '
                            _PN +='' + this.mycontent.days[i].day + '.'+ this.month + '.'
                            PN +=  7.5
                        }
                        if (this.mycontent.days[i].day_type == "služobná cesta") {
                            // eslint-disable-next-line no-console
                            //console.log('sc')
                            if (_SC.length > 13) _SC += ', '
                            _SC +='' + this.mycontent.days[i].day + '.'+ this.month + '.'
                            odpracovane +=  7.5
                        }

                        if ((this.mycontent.days[i].day_type == "práca")) {
                            odpracovane +=  7.5
                        }

                    }
                    var _poznamky = "";
                    if (_sviatok.length > 9) _poznamky += _sviatok + '; ';
                    if (_dovolenka.length > 11) _poznamky += _dovolenka + '; ';
                    if (_lekar.length > 7) _poznamky += _lekar + '; ';
                    if (_PN.length > 4) _poznamky += _PN + '; ';
                    if (_SC.length > 13) _poznamky += _SC + '; ';
                    
                    //uzavri vykaz
                    const data = {
                        month : this.month,
                        year: this.year,
                        // vygenerovany text
                        poznamky: _poznamky,
                        odpracovane_hodiny: odpracovane,
                        sviatok: sviatok,
                        dovolenka: dovolenka,
                        lekar: lekar,
                        PN: PN,
                        nahradne_volno: nahradne_volno,
                        plateny_nadcas: plateny_nadcas
                    }                    

                    axios.post('/uzavri-vykaz/'+ this.$route.params.user_id, data, this.$store.getters.getAxiosConfig )
                    .then(res => {
                        if (res.data.message == 'OK') {
                            this.stav_vykazu = 'uzavretý'
                            this.initData() // refresh

                        }
                        else
                            this.$store.commit('setFeedback', res.data.status)
                    
                    })
                    .catch(error => {
                        this.$store.commit('setFeedback', error)
                    })  
                    
                })
                .catch(error => {
                    this.$store.commit('setFeedback',  "User report error " + this.month + "/" + this.year + ": " + error)
                })

        },
        download() {
            let _month = this.month
            const data = {
                user_id: this.$route.params.user_id,
                rok: this.year,
                project_id: this.$route.params.project_id,
                mesiac: _month // this.month
            }
            var ac = this.$store.getters.getAxiosConfig 
            ac.responseType = 'arraybuffer'

            axios.post('/prepare-download-2021', data, ac)
                    .then((res) => {
                    // set data to var

                        const url = URL.createObjectURL(new Blob([res.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        }))
                        const link = document.createElement('a')
                        link.href = url
                        var mm = _month//this.month
                        if (/*this.*/_month < 10) mm = '0' + _month   //this.month                          
                        link.setAttribute('download', '' + this.year + '-' + mm + '-' + this.myContent.priezvisko + '.xlsx')
                        document.body.appendChild(link)
                        link.click()              
                    })
                    .catch(error => {
                        this.$store.commit('setFeedback', error)
                    }) 
            

        },
        zmenaKonca(index) {
            this.zmenit_koniec_PD = true
            this.zmenit_zaciatok_PD = false
            this.den_pre_zmenu = index + 1
            this.hodiny_na_zmenu = this.myContent.dochadzka[index].kon_hod
            this.minuty_na_zmenu = this.myContent.dochadzka[index].kon_min
        },
        zmenaZaciatku(index) {
            this.zmenit_koniec_PD = false
            this.zmenit_zaciatok_PD = true
            this.den_pre_zmenu = index + 1
            this.hodiny_na_zmenu = this.myContent.dochadzka[index].zac_hod
            this.minuty_na_zmenu = this.myContent.dochadzka[index].zac_min
        },
        zapisZmenuDochadzky() {
            const _hod = parseFloat(this.hodiny_na_zmenu)
            const _min = parseFloat(this.minuty_na_zmenu)
            var _pov_hod = 0 
            var _pov_min = 0 
            var _zmena = 'x'
            if (isNaN(_hod) || (isNaN(_min))) {
                this.$store.commit('setFeedback', "Zadali ste nečíselnú hodnotu")
                return
            }
            if (this.zmenit_zaciatok_PD) {
                _zmena = 'z'
                _pov_hod = this.myContent.dochadzka[this.den_pre_zmenu-1].zac_hod
                _pov_min = this.myContent.dochadzka[this.den_pre_zmenu-1].zac_min
            } else {
                _zmena = 'k'
                _pov_hod = this.myContent.dochadzka[this.den_pre_zmenu-1].kon_hod
                _pov_min = this.myContent.dochadzka[this.den_pre_zmenu-1].kon_min
            } 

            const data = {
                user_id : this.$route.params.user_id,
                den : this.den_pre_zmenu,
                mesiac: this.month,
                rok: this.year,
                zmena: _zmena,
                pov_hod : _pov_hod,
                pov_min : _pov_min,
                new_hod : _hod,
                new_min : _min
            }

            axios.post('/zmena-odchodu', data, this.$store.getters.getAxiosConfig )
                    .then(res => {
                        if (res.data.message != 'OK') {
                            this.$store.commit('setFeedback', res.data.status)                    
                        } else {
                            if (this.zmenit_zaciatok_PD) {
                                this.myContent.dochadzka[this.den_pre_zmenu-1].suma = 
                                    Math.round(parseFloat(this.myContent.dochadzka[this.den_pre_zmenu-1].suma)*100 + 
                                            ((_pov_hod - _hod) * 60 + _pov_min - _min)/60*100)/100
                                this.myContent.dochadzka[this.den_pre_zmenu-1].zac_hod = _hod
                                this.myContent.dochadzka[this.den_pre_zmenu-1].zac_min = _min
                            } else {
                                this.myContent.dochadzka[this.den_pre_zmenu-1].suma = 
                                    Math.round(parseFloat(this.myContent.dochadzka[this.den_pre_zmenu-1].suma)*100 + 
                                                ((_hod - _pov_hod) * 60 + 
                                                _min - _pov_min)/60*100)/100
                                this.myContent.dochadzka[this.den_pre_zmenu-1].kon_hod = _hod
                                this.myContent.dochadzka[this.den_pre_zmenu-1].kon_min = _min
                            }

                            this.myContent.dochadzka[this.den_pre_zmenu-1].max_poc_hod = 
                                Math.round((this.myContent.dochadzka[this.den_pre_zmenu-1].suma + 0.5)*100)/100

                            this.SumaRiadokDochadzka()  
                            this.zmenit_koniec_PD = false     
                            this.zmenit_zaciatok_PD = false     
                            this.initData()                     
                        }
                    })
                    .catch(error => {
                        this.$store.commit('setFeedback', error)
                    })  
        },
        nastavVolno(index) {
            this.$store.commit('setFeedback',null)
            let dow = this.stringToDate("" + (index+1) + "/" + this.month + "/" + this.year,"dd/MM/yyyy","/").getDay()
            
            // eslint-disable-next-line no-console
            //console.log(dow);
            if (dow>0 && dow <6) {
                this.$store.commit('setFeedback', "Nemožno uvoľniť iné dni ako sobotu alebo nedeľu.")
                return
            }

            this.urobit_volny_den = true
            this.den_pre_zmenu = index + 1

        },
        zapisVolnyDen() {

            const data = {
                user_id : this.$route.params.user_id,
                den : this.den_pre_zmenu,
                mesiac: this.month,
                rok: this.year
            }

            axios.post('/uvolni-den', data, this.$store.getters.getAxiosConfig )
                    .then(res => {
                        if (res.data.message != 'OK') {
                            this.$store.commit('setFeedback', res.data.status)                    
                        } else {
                            this.urobit_volny_den = false
                            this.initData()                        
                        }
                    })
                    .catch(error => {
                        this.$store.commit('setFeedback', error)
                    })  
        }

    }

}
</script>