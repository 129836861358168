import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../axios-auth';
import router from '../router/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    isLogged: false,
    user: null,
    axios_config: null,
    feedback : null,   
    corsConfig: {
      headers: { 
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }
    },
  },
//*********************************************************************** */
//*********************************************************************** */
  mutations: {
    setUserData(state, userData) {
      state.user = userData
      localStorage.setItem('user_id', userData.user_id)
      localStorage.setItem('role_id', userData.role_id)
    },
    //*********************************************************************** */
    setConfig(state, data) {
      state.axios_config = {
        headers: {
          Authorization: 'Bearer ' + data
        }
      }
      state.token = data    
      state.isLogged = true
    },
    //*********************************************************************** */
    logout(state) {
      state.user = null
      state.isLogged = false
      state.axios_config = null
      state.token = null
      localStorage.clear()
    },
    //*********************************************************************** */
    setFeedback(state, data) {
      // if (data)
       state.feedback = data
    }
  },

//*********************************************************************** */
//*********************************************************************** */
  actions: {
    register( {dispatch, commit}, authData ) {
      commit('setFeedback', null)
      axios.post('/register', authData)
      .then(() => {
        dispatch('login', {
          username: authData.email,
          password: authData.heslo,
          grant_type:"password",
          client_id: "2",
          client_secret: "iQuGUAzqc187j7IKQ94tTVJAywHCAzYBGAMTxEtr",
          loader: false,
          infoError: false
        })
      })
      .catch(error => {
        commit('setFeedback', error)
      })
    },
    //*********************************************************************** */
    login( {commit, state}, authData ) {
      commit('setFeedback', null)
      // eslint-disable-next-line no-console
      //console.log(state.corsConfig)
      axios.post('/oauth/token', authData/*, state.corsConfig*/)
      .then(res => {        
        //console.log(authData)
        //console.log(res.data)
        // TOKEN TO LOCAL STORAGE
        localStorage.setItem('token', res.data.access_token) 
        commit('setConfig', res.data.access_token)
        axios.get('/get-full-user-parameters', state.axios_config)
        .then(res => {
            // set data to store
            commit('setUserData', res.data)     
            router.push({name : 'dashboard'})
        })
        .catch(error => {
          commit('setFeedback', "login error:" + error.response.data.message)    
        })
      })
      .catch(() => {
        commit('setFeedback', "Access denied.")
      })
    },
    //*********************************************************************** */
    tryAutologin({commit, state}) {
      commit('setFeedback', null)
      state.token = localStorage.getItem('token')
      if (state.token != null) {
        commit('setConfig', state.token)

        axios.get('/get-full-user-parameters', state.axios_config)
        .then(res => {
            // set data to store
            commit('setUserData', res.data) 
        })
        .catch(() => {
            commit('logout')
            router.replace({name : 'login'})
        })
      } else {
          router.replace({name : 'login'})        
      }
    },
    //*********************************************************************** */
    logout({commit}) {
      commit('logout')
      router.replace({name : 'welcome'})
    }
  },
//*********************************************************************** */
//*********************************************************************** */
  getters: {
    getUser (state) {
      if (state.user)
        return state.user
      else
        return null
    },
    //*********************************************************************** */
    getAxiosConfig (state) {
      return state.axios_config
    },   
     //*********************************************************************** */
    getFeedback (state) {
      return state.feedback
    },
    //*********************************************************************** */
    isLogged(state) {   
      return state.isLogged
    },
    //*********************************************************************** */
    isAdmin(state) {
        if(state.user) {
           return (state.user.role_id == 2) 
        } else {
          return false
        }
      },
    getUserID() {        
      return localStorage.getItem('user_id')
    } 
  }
})