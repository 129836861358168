<template>
  <div id="signin">
    <div class="signin-form" v-if="resetPassword == 0">
      <form @submit.prevent="onSubmit">
        <div class="input">
          <label for="email">Mail</label>
          <input
                  type="email"
                  id="email"
                  v-model="email">
        </div>
        <div class="input">
          <label for="password">Password</label>
          <input
                  type="password"
                  id="password"
                  v-model="password">
        </div>
        <v-layout justify-end>
            <v-chip small class="chip-action-box" @click="resetPassword = 1">Forgotten password...</v-chip>
        </v-layout>
        <div class="submit">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
      <ChangePassword v-if="resetPassword == 1" v-bind:source="1" v-bind:eml="''"/>
  </div>
</template>

<script>

import ChangePassword from './ChangePassword.vue'

  export default {
    data: function () {
      return {
        email: '',
        password: '',
        grant_type:"password",
        client_id: "2",
        client_secret: "iQuGUAzqc187j7IKQ94tTVJAywHCAzYBGAMTxEtr",
        loader: false,
        infoError: false,
        resetPassword: 0
      }
    },
    components: {
      ChangePassword
    },
    methods: {
      onSubmit () {
        const formData = {
            email: this.email,
            grant_type: this.grant_type,
            client_id: this.client_id,
            client_secret: this.client_secret,
            username: this.email,
            password: this.password
        }
          // eslint-disable-next-line no-console
        // console.log("submit data")
        this.$store.dispatch('login', formData)
      }
    }
  }

</script>

<style scoped>
  .signin-form {
    width: 400px;
    margin: 30px auto;
    border: 1px solid #eee;
    padding: 20px;
    box-shadow: 0 2px 3px #ccc;
  }

  .input {
    margin: 10px auto;
  }

  .input label {
    display: block;
    color: #4e4e4e;
    margin-bottom: 6px;
  }

  .input input {
    font: inherit;
    width: 100%;
    padding: 6px 12px;
    box-sizing: border-box;
    border: 1px solid #ccc;
  }

  .input input:focus {
    outline: none;
    border: 1px solid #521751;
    background-color: #eee;
  }

  .submit button {
    border: 1px solid #521751;
    color: #521751;
    padding: 10px 20px;
    font: inherit;
    cursor: pointer;
  }

  .submit button:hover,
  .submit button:active {
    background-color: #521751;
    color: white;
  }

  .submit button[disabled],
  .submit button[disabled]:hover,
  .submit button[disabled]:active {
    border: 1px solid #ccc;
    background-color: transparent;
    color: #ccc;
    cursor: not-allowed;
  }
</style>