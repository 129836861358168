<template>
    <v-row>
      <v-col cols=12>
        <v-card outlined raised color = "lightgray" style="margin: 10px">
            <v-card-title>
                Rozvrhy hodin
                <v-btn @click ="pridanie_rozvrhu = true" style="margin:10px">Pridaj rozvrh</v-btn>    
                <v-card>
                    <v-card-title v-if="pridanie_rozvrhu">
                        <v-row>
                            <v-col cols=2>
                                <v-text-field label="Rok:" type ="number" max="2030" min="2016" step="1" v-model="rok_rozvrhu" style="margin-right:10px"></v-text-field>
                            </v-col>
                            <v-col cols=6>
                                <v-text-field label="Názov:" v-model="nazov_rozvrhu"></v-text-field>
                            </v-col>
                            <v-col cols=2>
                                <v-btn @click ="ulozNovyRozvrh()" style="margin:10px">OK</v-btn>                                    
                            </v-col>
                            <v-col cols=2>
                                <v-btn @click ="pridanie_rozvrhu = false" style="margin:10px">Zrušiť</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                </v-card>
            </v-card-title>
            
            <v-card-text v-if="myContent">
                <v-row v-for="(rozvrh,index) in myContent.list" :key="index">
                    <v-col cols=12>
                    <v-card>
                        <v-card-title> 
                            <v-row>
                                <v-col cols=4>
                                    <v-icon color="primary" @click="editRozvrh(index)" style="margin-right:5px" >edit</v-icon>
                                    <v-icon v-if="toDelete(index)" color="primary" @click="deleteRozvrh(index)" style="margin-right:5px" >delete</v-icon>
                                    {{rozvrh.rok}} - {{rozvrh.nazov}}                                      
                                    
                                </v-col>
                                <v-col cols=8 v-if="unp_rozvrh == index">
                                    <v-card>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols=2>
                                                    <v-text-field label="Rok:" type ="number" max="2030" min="2016" step="1" v-model="rok_rozvrhu" style="margin-right:10px"></v-text-field>
                                                </v-col>
                                                <v-col cols=6>
                                                    <v-text-field label="Názov:" v-model="nazov_rozvrhu"></v-text-field>
                                                </v-col>
                                                <v-col cols=2>
                                                    <v-btn @click ="updateRozvrh(index)" style="margin:10px">OK</v-btn>                                    
                                                </v-col>
                                                <v-col cols=2>
                                                    <v-btn @click ="unp_rozvrh = -1" style="margin:10px">Zrušiť</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>                            
                        </v-card-title>
                        <v-card-text>
                             <v-row v-for="(den, index2) in rozvrh.dni" :key="index2">
                                <v-col cols=1></v-col>
                                <v-col cols=1> <strong>{{dni[index2]}}</strong>  </v-col>
                                <v-col cols=1>   
                                    <v-icon color="primary" @click="novaHodina(index,index2)" style="margin-left:10px" >mdi-plus-circle-outline</v-icon>
                                </v-col>
                                <v-col cols=7 v-if="unp_zoznam == index && unp_den == index2">
                                    <v-card>
                                        <v-card-title>
                                            <v-col cols=12 v-if="feedback">
                                                    <v-alert type="error">
                                                        {{feedback}}
                                                    </v-alert>
                                                </v-col>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols=8>
                                                    <v-time-picker small v-model="cas_od" format="24hr"></v-time-picker>
                                                    <v-time-picker x-small v-model="cas_do" format="24hr"></v-time-picker>
                                                </v-col>
                                                <v-col cols=3>
                                                    <v-row>    
                                                        <v-col cols=12>
                                                            <v-text-field label="Názov:" v-model="nazov_predmetu"></v-text-field>
                                                        </v-col>
                                                        <v-col cols=12>
                                                            <v-row>    
                                                                <v-col cols=4></v-col>
                                                                <v-col cols=4> 
                                                                    <v-btn @click ="saveHodina(index, index2)" style="margin:10px">OK</v-btn>                                    
                                                                </v-col>   
                                                                <v-col cols=4> 
                                                                    <v-btn @click ="unp_zoznam = -1, unp_den= - 1" style="margin:10px">Zrušiť</v-btn>
                                                                </v-col>    
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>  
                                            </v-row> 
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col cols=7>
                                    <v-card v-if="den.length > 0">
                                        <v-card-text>
                                            <v-row v-for="(hodina, index3) in den" :key="index3">
                                                <v-col cols=2>                                                   
                                                   {{dm2(hodina.start_hours)}}:{{dm2(hodina.start_minutes)}} - {{dm2(hodina.end_hours)}}:{{dm2(hodina.end_minutes)}}
                                                </v-col>
                                                <v-col cols=2>
                                                    {{hodina.nazov}}
                                                </v-col>
                                                <v-col cols=2>
                                                    <v-icon color="primary" @click="deleteLesson(index, index2, index3)">delete</v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                             </v-row>
                        </v-card-text>
                    </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
</template>

<script>
import axios from '../../axios-auth.js'
import * as time_override from './time_override.js'

  export default {
    data() {
      return {
        unp_rozvrh : -1,
        unp_den: -1,
        unp_zoznam: -1,
        pridanie_rozvrhu: false,
        rok_rozvrhu: 2016,
        rozvrh_id: -1,
        polozka_id: -1,
        nazov_rozvrhu: '',
        nazov_predmetu: '',
        feedback : null,
        dni : ['pondelok', 'utorok','streda','štvrtok', 'piatok', 'sobota'],
        // my content
        myContent: null,

        }
    },      
    mounted () {
      this.initData()
   },
   methods: {
        initData() {
            // prepare empty mycontent
            this.myContent = {
                list: []
            }
            this.readDataFromDB()
        },
        ulozNovyRozvrh() {

            this.rozvrh_id = 0
            const rozvrh = {
                id : 0,
                nazov: this.nazov_rozvrhu,
                rok: this.rok_rozvrhu,
                dni: [[],[],[],[],[]]
            }
            this.myContent.list.splice(0,0,rozvrh)
                     
           // zapis do db
             const myRozvrh = { 
                id : 0,
                nazov: this.nazov_rozvrhu,
                rok: this.rok_rozvrhu,                 
            } 

            // zapis do databazy - vratenie id 
            axios.post('/zapis-rozvrh', myRozvrh, this.$store.getters.getAxiosConfig )
            .then(res => {
               // nastavim v pripade novej id
                if (this.rozvrh_id == 0)
                    this.myContent.list[0].id = res.data.rozvrh_id
             })
            .catch(error => {
                this.$store.commit('setFeedback', error)
            })     

            this.pridanie_rozvrhu = false
        },
        updateRozvrh(index) {            
            this.myContent.list[index].nazov = this.nazov_rozvrhu
            this.myContent.list[index].rok = this.rok_rozvrhu

           // zapis do db
             const myRozvrh = { 
                id : this.myContent.list[index].id,
                nazov: this.nazov_rozvrhu,
                rok: this.rok_rozvrhu,                 
            } 

            // zapis do databazy 
            axios.post('/zapis-rozvrh', myRozvrh, this.$store.getters.getAxiosConfig )
            .then((res) => {
                if (res == null) this.$store.commit('setFeedback', 'empty rs')
             })
            .catch(error => {
                this.$store.commit('setFeedback', error)
            })    

            this.unp_rozvrh = -1
        },
        editRozvrh(index) {
            this.nazov_rozvrhu = this.myContent.list[index].id
            this.nazov_rozvrhu = this.myContent.list[index].nazov
            this.rok_rozvrhu = this.myContent.list[index].rok
            this.unp_rozvrh = index
            this.unp_zoznam = -1
            this.unp_den = -1            
        },
        deleteRozvrh(index) {
            const data = {
                id : this.myContent.list[index].id
            }
            

            axios.post('/delete-rozvrh', data, this.$store.getters.getAxiosConfig )
                .then(res => {
                // set data to var
                    if (res.data.message != 'OK') this.$store.commit('setFeedback', res.data.message)
                    this.myContent.list.splice(index,1)
                })
                .catch(error => {
                    this.$store.commit('setFeedback',  "Timeteble error: " + error)
                })
        },
        dm2(number) {
            if (number<10) return '0' + number
            else return number
        },
        novaHodina(index,index2) {
            this.feedback = ''
            this.polozka_id = 0 
            this.unp_rozvrh = - 1
            this.unp_zoznam = index
            this.unp_den = index2
            this.cas_od = '00:00'
            this.cas_do = '00:00'
        },

        saveHodina(index,index2) {
            this.unp_rozvrh = - 1

            var ind = this.cas_od.indexOf(':')
            let sh = parseInt(this.cas_od.substring(0,ind))
            let sm = parseInt(this.cas_od.substring(ind+1))

            ind = this.cas_do.indexOf(':')
            let eh = parseInt(this.cas_do.substring(0,ind))
            let em = parseInt(this.cas_do.substring(ind+1))

            // koniec pred startom
            if (sh*60+sm >= eh*60+em) {
                    this.feedback = 'Koniec hodiny musí byť neskôr ako začiatok.'
                    return 
            }

            // prekryv s ostatnymi hodinami
            for(let i = 0; i < this.myContent.list[index].dni[index2].length; i++) {
                    // zaciatok nie je v aktivite
                    let sha = this.myContent.list[index].dni[index2][i].start_hours
                    let sma = this.myContent.list[index].dni[index2][i].start_minutes
                    let eha = this.myContent.list[index].dni[index2][i].end_hours
                    let ema = this.myContent.list[index].dni[index2][i].end_minutes
                    if (time_override.time_override(sh,sm,eh,em,sha,sma,eha,ema)) {
                            this.feedback = 'Hodina sa prekrýva s už definovanými hodinami.'
                            return 
                    }
                
            }

            const myLesson = { // for frontend
                        id : this.polozka_id,
                        start_hours: sh,
                        start_minutes: sm,
                        end_hours: eh,
                        end_minutes: em,
                        nazov : this.nazov_predmetu
            }           

            // najdem poziciu
            let myIndex = this.getLessonIndex(index, index2, sh*60 + sm)
            this.myContent.list[index].dni[index2].splice(myIndex, 0, myLesson)

            // zapis do db
            const lessonDB = { // for frontend
                        id : 0,
                        rozvrh_id: this.myContent.list[index].id,
                        day: index2, 
                        start_hours: sh,
                        start_minutes: sm,
                        end_hours: eh,
                        end_minutes: em,
                        nazov : this.nazov_predmetu
            }  

            // eslint-disable-next-line no-console
            //console.log( this.myContent.list[index].id)

            // zapis do databazy 
            axios.post('/zapis-rozvrh-predmety', lessonDB, this.$store.getters.getAxiosConfig )
            .then(res => {
               // nastavim v pripade novej id
                  this.myContent.list[index].dni[index2].id = res.data.lesson_id
             })
            .catch(error => {
                this.$store.commit('setFeedback', error)
            })   

            this.unp_zoznam = -1
            this.unp_den = -1            
        },
        getLessonIndex(index, index2, myTime) {
             for(let i = this.myContent.list[index].dni[index2].length-1; i >= 0; i-- ) {
                if (myTime > this.myContent.list[index].dni[index2][i].start_hours*60 + this.myContent.list[index].dni[index2][i].start_minutes) {
                    return i+1
                }
            }
            return 0
        },
        deleteLesson(index, index2, index3) {
            const data = {
                id : this.myContent.list[index].dni[index2][index3].id
            }

            axios.post('/delete-rozvrh-predmet', data, this.$store.getters.getAxiosConfig )
                .then(res => {
                // set data to var
                    if (res.data.message != 'OK') this.$store.commit('setFeedback',  "Timeteble delete error")
                    this.myContent.list[index].dni[index2].splice(index3,1)
                })
                .catch(error => {
                    this.$store.commit('setFeedback',  "Timeteble error: " + error)
                })
        },
        readDataFromDB() {
                axios.get('/get-rozvrh', this.$store.getters.getAxiosConfig )
                .then(res => {
                // set data to var
                    this.myContent.list = res.data.list
                })
                .catch(error => {
                    this.$store.commit('setFeedback',  "Timeteble error: " + error)
                })
        },
        toDelete(index) {
            for(let i=0; i < this.myContent.list[index].dni.length; i++) {
                if (this.myContent.list[index].dni[i].length > 0 ) return false
            }
            return true;
        }
        
 
    }

  }
  
</script>

<style>

</style>