<template>
<v-container>
        <v-card>
            <v-card-text>
                <v-layout row wrap>
                    <v-flex xs4>
                        <v-radio-group
                            v-model="typ_studia"
                            row
                        >
                            <v-radio label="Bakalárske štúdium" value="bc" @change="getPredmety('bc')"></v-radio>
                            <v-radio label="Magisterské štúdium" value="mgr" @change="getPredmety('mgr')"></v-radio><br/>
                            <v-radio label="PhD štúdium         &nbsp;" value="phd" @change="getPredmety('phd')"></v-radio>
                            <v-radio label="PhD (EN) štúdium" value="phd-e" @change="getPredmety('phd-e')"></v-radio>
                        </v-radio-group>
                    </v-flex>
                    <v-flex xs3></v-flex>
                    <v-flex xs5>
                        <v-btn color="primary" dense small @click="mojeEPC()">Moje epc</v-btn>
                        <v-btn color="primary" dense small @click="mojeUdaje()" style="margin-left:10px">Moje údaje</v-btn>
                        <v-btn color="primary" dense small @click="kompetentnosti()" style="margin-left:10px">Kontrola KPTN</v-btn>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-text v-if="myContent">
                <v-card style="min-height:30px" v-for="(element, index) in myContent" :key="index" :color="element.semester % 2 == 1 ? '#EEEEEE' : '#CCCCCC'">
                    <v-layout row wrap >
                        <v-flex xs1 >
                            <v-layout row wrap>
                                <v-flex xs6>
                                    <v-layout justify-center>{{element.rocnik}}</v-layout>
                                </v-flex>
                                <v-flex xs6>{{element.semester}}</v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs1>
                            <v-layout justify-end>
                                <v-btn dense x-small color="primary" style="margin-right:10px" @click="toAis(element.id)">do AIS</v-btn>
                                <v-icon v-if="element.edit == 1" style="margin-right:20px;" color="blue">star_outline</v-icon>
                            </v-layout>
                        </v-flex>
                        <v-flex xs4 style="cursor:pointer" @click="showInfolist(element.id)">
                            <b v-if="element.typ_predmetu == 'PP'">{{element.nazov}}</b>    
                            <span v-if="element.typ_predmetu != 'PP'">- {{element.nazov}}</span>    
                        </v-flex>
                        <v-flex xs1> {{element.prednasky}} / {{element.cvicenia}} - {{element.kredity}} ({{element.ukoncenie}})</v-flex>    
                        <v-flex xs1>
                            <v-icon small v-if="element.sylaby>12" style="color:green" :title="'sylaby ('+ element.sylaby+')'">check</v-icon>
                            <v-icon small v-if="element.sylaby>7 && element.sylaby<13" style="color:orange" :title="'sylaby ('+ element.sylaby+')'">check</v-icon>
                            <v-icon small v-if="element.sylaby<8" style="color:red" :title="'sylaby ('+ element.sylaby+')'">adjust</v-icon>
                            
                            <v-icon small v-if="element.vysl_vzdel>0" style="color:green" title="výsledky vzdelávania">check</v-icon>
                            <v-icon small v-else style="color:red" title="výsledky vzdelávania">adjust</v-icon>
                            <v-icon small v-if="element.podm_abs>0" style="color:green" title="Podmienky absolvovania">check</v-icon>
                            <v-icon small v-else style="color:red" title="Podmienky absolvovania">adjust</v-icon>
                            <v-icon small v-if="element.literatura>0" style="color:green" title="literatúra">check</v-icon>
                            <v-icon small v-else style="color:red" title="literatúra">adjust</v-icon>
                            <span v-if="element.kompet > 10" style="color:green;margin-left:5px" :title="'kompetentnosti ('+ element.kompet+')'">{{element.kompet}}</span>
                            <span v-if="element.kompet > 4 && element.kompet <11 " style="color:orange;margin-left:5px" :title="'kompetentnosti ('+ element.kompet+')'">{{element.kompet}}</span>
                            <span v-if="element.kompet <5" style="color:red;margin-left:5px" :title="'kompetentnosti ('+ element.kompet+')'">{{element.kompet}}</span>
                        </v-flex>
                        <v-flex xs4>
                            <v-icon v-if="isAdmin" @click="ucitel_add = index">add</v-icon>
                            <span v-for="(ucitel,index2) in element.ucitelia" :key="index2">
                            <b v-if="index2 == 0" @click="showTeacherInfo(ucitel.id)" style="cursor:pointer">{{ucitel.priezvisko}}</b>
                            <span v-if="index2 != 0" >, </span>
                            <span v-if="index2 != 0" @click="showTeacherInfo(ucitel.id)" style="cursor:pointer">{{ucitel.priezvisko}}</span>
                            <v-icon v-if="isAdmin" @click="deleteTeacher(index2, index)">delete</v-icon>
                            </span>
                        </v-flex>
                        <v-flex xs12 v-if="ucitel_add == index">
                            <v-layout row wrap>
                                <v-flex xs7></v-flex>
                                <v-flex xs4>
                                    <v-card color="#CCCCEE">
                                        <v-icon class="btn-close" @click="ucitel_add = -1">close</v-icon> 
                                        <v-card-text>
                                            <p style="color:red" v-if="feedback.length > 0">{{feedback}}</p>
                                            <v-chip @click="addTeacher(teacher, index)" v-for="(teacher,index2) in teachers" :key="index2">
                                                {{teacher.priezvisko}}
                                            </v-chip>
                                        </v-card-text>
                                    </v-card>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout> 
                </v-card>
                <v-card>
                    <v-card-text>
                        <b>Počet kreditov:</b><br>
                        <v-layout row wrap v-for="(pocet, index) in kredity" :key="index">
                            <v-flex v-if= "index>0" xs1></v-flex>
                            <v-flex v-if= "index>0" xs1> {{Math.floor((index+1)/2)}}</v-flex>
                            <v-flex v-if= "index>0" xs1> <span v-if="index % 2 == 0"> L </span> <span v-else> Z </span></v-flex>
                            <v-flex v-if= "index>0" xs2>{{pocet}}</v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
</v-container>
</template>

<script>
import axios from '../../../axios-auth.js'
import {mapGetters} from 'vuex'

  export default {
    data() {
      return {
            // my content
            myContent: null,
            typ_studia: 'bc',
            ucitel_add: -1,
            teachers: [],
            feedback: '',
            kredity: [0],
            pocet_semestrov: 2
        }
    },      
    computed: 
        mapGetters([
                        'isAdmin'
                    ]),
    mounted () {
      this.initData()
    },
    methods: {
        initData() {
            this.getPredmety("bc")
        },
        getPredmety(stupen) {
            const data = {
                stupen: stupen,
            }
            this.typ_studia = stupen
            axios.post('/inf-get-predmety', data, this.$store.getters.getAxiosConfig )
            .then(res => {
                // eslint-disable-next-line no-console
                // console.log(res.data)
                this.myContent = res.data.zoznam
                this.teachers = res.data.ucitelia
                this.prepareSummary()
            })
            .catch(error => {
                this.$store.commit('setFeedback',  "User error: " + error)
            })
        },
        mojeEPC() {
            this.$router.push({name:'my_epc', params: {}})    
        },
        showTeacherInfo(_id) {
            this.$router.push({name:'teacher_info', params: {id: _id}})    
        },
        addTeacher(_teacher, _index) {
            this.feedback = ''
            for(let i=0; i<this.myContent[_index].ucitelia.length; i++) {
                if (_teacher.id == this.myContent[_index].ucitelia[i].id) {
                    this.feedback = "Už je priradený."
                    return
                }
            }

            const data = {
                teacher_id: _teacher.id,
                predmet_id: this.myContent[_index].id,
            }
            axios.post('/inf-add-teacher-predmet', data, this.$store.getters.getAxiosConfig )
            .then(res => {
                // eslint-disable-next-line no-console
                // console.log(res.data)
                if (res.data.message == 'OK')                
                    this.myContent[_index].ucitelia.splice(0,0, _teacher)
                else   
                    this.feedback = res.data.message
            })
            .catch(error => {
                this.$store.commit('setFeedback',  "User error: " + error)
            })            
        },
        deleteTeacher(_index2, _index) {
            this.feedback = ''
            const _data = {
                teacher_id: this.myContent[_index].ucitelia[_index2].id,
                predmet_id: this.myContent[_index].id,
            }
            axios.post('/inf-delete-teacher-predmet', _data, this.$store.getters.getAxiosConfig )
            .then(res => {
                // eslint-disable-next-line no-console
                // console.log(res.data)
                if (res.data.message == 'OK')                
                    this.myContent[_index].ucitelia.splice(_index2,1)
                else   
                    this.feedback = res.data.message
            })
            .catch(error => {
                this.$store.commit('setFeedback',  "User error: " + error)
            })            
        },
        mojeUdaje() {            
            this.$router.push({name:'teacher_info', params: {id: this.$store.getters.getUser.user_id}})
        },
        showInfolist(_id) {
            this.$router.push({name:'infolist', params: {id: _id}})   
        },
        toAis(_id) {
            this.$router.push({name:'ais_export', params: {id: _id}})   
        },
        prepareSummary() {
            this.kredity = [0]
            var _semester = 0
            for(let i = 0; i < this.myContent.length; i++) {
                if (this.myContent[i].semester > _semester) {
                    _semester = this.myContent[i].semester
                    this.kredity.push(0);
                }
                this.kredity[this.myContent[i].semester] += parseInt(this.myContent[i].kredity)
            }
            this.pocet_semestrov = _semester
        },
        kompetentnosti() {
            this.$router.push({name:'kompetentnosti', params: {}})   
        },
  }
}
  
</script>

<style>
.btn-close {
  position: absolute !important;
  top: 4px;
  right: 4px;
  z-index: 2;
}
</style>