<template>
<v-container>
    <v-layout row wrap>
      <v-flex xs12 >
        <v-card v-if="myContent">
            <v-card-title>
                {{myContent.nazov}}
            </v-card-title>
            <v-card-text>
                <v-expansion-panels
                    v-model="panels"
                    multiple
                    >
                    <v-expansion-panel >
                        <v-expansion-panel-header>Základné informácie</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <b>Forma výučby: </b>
                            <span v-if="myContent.prednasky > 0">prednáška</span>
                            <span v-if="myContent.prednasky > 0 && myContent.cvicenia > 0"> / </span>
                            <span v-if="myContent.cvicenia > 0">seminár</span>
                            <br>
                            <b>Odporúčaný rozsah:</b>
                            {{myContent.prednasky}} / {{myContent.cvicenia }} ({{myContent.prednasky*13}} / {{myContent.cvicenia*13}})
                            <br>
                            <b>Počet kreditov:</b> {{myContent.kredity}}
                            <b>Stupeň štúdia:</b> {{myContent.stupen}}
                            <b>Ukončenie predmetu:</b> {{myContent.ukoncenie}}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel >
                        <v-expansion-panel-header>Kompetentnosti</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p style="color:red">Pokiaľ je možné uprednostnite EU kompetentnosti (SK berte ako doplnok). 
                                Ak vám nejaké kompetentnosti chýbajú, spíšte si ich a po dokončení všetkých infolistov mi ich pošlite. 
                                Zabezpečujúci zvážia ich pridanie. </p>
                            <small style="color:blue">Vyberáme kompetentnosti cca 15 na predmet; ak nemožno nájsť žiadne, predmet stráca opodstatnenie. </small>
                            <small>Bližší popis na <a target="_blank" href="https://ec.europa.eu/esco/portal/occupation">EU zdroj</a> a na <a target="_blank" href="https://sustavapovolani.sk/register_kompetencii">SK zdroj</a></small>              
                            <ul>
                                <li v-for="(element, index) in myContent.komp" :key="index" :title="element.povolanie">
                                    <v-icon small color="primary" v-if="myContent.edit == 1" @click ="deleteKomp(index)">delete</v-icon>
                                    {{element.popis}} <b>- {{element.typ}}</b> ({{element.zdroj}})
                                </li>
                            </ul>
                            <v-layout justify-center v-if="myContent.edit == 1 && isEdited != 4">
                                <v-btn color="primary" @click= "isEdited = 4" dense small><v-icon style="cursor:pointer">add</v-icon></v-btn>
                            </v-layout>
                            <v-card v-if="isEdited == 4">
                                <v-icon class="btn-close" @click="isEdited = -1">close</v-icon> 
                                <v-card-text>
                                    <v-layout row wrap>
                                        <v-flex xs4>                                       
                                            <v-autocomplete
                                                    v-model="compFilterOblast"
                                                    :items="myContent.komp_kategorie"
                                                    label="Oblasť"
                                                    clearable
                                            ></v-autocomplete>                                                                                
                                        </v-flex>   
                                        <v-flex xs1><v-icon small style="margin-top:20px" @click="compFilterOblast=''">delete</v-icon></v-flex>                                 
                                        <v-flex xs4>
                                            <v-text-field label="Popis" v-model="compFilterPopis"></v-text-field>
                                        </v-flex>       
                                        <v-flex xs3>                             
                                            <v-checkbox 
                                              label = "ukáž len EU kompet."
                                              v-model = "lenEU"
                                              style= "margin-left:15px"
                                            ></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                    </v-card-text>
                                <ul>
                                    <li v-for="(myindex, index) in getFilteredKomp()" :key="index">
                                        <span :title="myContent.kompetentnosti[myindex].povolanie" v-if="myContent.kompetentnosti[myindex].pouzita == 0" @click="addKompetentnost(myindex)" style="cursor:pointer">{{myContent.kompetentnosti[myindex].kategoria}} ({{myContent.kompetentnosti[myindex].zdroj}}): {{myContent.kompetentnosti[myindex].popis}} - {{myContent.kompetentnosti[myindex].typ}}</span>
                                        <span :title="myContent.kompetentnosti[myindex].povolanie" v-if="myContent.kompetentnosti[myindex].pouzita == 1" style="color:red">{{myContent.kompetentnosti[myindex].kategoria}} ({{myContent.kompetentnosti[myindex].zdroj}}): {{myContent.kompetentnosti[myindex].popis}} - {{myContent.kompetentnosti[myindex].typ}}</span>
                                    </li>
                                </ul>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel >
                        <v-expansion-panel-header>Sylaby</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <small style="color:blue">Doba prípravy zodpovedá dĺžke cvičení. V prípade dlhšej ju treba vysvetliť alebo popísať konkrétnu aktivitu študenta. </small>
                            <small>Pre predmety končiace skúškou pridajte 14. týždeň a popíšte prípravu a realizáciu skúšky (doba skúšky je štandardne 2 hodiny, príprava na skúšku 36 hodín). </small>
                            <small>PH a abs končí v 13. týždni, príprava na PH je max. 12 hodín. </small>
                                <v-card-text>
                                <v-layout row wrap>
                                    <v-flex xs1><v-layout justify-center><b>Týždeň</b></v-layout>   </v-flex>
                                    <v-flex xs3><b>Téma</b></v-flex>
                                    <v-flex xs1><v-layout justify-center><b>Prednášky</b></v-layout></v-flex>
                                    <v-flex xs1><v-layout justify-center><b>Cvičenia</b></v-layout></v-flex>
                                    <v-flex xs1><v-layout justify-center><b>Dom.príp.</b></v-layout></v-flex>
                                    <v-flex xs4><b>Popis domácej prípravy</b></v-flex>
                                    <v-flex xs1></v-flex>
                                </v-layout>  
                                <v-layout row wrap v-for="(element, index) in myContent.sylaby" :key="index">
                                    <v-flex xs12>
                                    <v-card v-if="sylEdit == index">
                                        <v-card-text>
                                        <v-icon class="btn-close" @click="sylEdit = -1">close</v-icon> 
                                        <v-layout row wrap >
                                            <v-flex xs1><v-text-field label="Týždeň" v-model="sylabus.tyzden" style="margin:10px" hide-details single-line type="number"></v-text-field></v-flex>
                                            <v-flex xs4><v-textarea filled label = "Téma:" v-model="sylabus.tema" rows="3"></v-textarea></v-flex>
                                            <v-flex xs1><v-text-field label="Prednášky" v-model="sylabus.hodin_prednasky" style="margin:10px" hide-details single-line type="number"></v-text-field></v-flex>
                                            <v-flex xs1><v-text-field label="Cvičenia" v-model="sylabus.hodin_cvicenia" style="margin:10px" hide-details single-line type="number"></v-text-field></v-flex>
                                            <v-flex xs1><v-text-field label="Dom. príprava" v-model="sylabus.hodin_doma" style="margin:10px" hide-details single-line type="number"></v-text-field></v-flex>
                                            <v-flex xs4><v-textarea filled label = "Popis dom. prípravy:" v-model="sylabus.popis" rows="3"></v-textarea></v-flex>
                                            <v-flex xs12>
                                                <v-layout justify-center>
                                                     <v-btn color="primary" dense small @click= "updateSylabus()" style="cursor:pointer">aktualizuj</v-btn>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>
                                        </v-card-text>  
                                    </v-card>
                                        <v-layout row wrap v-if="sylEdit != index" :style="getBackgroundColor(index)">
                                            <v-flex xs1>               
                                                <v-layout row wrap>
                                                    <v-flex xs1></v-flex>
                                                    <v-flex xs7>
                                                        <v-icon color="primary" small style="cursor:pointer;margin-left:5px;margin-right:5px" v-if="myContent.edit == 1" @click="editSylabus(index)">edit</v-icon>
                                                        <v-icon color="primary" small style="cursor:pointer" v-if="myContent.edit == 1 && index > 0" @click="posunSylabus(index-1)">mdi-arrow-up</v-icon>
                                                        <v-icon color="primary" small style="cursor:pointer" v-if="myContent.edit == 1 && index < myContent.sylaby.length - 1" @click="posunSylabus(index)">mdi-arrow-down</v-icon>
                                                    </v-flex>                                 
                                                    <v-flex xs2>
                                                        {{element.tyzden}}
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs3>{{element.tema}}</v-flex>
                                            <v-flex xs1><v-layout justify-center>{{element.hodin_prednasky}}</v-layout></v-flex>
                                            <v-flex xs1><v-layout justify-center>{{element.hodin_cvicenia}}</v-layout></v-flex>
                                            <v-flex xs1><v-layout justify-center>{{element.hodin_doma}}</v-layout></v-flex>
                                            <v-flex xs4>{{element.popis}}
                                                
                                            </v-flex>
                                            <v-flex xs1>
                                                <v-layout justify-center><v-icon color="primary" small style="cursor:pointer" v-if="myContent.edit == 1" @click="deleteSylabus(index)">delete</v-icon></v-layout></v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                </v-card-text>
                            <v-layout justify-center v-if="myContent.edit == 1 && isEdited != 5">
                                <v-btn color="primary" dense small @click= "prepareSylabus()"><v-icon style="cursor:pointer">add</v-icon></v-btn>
                            </v-layout>

                            <v-card v-if="isEdited == 5">
                                <v-icon class="btn-close" @click="isEdited = -1">close</v-icon> 
                                <v-card-text>
                                    <v-layout row wrap>
                                    <v-flex xs1><v-text-field label="Týždeň" v-model="sylabus.tyzden" style="margin:10px" hide-details single-line type="number"></v-text-field></v-flex>
                                    <v-flex xs4><v-textarea filled label = "Téma:" v-model="sylabus.tema" rows="3"></v-textarea></v-flex>
                                    <v-flex xs1><v-text-field label="Prednášky" v-model="sylabus.hodin_prednasky" style="margin:10px" hide-details single-line type="number"></v-text-field></v-flex>
                                    <v-flex xs1><v-text-field label="Cvičenia" v-model="sylabus.hodin_cvicenia" style="margin:10px" hide-details single-line type="number"></v-text-field></v-flex>
                                    <v-flex xs1><v-text-field label="Dom. príprava" v-model="sylabus.hodin_doma" style="margin:10px" hide-details single-line type="number"></v-text-field></v-flex>
                                    <v-flex xs4><v-textarea filled label = "Popis dom. prípravy:" v-model="sylabus.popis" rows="3"></v-textarea></v-flex>
                                </v-layout>
                                <v-layout justify-center>
                                    <v-btn color="primary" style="margin-bottom:10px" dense small @click="saveSylabus()">OK</v-btn>
                                </v-layout>                                                                
                                </v-card-text>                                
                            </v-card>
                            <v-card outlined style="margin-top:10px">
                                <v-card-text>     
                                    Hodín celkom: {{sucetHodin()}}
                                </v-card-text>       
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Výsledky vzdelávania</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <small>U bc je výsledkom že poznajú a vedia aplikovať. U mgr sú schopní nachádzať súvislosti a navrhovať nové riešenia. </small>
                            <p v-if="isEdited != 1" v-html ="zriadkovanie(myContent.vysl_vzdel)"></p>
                            <v-card v-if="isEdited == 1">
                                <v-textarea 
                                    v-model="myContent.vysl_vzdel"
                                    filled
                                    rows="6"
                                    >
                                </v-textarea> 
                                <v-layout justify-center>
                                    <v-btn color="primary" style="margin-bottom:10px" dense small @click="zapisElement('vysl_vzdel')">OK</v-btn>
                                </v-layout>  
                            </v-card>
                            <v-layout justify-center v-if="myContent.edit == 1 && isEdited != 1">
                                <v-btn color="primary" dense small @click="isEdited = 1"><v-icon style="cursor:pointer">edit</v-icon></v-btn>
                            </v-layout>
                    </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Podmienky absolvovania</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <small>Minimum je <b>70%</b>. <br/> Príklad z infolistu: Aktívna účasť na seminároch a ústna skúška.  V priebehu semestra vypracuje študent v tíme projekt podľa zadania vyučujúceho a prezentuje ho na seminári  (20 bodov); v rámci seminárov realizuje študent praktické aktivity a prezentuje ich výsledky (10 bodov). Na konci semestra absolvuje študent ústnu skúšku (70 bodov).
                            Kredity nebudú udelené študentovi, ktorý získa v celkovom bodovom hodnotení menej ako 70 percent.
                            Hodnotenie: A = 100 % - 95%, B = 94 % - 90 %, C = 89 % - 85 %, D = 84 % -80 %, E = 79 % - 70 %, FX = 69 % - 0 % .</small>
                            <p v-if="isEdited != 2" v-html ="zriadkovanie(myContent.podm_abs)"></p>
                            <v-card v-if="isEdited == 2">
                                <v-textarea 
                                    v-model="myContent.podm_abs"
                                    filled
                                    rows="6"
                                    >
                                </v-textarea> 
                                <v-layout justify-center>
                                    <v-btn color="primary" style="margin-bottom:10px" dense small @click="zapisElement('podm_abs')">OK</v-btn>
                                </v-layout>  
                            </v-card>
                            <v-layout justify-center v-if="myContent.edit == 1 && isEdited != 2">
                                <v-btn color="primary" dense small @click="isEdited = 2"><v-icon style="cursor:pointer">edit</v-icon></v-btn>
                            </v-layout>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Literatúra</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <small>Iné katedry zvykli uvádzať ako prvé kurz v edu. Ideálne, aby v literatúre boli aj vaše publikácie, ale pokiaľ to nebude vhodné, tak nasilu ich tam nedávajte. Hľadajte literatúru za posledné roky, staršie zdroje len výnimočne a nie ako prvé. Aj sk/cz, lebo ako požadovaný jazyk nemáme EN. </small>
                            <p v-if="isEdited != 3" v-html="zriadkovanie(myContent.literatura)"></p>
                            <v-card v-if="isEdited == 3">
                                <v-textarea 
                                    v-model="myContent.literatura"
                                    filled
                                    rows="10"
                                    >
                                </v-textarea> 
                                <v-layout justify-center>
                                    <v-btn color="primary" style="margin-bottom:10px" dense small @click="zapisElement('literatura')">OK</v-btn>
                                </v-layout>  
                            </v-card>
                            <v-layout justify-center v-if="myContent.edit == 1 && isEdited != 3">
                                <v-btn color="primary" dense small @click="isEdited = 3"><v-icon style="cursor:pointer">edit</v-icon></v-btn>
                            </v-layout>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                </v-expansion-panels>
            </v-card-text>            
        </v-card>
      </v-flex>
   </v-layout>
</v-container>
</template>

<script>
import axios from '../../../axios-auth.js'
import {mapGetters} from 'vuex'

  export default {
    data() {
      return {
            panels: [],
            myContent: null,
            isEdited: -1,
            compFilterOblast: '',
            compFilterPopis: '',
            sylabus : { tyzden:0, tema:'', hodin_prednasky:0,hodin_cvicenia:0, hodin_doma:0, popis:''},
            nascitaneKredity: 0,
            sylEdit: -1,
            lenEU: true
        }
    },      
    computed: 
        mapGetters([
                        'isAdmin'
                    ]),
    mounted () {
      this.initData()
    },
    methods: {
        initData() {
            this.getInfo(this.$route.params.id)
        },
        getInfo(_id) {
            const data = {
                predmet_id : _id
            }
            axios.post('/inf-get-infolist', data, this.$store.getters.getAxiosConfig )
            .then(res => {
                // eslint-disable-next-line no-console
                // console.log(res.data)
                if (res.data.message=='OK') {
                    this.myContent = res.data 

                    // eslint-disable-next-line no-console
                   //console.log(res.data)

                    // identifikovat pouzite kompetencie
                    this.setPouziteKompetencie()
                } else {
                    this.$store.commit('setFeedback',  "User error: " + res.data.message)    
                }
            })
            .catch(error => {
                this.$store.commit('setFeedback',  "User error: " + error)
            })
        },   
        zapisElement(_field) {
            var _data = {  
                predmet_id: this.myContent.id,
                pole: _field,
                obsah: ''
            }
            if (_field == 'literatura') _data.obsah = this.myContent.literatura
            if (_field == 'podm_abs') _data.obsah = this.myContent.podm_abs
            if (_field == 'vysl_vzdel') _data.obsah = this.myContent.vysl_vzdel
            axios.post('/inf-update-infolist', _data, this.$store.getters.getAxiosConfig )
            .then(res => {
                if (res.data.message =='OK') {
                    this.isEdited = -1 
                } else {
                    this.$store.commit('setFeedback',  "User error: " + res.data.message)    
                }
            })
            .catch(error => {
                this.$store.commit('setFeedback',  "User error: " + error)
            })
        },
        zriadkovanie(_text) {
            var index = -1
            while (_text.indexOf("\n")>-1) {
                index = _text.indexOf("\n")
                _text = _text.substring(0,index) + "<br>" + _text.substring(index + 1)
            }
            return _text
        },
        addKompetentnost(_index) {
            const _data = {
                predmet_id: this.myContent.id,
                komp_id: this.myContent.kompetentnosti[_index].id
            }
            axios.post('/inf-add-komp', _data, this.$store.getters.getAxiosConfig )
            .then(res => {
                if (res.data.message =='OK') {
                    this.myContent.komp.push({
                        id: res.data.id,
                        kid: this.myContent.kompetentnosti[_index].id,
                        popis: this.myContent.kompetentnosti[_index].popis,
                        zdroj: this.myContent.kompetentnosti[_index].zdroj,
                        typ: this.myContent.kompetentnosti[_index].typ,
                        povolanie: this.myContent.kompetentnosti[_index].povolanie
                    })
                    this.myContent.kompetentnosti[_index].pouzita = 1
                } else {
                    this.$store.commit('setFeedback',  "User error: " + res.data.message)    
                }
            })
            .catch(error => {
                this.$store.commit('setFeedback',  "User error: " + error)
            })
        },
        deleteKomp(_index) {
            const _data = {
                predmet_id: this.myContent.id,
                komp_id: this.myContent.komp[_index].kid
            }     
            axios.post('/inf-delete-komp', _data, this.$store.getters.getAxiosConfig )
            .then(res => {
                if (res.data.message =='OK') {
                    this.odblokujPouzitie(this.myContent.komp[_index].kid)
                    this.myContent.komp.splice(_index,1)                    
                } else {
                    this.$store.commit('setFeedback',  "User error: " + res.data.message)    
                }
            })
            .catch(error => {
                this.$store.commit('setFeedback',  "User error: " + error)
            })
        },
        // zoznam indexov
        getFilteredKomp() {
            var _mylist = []
            for(let i=0; i<this.myContent.kompetentnosti.length; i++) {
                if (this.compFilterPopis != '') {
                    if (this.myContent.kompetentnosti[i].popis.indexOf(this.compFilterPopis) == -1)
                      continue
                }
                if (this.compFilterOblast != '') {
                    if (this.myContent.kompetentnosti[i].kategoria.indexOf(this.compFilterOblast) == -1)
                      continue
                }
                if (this.lenEU) {
                    if (this.myContent.kompetentnosti[i].zdroj != 'EU') continue
                }
                _mylist.push(i)
            }
            return _mylist
        },
        setPouziteKompetencie() {
            for(let i=0; i<this.myContent.komp.length; i++) {
                for(let j=0; j<this.myContent.kompetentnosti.length; j++) {
                    if (this.myContent.komp[i].kid == this.myContent.kompetentnosti[j].id) {
                        this.myContent.kompetentnosti[j].pouzita = 1
                    }
                }
            }
        },
        odblokujPouzitie(_id) {
            for(let j=0; j<this.myContent.kompetentnosti.length; j++) {
                if (_id == this.myContent.kompetentnosti[j].id) {
                    this.myContent.kompetentnosti[j].pouzita = 0
                    return
                }
            }            
        },
        prepareSylabus() {
            this.sylabus = {
                tyzden: this.myContent.sylaby.length + 1,
                tema: '',
                hodin_prednasky: this.myContent.prednasky,
                hodin_cvicenia: this.myContent.cvicenia, 
                hodin_doma: this.myContent.cvicenia, 
                popis:''
            }
            this.isEdited = 5
        },
        saveSylabus() {
            const _data = {
                predmet_id: this.myContent.id,
                tyzden: this.sylabus.tyzden,
                tema: this.sylabus.tema,
                hodin_prednasky: this.sylabus.hodin_prednasky,
                hodin_cvicenia: this.sylabus.hodin_cvicenia,
                hodin_doma: this.sylabus.hodin_doma,
                popis: this.sylabus.popis
            }

            axios.post('/inf-add-sylabus', _data, this.$store.getters.getAxiosConfig )
            .then(res => {
                if (res.data.message =='OK') {
                    this.myContent.sylaby.push({
                        id: res.data.id,
                        tyzden: this.sylabus.tyzden,
                        tema: this.sylabus.tema,
                        hodin_prednasky: parseInt(this.sylabus.hodin_prednasky),
                        hodin_cvicenia: parseInt(this.sylabus.hodin_cvicenia),
                        hodin_doma: parseInt(this.sylabus.hodin_doma),
                        popis: this.sylabus.popis
                    })
                    this.isEdited = -1
                } else {
                    this.$store.commit('setFeedback',  "User error: " + res.data.message)    
                }
            })
            .catch(error => {
                this.$store.commit('setFeedback',  "User error: " + error)
            })
        },
        deleteSylabus(_index) {
            const _data = {
                predmet_id: this.myContent.id,
                sylabus_id: this.myContent.sylaby[_index].id
            }     
            axios.post('/inf-delete-sylabus', _data, this.$store.getters.getAxiosConfig )
            .then(res => {
                if (res.data.message =='OK') {
                    this.myContent.sylaby.splice(_index,1)                    
                } else {
                    this.$store.commit('setFeedback',  "User error: " + res.data.message)    
                }
            })
            .catch(error => {
                this.$store.commit('setFeedback',  "User error: " + error)
            })
        },
        sucetHodin() {
            var _sum = 0
            for(let i=0; i<this.myContent.sylaby.length; i++) {
                _sum += this.myContent.sylaby[i].hodin_prednasky +  this.myContent.sylaby[i].hodin_cvicenia + this.myContent.sylaby[i].hodin_doma
            }
            return _sum + " hodín = " + Math.floor(_sum/25) + " kreditov z potrebných " + this.myContent.kredity
        },
        editSylabus(_index) {
            this.sylabus = {
                tyzden: this.myContent.sylaby[_index].tyzden,
                tema: this.myContent.sylaby[_index].tema,
                hodin_prednasky: this.myContent.sylaby[_index].hodin_prednasky,
                hodin_cvicenia: this.myContent.sylaby[_index].hodin_cvicenia, 
                hodin_doma: this.myContent.sylaby[_index].hodin_doma, 
                popis: this.myContent.sylaby[_index].popis
            }
            this.sylEdit = _index            
        },
        updateSylabus() {
            const _data = {
                predmet_id: this.myContent.id,
                id: this.myContent.sylaby[this.sylEdit].id,
                tyzden: this.sylabus.tyzden,
                tema: this.sylabus.tema,
                hodin_prednasky: this.sylabus.hodin_prednasky,
                hodin_cvicenia: this.sylabus.hodin_cvicenia,
                hodin_doma: this.sylabus.hodin_doma,
                popis: this.sylabus.popis
            }

            axios.post('/inf-update-sylabus', _data, this.$store.getters.getAxiosConfig )
            .then(res => {
                if (res.data.message =='OK') {
                    this.myContent.sylaby[this.sylEdit].tyzden = this.sylabus.tyzden
                    this.myContent.sylaby[this.sylEdit].tema = this.sylabus.tema
                    this.myContent.sylaby[this.sylEdit].hodin_prednasky = parseInt(this.sylabus.hodin_prednasky)
                    this.myContent.sylaby[this.sylEdit].hodin_cvicenia = parseInt(this.sylabus.hodin_cvicenia)
                    this.myContent.sylaby[this.sylEdit].hodin_doma = parseInt(this.sylabus.hodin_doma)
                    this.myContent.sylaby[this.sylEdit].popis = this.sylabus.popis
                    this.sylEdit = -1
                } else {
                    this.$store.commit('setFeedback',  "User error: " + res.data.message)    
                }
            })
            .catch(error => {
                this.$store.commit('setFeedback',  "User error: " + error)
            })
        },    
        posunSylabus(_index) { // posun dole
         const _data = {
             predmet_id: this.myContent.id,
             id1 : this.myContent.sylaby[_index].id,
             id2 : this.myContent.sylaby[_index+1].id,
        //     tyzden1 : this.myContent.sylaby[_index].tyzden,
        //     tyzden2 : this.myContent.sylaby[_index+1].tyzden,
         }
         axios.post('/inf-order-sylabus', _data, this.$store.getters.getAxiosConfig )
            .then(res => {
                if (res.data.message =='OK') {
                    const _tema = this.myContent.sylaby[_index].tema
                  //  const _tyzden = this.myContent.sylaby[_index].tyzden 
                    const _hodin_prednasky = this.myContent.sylaby[_index].hodin_prednasky 
                    const _hodin_cvicenia = this.myContent.sylaby[_index].hodin_cvicenia
                    const _hodin_doma = this.myContent.sylaby[_index].hodin_doma
                    const _popis = this.myContent.sylaby[_index].popis

                    this.myContent.sylaby[_index].tema = '' + this.myContent.sylaby[_index+1].tema
                    //this.myContent.sylaby[_index].tyzden = this.myContent.sylaby[_index+1].tyzden
                    this.myContent.sylaby[_index].hodin_prednasky = this.myContent.sylaby[_index+1].hodin_prednasky
                    this.myContent.sylaby[_index].hodin_cvicenia = this.myContent.sylaby[_index+1].hodin_cvicenia
                    this.myContent.sylaby[_index].hodin_doma = this.myContent.sylaby[_index+1].hodin_doma
                    this.myContent.sylaby[_index].popis = this.myContent.sylaby[_index+1].popis

                    this.myContent.sylaby[_index+1].tema = _tema
                    //this.myContent.sylaby[_index+1].tyzden = _tyzden
                    this.myContent.sylaby[_index+1].hodin_prednasky = _hodin_prednasky
                    this.myContent.sylaby[_index+1].hodin_cvicenia = _hodin_cvicenia
                    this.myContent.sylaby[_index+1].hodin_doma = _hodin_doma
                    this.myContent.sylaby[_index+1].popis = _popis
                } else {
                    this.$store.commit('setFeedback',  "User error: " + res.data.message)    
                }
            })
            .catch(error => {
                this.$store.commit('setFeedback',  "User error: " + error)
            })

        },
        getBackgroundColor(_index) {
            if (_index % 2 == 0) return 'background-color:#FFFFFF'
            return 'background-color:#EEEEEE'
        }
  }
}
  
</script>

<style>

</style>