<template>
<v-container>
    <v-layout row wrap>
      <v-flex xs12 >
        <v-card>
            <v-card-title>
                Publikačná činnosť
            </v-card-title>
            <v-card-text>
                <h3>Posledných 5 rokov:</h3>                
                <v-layout row wrap v-for="(pub, index) in myContent.five_years" :key="index">
                    <v-flex xs1></v-flex>
                    <v-flex xs10>
                        <v-textarea 
                            :label = "'Publikácia' +(index+1)"
                            v-model="myContent.five_years[index]"
                            filled
                            rows="3"
                            >
                        </v-textarea>   
                    </v-flex>
                    <v-flex xs1></v-flex>
                </v-layout> 
            </v-card-text>
            <v-card-text>
                <h3>Celkom:</h3>                
                <v-layout row wrap v-for="(pub, index) in myContent.all_life" :key="index">
                    <v-flex xs1></v-flex>
                    <v-flex xs10>
                        <v-textarea 
                            :label = "'Publikácia' +(index+1)"
                            v-model="myContent.all_life[index]"
                            filled
                            rows="3"
                            >
                        </v-textarea>   
                    </v-flex>
                    <v-flex xs1></v-flex>
                </v-layout> 
                <v-layout justify-center v-if="feedback.length > 0">
                    <p style="color:blue" >{{feedback}}</p>
                </v-layout>
                <v-layout justify-center>
                    <v-btn v-if="readFinished" color="primary" dense small @click="saveEPC()">Ulož zmeny</v-btn>
                </v-layout>
            </v-card-text>
        </v-card>
      </v-flex>
   </v-layout>
   </v-container>
</template>

<script>
import axios from '../../../axios-auth.js'
import {mapGetters} from 'vuex'

  export default {
    data() {
      return {
            // my content
            myContent: {
                five_years: ["","","","",""],
                all_life: ["","","","",""]
            },
            readFinished: true,
            feedback: ""
        }
    },      
    computed: 
        mapGetters([
                        'isAdmin'
                    ]),
    mounted () {
      this.initData()
    },
    methods: {
        initData() {
            this.getMyEpc()
        },
        getMyEpc() {
            this.finished = false
            const data = {
            }
            axios.post('/inf-get-my-epc', data, this.$store.getters.getAxiosConfig )
            .then(res => {
                if (res.data.epc.length>1) {
                    this.myContent = JSON.parse(res.data.epc)
                }                
                this.readFinished = true
            })
            .catch(error => {
                this.$store.commit('setFeedback',  "User error: " + error)
            })
        },
        saveEPC() {
            this.feedback = ""
            const data = {
                publikacie: JSON.stringify(this.myContent)
            }
            axios.post('/inf-save-my-epc', data, this.$store.getters.getAxiosConfig )
            .then(res => {
                // this.myContent = res.data.zoznam
                this.feedback = res.data.message
            })
            .catch(error => {
                this.$store.commit('setFeedback',  "User error: " + error)
            })
        }
    
  }
}
  
</script>

<style>

</style>